import {
    InfluencerList,
    InfluencerListInfluencerFame,
    InfluencerListInfluencerState
} from "../../../../../../types/dbModels";
import {useTranslation} from "react-i18next";
import {useFirebase} from "../../../../../../contexts/FirebaseContext";
import React, {useEffect} from "react";
import {toast} from "react-toastify";
import FormInput from "../../../../../FormInput";

export default function FameSelect({influencerList, shouldReload, influencerState}: {
    influencerList: InfluencerList,
    shouldReload: () => any,
    influencerState: InfluencerListInfluencerState
}) {
    const {t} = useTranslation();
    const {putInfluencerList, getInfluencerAvailableFame} = useFirebase();

    useEffect(() => {
        if (!influencerList) {
            return;
        }

    }, [influencerList, influencerState.userId]);

    const updateFame = async (newFame: InfluencerListInfluencerFame) => {
        if (!influencerState || newFame === influencerState.fame) return;

        let influencerIndex = -1;
        const categoryIndex = influencerList.influencersCategories.findIndex(c => {
            const i = c.influencers.findIndex(influencer => influencer.userId === influencerState.userId)
            if (i > -1) {
                influencerIndex = i;
            }
            return i > -1;
        })

        if (influencerIndex === -1 || categoryIndex === -1) return;

        try {
            const newCategories = [...influencerList.influencersCategories];
            newCategories[categoryIndex].influencers[influencerIndex].fame = newFame;

            const res = await putInfluencerList(influencerList.id, {influencersCategories: newCategories});
            if (!res) throw new Error("");

            toast.success(t("message-success-fame-updated"));

            shouldReload();
        } catch (error) {
            toast.error(t("error-edit-fame"));
        }
    }

    const keyValue : any = {
        influencer: -1,
        nano: 0,
        micro: 10,
        mid: 20,
        top: 50,
        celebrity: 55,
        ugc_creator: 60
    }

    return <FormInput
        defaultValue={influencerState['fame']}
        clearable={false}
        type="select"
        onChange={(e) => updateFame(e.value as InfluencerListInfluencerFame)}
        name="fame"
        value={influencerState['fame']}
    >
        {getInfluencerAvailableFame()
            .sort((a: any, b: any) => {
                if (keyValue[a.id] > keyValue[b.id]) return 1
                if (keyValue[a.id] < keyValue[b.id]) return -1
                return 0
            })
            .map((status: any) => (
                <option key={Math.random()} value={status.id}>
                    {t(status.text)}
                </option>
            ))}
    </FormInput>
}
