export function generateRandomStringId(length = 15) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let randomId = '';

    for (let i = 0; i < length; i++) {
        const randomIndex = Math.floor(Math.random() * characters.length);
        randomId += characters.charAt(randomIndex);
    }

    return randomId;
}

export const debounce = <F extends (...args: any[]) => any>(func: F, delay: number): ((...args: Parameters<F>) => void) => {
    let timerId: NodeJS.Timeout | null = null;

    return (...args: Parameters<F>) => {
        if (timerId) {
            clearTimeout(timerId);
        }
        timerId = setTimeout(() => func(...args), delay);
    };
};

export function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
    const chunks: T[][] = [];

    for (let i = 0; i < array.length; i += chunkSize) {
        chunks.push(array.slice(i, i + chunkSize));
    }

    return chunks;
}

export function formatNumber(num: number): string {
    if (num < 1000) {
        return Math.round(num).toFixed(0).toString();
    } else if (num >= 1000 && num < 1000000) {
        return (num / 1000).toFixed(1) + 'K';
    } else if (num >= 1000000 && num < 1000000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else {
        return (num / 1000000000).toFixed(1) + 'B';
    }
}

export function formatPercentage(value: number) {
    return +(value * 100).toFixed(2);
}

export function capitalizeFirstLetter(string: string) {
    try {
        string = string.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    } catch (err) {
        return string;
    }
}

export function formatDateFromTimestamp(timestamp: number, hours = false) {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Mois indexé à partir de 0
    const year = date.getFullYear();

    let h = "";
    if(hours) {
        h = `à ${String(date.getHours()).padStart(2, '0')}h${String(date.getMinutes()).padStart(2, '0')}`
    }

    return `${day}/${month}/${year} ${h}`;
}

export function generateUid() {
    return Date.now().toString(36) + Math.random().toString(36).substr(2);
}

export function getNbOfDaysBetweenTwoDates(first: any, second: any) {
    return Math.round((second - first) / (1000 * 60 * 60 * 24));
}

export function capitalFirstLetter(word: any) {
    try {
        return word.charAt(0).toUpperCase() + word.slice(1);
    } catch (err) {
        return word;
    }
}

export function isValidURL(str:string) {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

export function mediaTypeToEmoji(type: any) {
    try {
        type = type.toLowerCase();
        switch (type) {
            case "video": return "🎥";
            case "photo": return "📸";
            default: return type;
        }
    } catch (err) {
        return type;
    }
}

export function getColorForEthnicity(type: string) {
    if (type === "white") {
        return "brand";
    }

    if (type === "hispanic") {
        return "neutral-2"
    }

    if (type === "asian") {
        return "neutral-1"
    }

    if (type === "african_american") {
        return "accent-2"
    }

    return "light-3";
}

export function countryCodeToFlag(code: any) {
    code = code.toUpperCase();

    switch (code) {
        case "AD": return "🇦🇩"; // Andorra
        case "AE": return "🇦🇪"; // United Arab Emirates
        case "AF": return "🇦🇫"; // Afghanistan
        case "AG": return "🇦🇬"; // Antigua & Barbuda
        case "AI": return "🇦🇮"; // Anguilla
        case "AL": return "🇦🇱"; // Albania
        case "AM": return "🇦🇲"; // Armenia
        case "AO": return "🇦🇴"; // Angola
        case "AQ": return "🇦🇶"; // Antarctica
        case "AR": return "🇦🇷"; // Argentina
        case "AS": return "🇦🇸"; // American Samoa
        case "AT": return "🇦🇹"; // Austria
        case "AU": return "🇦🇺"; // Australia
        case "AW": return "🇦🇼"; // Aruba
        case "AX": return "🇦🇽"; // Åland Islands
        case "AZ": return "🇦🇿"; // Azerbaijan
        case "BA": return "🇧🇦"; // Bosnia & Herzegovina
        case "BB": return "🇧🇧"; // Barbados
        case "BD": return "🇧🇩"; // Bangladesh
        case "BE": return "🇧🇪"; // Belgium
        case "BF": return "🇧🇫"; // Burkina Faso
        case "BG": return "🇧🇬"; // Bulgaria
        case "BH": return "🇧🇭"; // Bahrain
        case "BI": return "🇧🇮"; // Burundi
        case "BJ": return "🇧🇯"; // Benin
        case "BL": return "🇧🇱"; // St. Barthélemy
        case "BM": return "🇧🇲"; // Bermuda
        case "BN": return "🇧🇳"; // Brunei
        case "BO": return "🇧🇴"; // Bolivia
        case "BQ": return "🇧🇶"; // Caribbean Netherlands
        case "BR": return "🇧🇷"; // Brazil
        case "BS": return "🇧🇸"; // Bahamas
        case "BT": return "🇧🇹"; // Bhutan
        case "BV": return "🇧🇻"; // Bouvet Island
        case "BW": return "🇧🇼"; // Botswana
        case "BY": return "🇧🇾"; // Belarus
        case "BZ": return "🇧🇿"; // Belize
        case "CA": return "🇨🇦"; // Canada
        case "CC": return "🇨🇨"; // Cocos (Keeling) Islands
        case "CD": return "🇨🇩"; // Congo - Kinshasa
        case "CF": return "🇨🇫"; // Central African Republic
        case "CG": return "🇨🇬"; // Congo - Brazzaville
        case "CH": return "🇨🇭"; // Switzerland
        case "CI": return "🇨🇮"; // Côte d’Ivoire
        case "CK": return "🇨🇰"; // Cook Islands
        case "CL": return "🇨🇱"; // Chile
        case "CM": return "🇨🇲"; // Cameroon
        case "CN": return "🇨🇳"; // China
        case "CO": return "🇨🇴"; // Colombia
        case "CR": return "🇨🇷"; // Costa Rica
        case "CU": return "🇨🇺"; // Cuba
        case "CV": return "🇨🇻"; // Cape Verde
        case "CW": return "🇨🇼"; // Curaçao
        case "CX": return "🇨🇽"; // Christmas Island
        case "CY": return "🇨🇾"; // Cyprus
        case "CZ": return "🇨🇿"; // Czechia
        case "DE": return "🇩🇪"; // Germany
        case "DJ": return "🇩🇯"; // Djibouti
        case "DK": return "🇩🇰"; // Denmark
        case "DM": return "🇩🇲"; // Dominica
        case "DO": return "🇩🇴"; // Dominican Republic
        case "DZ": return "🇩🇿"; // Algeria
        case "EC": return "🇪🇨"; // Ecuador
        case "EE": return "🇪🇪"; // Estonia
        case "EG": return "🇪🇬"; // Egypt
        case "EH": return "🇪🇭"; // Western Sahara
        case "ER": return "🇪🇷"; // Eritrea
        case "ES": return "🇪🇸"; // Spain
        case "ET": return "🇪🇹"; // Ethiopia
        case "FI": return "🇫🇮"; // Finland
        case "FJ": return "🇫🇯"; // Fiji
        case "FK": return "🇫🇰"; // Falkland Islands
        case "FM": return "🇫🇲"; // Micronesia
        case "FO": return "🇫🇴"; // Faroe Islands
        case "FR": return "🇫🇷"; // France
        case "GA": return "🇬🇦"; // Gabon
        case "GB": return "🇬🇧"; // United Kingdom
        case "GD": return "🇬🇩"; // Grenada
        case "GE": return "🇬🇪"; // Georgia
        case "GF": return "🇬🇫"; // French Guiana
        case "GG": return "🇬🇬"; // Guernsey
        case "GH": return "🇬🇭"; // Ghana
        case "GI": return "🇬🇮"; // Gibraltar
        case "GL": return "🇬🇱"; // Greenland
        case "GM": return "🇬🇲"; // Gambia
        case "GN": return "🇬🇳"; // Guinea
        case "GP": return "🇬🇵"; // Guadeloupe
        case "GQ": return "🇬🇶"; // Equatorial Guinea
        case "GR": return "🇬🇷"; // Greece
        case "GS": return "🇬🇸"; // South Georgia & South Sandwich Islands
        case "GT": return "🇬🇹"; // Guatemala
        case "GU": return "🇬🇺"; // Guam
        case "GW": return "🇬🇼"; // Guinea-Bissau
        case "GY": return "🇬🇾"; // Guyana
        case "HK": return "🇭🇰"; // Hong Kong SAR China
        case "HM": return "🇭🇲"; // Heard & McDonald Islands
        case "HN": return "🇭🇳"; // Honduras
        case "HR": return "🇭🇷"; // Croatia
        case "HT": return "🇭🇹"; // Haiti
        case "HU": return "🇭🇺"; // Hungary
        case "ID": return "🇮🇩"; // Indonesia
        case "IE": return "🇮🇪"; // Ireland
        case "IL": return "🇮🇱"; // Israel
        case "IM": return "🇮🇲"; // Isle of Man
        case "IN": return "🇮🇳"; // India
        case "IO": return "🇮🇴"; // British Indian Ocean Territory
        case "IQ": return "🇮🇶"; // Iraq
        case "IR": return "🇮🇷"; // Iran
        case "IS": return "🇮🇸"; // Iceland
        case "IT": return "🇮🇹"; // Italy
        case "JE": return "🇯🇪"; // Jersey
        case "JM": return "🇯🇲"; // Jamaica
        case "JO": return "🇯🇴"; // Jordan
        case "JP": return "🇯🇵"; // Japan
        case "KE": return "🇰🇪"; // Kenya
        case "KG": return "🇰🇬"; // Kyrgyzstan
        case "KH": return "🇰🇭"; // Cambodia
        case "KI": return "🇰🇮"; // Kiribati
        case "KM": return "🇰🇲"; // Comoros
        case "KN": return "🇰🇳"; // St. Kitts & Nevis
        case "KP": return "🇰🇵"; // North Korea
        case "KR": return "🇰🇷"; // South Korea
        case "KW": return "🇰🇼"; // Kuwait
        case "KY": return "🇰🇾"; // Cayman Islands
        case "KZ": return "🇰🇿"; // Kazakhstan
        case "LA": return "🇱🇦"; // Laos
        case "LB": return "🇱🇧"; // Lebanon
        case "LC": return "🇱🇨"; // St. Lucia
        case "LI": return "🇱🇮"; // Liechtenstein
        case "LK": return "🇱🇰"; // Sri Lanka
        case "LR": return "🇱🇷"; // Liberia
        case "LS": return "🇱🇸"; // Lesotho
        case "LT": return "🇱🇹"; // Lithuania
        case "LU": return "🇱🇺"; // Luxembourg
        case "LV": return "🇱🇻"; // Latvia
        case "LY": return "🇱🇾"; // Libya
        case "MA": return "🇲🇦"; // Morocco
        case "MC": return "🇲🇨"; // Monaco
        case "MD": return "🇲🇩"; // Moldova
        case "ME": return "🇲🇪"; // Montenegro
        case "MF": return "🇲🇫"; // St. Martin
        case "MG": return "🇲🇬"; // Madagascar
        case "MH": return "🇲🇭"; // Marshall Islands
        case "MK": return "🇲🇰"; // North Macedonia
        case "ML": return "🇲🇱"; // Mali
        case "MM": return "🇲🇲"; // Myanmar (Burma)
        case "MN": return "🇲🇳"; // Mongolia
        case "MO": return "🇲🇴"; // Macao SAR China
        case "MP": return "🇲🇵"; // Northern Mariana Islands
        case "MQ": return "🇲🇶"; // Martinique
        case "MR": return "🇲🇷"; // Mauritania
        case "MS": return "🇲🇸"; // Montserrat
        case "MT": return "🇲🇹"; // Malta
        case "MU": return "🇲🇺"; // Mauritius
        case "MV": return "🇲🇻"; // Maldives
        case "MW": return "🇲🇼"; // Malawi
        case "MX": return "🇲🇽"; // Mexico
        case "MY": return "🇲🇾"; // Malaysia
        case "MZ": return "🇲🇿"; // Mozambique
        case "NA": return "🇳🇦"; // Namibia
        case "NC": return "🇳🇨"; // New Caledonia
        case "NE": return "🇳🇪"; // Niger
        case "NF": return "🇳🇫"; // Norfolk Island
        case "NG": return "🇳🇬"; // Nigeria
        case "NI": return "🇳🇮"; // Nicaragua
        case "NL": return "🇳🇱"; // Netherlands
        case "NO": return "🇳🇴"; // Norway
        case "NP": return "🇳🇵"; // Nepal
        case "NR": return "🇳🇷"; // Nauru
        case "NU": return "🇳🇺"; // Niue
        case "NZ": return "🇳🇿"; // New Zealand
        case "OM": return "🇴🇲"; // Oman
        case "PA": return "🇵🇦"; // Panama
        case "PE": return "🇵🇪"; // Peru
        case "PF": return "🇵🇫"; // French Polynesia
        case "PG": return "🇵🇬"; // Papua New Guinea
        case "PH": return "🇵🇭"; // Philippines
        case "PK": return "🇵🇰"; // Pakistan
        case "PL": return "🇵🇱"; // Poland
        case "PM": return "🇵🇲"; // St. Pierre & Miquelon
        case "PN": return "🇵🇳"; // Pitcairn Islands
        case "PR": return "🇵🇷"; // Puerto Rico
        case "PS": return "🇵🇸"; // Palestinian Territories
        case "PT": return "🇵🇹"; // Portugal
        case "PW": return "🇵🇼"; // Palau
        case "PY": return "🇵🇾"; // Paraguay
        case "QA": return "🇶🇦"; // Qatar
        case "RE": return "🇷🇪"; // Réunion
        case "RO": return "🇷🇴"; // Romania
        case "RS": return "🇷🇸"; // Serbia
        case "RU": return "🇷🇺"; // Russia
        case "RW": return "🇷🇼"; // Rwanda
        case "SA": return "🇸🇦"; // Saudi Arabia
        case "SB": return "🇸🇧"; // Solomon Islands
        case "SC": return "🇸🇨"; // Seychelles
        case "SD": return "🇸🇩"; // Sudan
        case "SE": return "🇸🇪"; // Sweden
        case "SG": return "🇸🇬"; // Singapore
        case "SH": return "🇸🇭"; // St. Helena
        case "SI": return "🇸🇮"; // Slovenia
        case "SJ": return "🇸🇯"; // Svalbard & Jan Mayen
        case "SK": return "🇸🇰"; // Slovakia
        case "SL": return "🇸🇱"; // Sierra Leone
        case "SM": return "🇸🇲"; // San Marino
        case "SN": return "🇸🇳"; // Senegal
        case "SO": return "🇸🇴"; // Somalia
        case "SR": return "🇸🇷"; // Suriname
        case "SS": return "🇸🇸"; // South Sudan
        case "ST": return "🇸🇹"; // São Tomé & Príncipe
        case "SV": return "🇸🇻"; // El Salvador
        case "SX": return "🇸🇽"; // Sint Maarten
        case "SY": return "🇸🇾"; // Syria
        case "SZ": return "🇸🇿"; // Eswatini
        case "TC": return "🇹🇨"; // Turks & Caicos Islands
        case "TD": return "🇹🇩"; // Chad
        case "TF": return "🇹🇫"; // French Southern Territories
        case "TG": return "🇹🇬"; // Togo
        case "TH": return "🇹🇭"; // Thailand
        case "TJ": return "🇹🇯"; // Tajikistan
        case "TK": return "🇹🇰"; // Tokelau
        case "TL": return "🇹🇱"; // Timor-Leste
        case "TM": return "🇹🇲"; // Turkmenistan
        case "TN": return "🇹🇳"; // Tunisia
        case "TO": return "🇹🇴"; // Tonga
        case "TR": return "🇹🇷"; // Turkey
        case "TT": return "🇹🇹"; // Trinidad & Tobago
        case "TV": return "🇹🇻"; // Tuvalu
        case "TW": return "🇹🇼"; // Taiwan
        case "TZ": return "🇹🇿"; // Tanzania
        case "UA": return "🇺🇦"; // Ukraine
        case "UG": return "🇺🇬"; // Uganda
        case "UM": return "🇺🇲"; // U.S. Outlying Islands
        case "US": return "🇺🇸"; // United States
        case "UY": return "🇺🇾"; // Uruguay
        case "UZ": return "🇺🇿"; // Uzbekistan
        case "VA": return "🇻🇦"; // Vatican City
        case "VC": return "🇻🇨"; // St. Vincent & Grenadines
        case "VE": return "🇻🇪"; // Venezuela
        case "VG": return "🇻🇬"; // British Virgin Islands
        case "VI": return "🇻🇮"; // U.S. Virgin Islands
        case "VN": return "🇻🇳"; // Vietnam
        case "VU": return "🇻🇺"; // Vanuatu
        case "WF": return "🇼🇫"; // Wallis & Futuna
        case "WS": return "🇼🇸"; // Samoa
        case "YE": return "🇾🇪"; // Yemen
        case "YT": return "🇾🇹"; // Mayotte
        case "ZA": return "🇿🇦"; // South Africa
        case "ZM": return "🇿🇲"; // Zambia
        case "ZW": return "🇿🇼"; // Zimbabwe
        default: return code;
    }
}
