import React, {useEffect} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
import {useCustomer} from '../../contexts/CustomerContext';
import {ROUTES} from '../../constants/routes';
import {CUSTOMER_AUTH_KEY} from "../../constants/localstorage";

const CustomerRoutes = () => {
    const {campaign, influencerList} = useCustomer();

    const navigate = useNavigate();
    const {slug} = useParams();


    useEffect(() => {
        if (!slug) return;

        if (campaign && influencerList) {
            const storage = localStorage.getItem(CUSTOMER_AUTH_KEY);
            const neededKey = `${influencerList.link}.${influencerList.password}`;

            if (neededKey !== storage) {
                localStorage.removeItem(CUSTOMER_AUTH_KEY);
                navigate(ROUTES.CUSTOMER.AUTH.replace(':slug', slug))
            }

        }
    }, [campaign, influencerList, navigate, slug])


    return (
        <>
            <Outlet/>
        </>
    );
};

export default CustomerRoutes;
