import React, {useRef, useState} from 'react'
import {useAuth} from '../../../../contexts/AuthContext'
import {useNavigate} from 'react-router-dom'
import {toast} from "react-toastify";
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';

export default function Profile() {

    // State
    const emailRef = useRef<any>(null)
    const passwordRef = useRef<any>(null)
    const passwordConfirmRef = useRef<any>(null)
    const [loading, setLoading] = useState(false)

    // Auth, Firebase & translation
    const {currentUser, updatePassword, updateEmail} = useAuth()
    const {t} = useTranslation();
    const navigate = useNavigate();

    function handleSubmit(e: any) {
        e.preventDefault()
        if (passwordRef.current?.value !== passwordConfirmRef.current?.value) {
            return toast.error('Passwords do not match');
        }

        const promises = []
        setLoading(true)

        if (emailRef.current?.value !== currentUser?.email) {
            promises.push(updateEmail(emailRef.current?.value))
        }
        if (passwordRef.current?.value) {
            promises.push(updatePassword(passwordRef.current?.value))
        }

        Promise.all(promises)
            .then(() => {
                navigate('/')
            })
            .catch((err) => {
                toast.error(err.message);
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <div className="md:container md:mx-auto h-screen">
            <div className="relative flex flex-col justify-center">
                <div className="prose w-full p-6 m-auto lg:max-w-xl">
                    <h2>🔐 {t('profile.change_password')}</h2>
                    <form className="space-y-4" onSubmit={handleSubmit}>
                        <input type="hidden" name="remember" defaultValue="true"/>
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label htmlFor="email-address" className="sr-only">
                                    {t('labels.email')}
                                </label>
                                <input
                                    disabled
                                    id="email-address"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    ref={emailRef}
                                    defaultValue={currentUser?.email}
                                    required
                                    className="w-full input input-bordered"
                                    placeholder={t('labels.email')}
                                />
                            </div>
                        </div>
                        <div className="-space-y-px rounded-md shadow-sm">
                            <div>
                                <label className="sr-only">{t('labels.password')}</label>
                                <input
                                    id="password"
                                    name="password"
                                    type="password"
                                    ref={passwordRef}
                                    className="w-full input input-bordered mt-2"
                                    placeholder={t('labels.password')}
                                />
                            </div>
                            <div>
                                <label className="sr-only">{t('labels.password_confirm')}</label>
                                <input
                                    id="confirm-password"
                                    name="confirm-password"
                                    type="password"
                                    ref={passwordConfirmRef}
                                    className="w-full input input-bordered mt-2"
                                    placeholder={t('labels.password_confirm')}
                                />
                            </div>
                        </div>

                        <div className="text-sm text-center">
                            <button
                                type="submit"
                                disabled={loading}
                                className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-md">
                                {t('labels.update')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
