import React from 'react';
import ConfirmMessage from "./ConfirmMessage";
import * as XLSX from 'xlsx';
import {InfluencerList} from "../../types/dbModels";
import {toast} from "react-toastify";
import {ROUTES} from "../../constants/routes";
import {useFirebase} from "../../contexts/FirebaseContext";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

type Props = {
    name: string,
    influencerList: InfluencerList | undefined,
    influencers: any
}

export default function InfluencerListToolsModal({name, influencerList, influencers}: Props) {
    const {
        deleteInfluencerList,
        postDuplicateInfluencerList
    } = useFirebase();
    const navigate = useNavigate();
    const {t} = useTranslation();


    const duplicateList = (influencerList: InfluencerList) => {
        postDuplicateInfluencerList(influencerList).then(r => {
            toast.success(t("list.confirm-duplicate"));
            return navigate(ROUTES.EMPLOYEE.INFLUENCER_LISTS);
        }).catch(err => {
            toast.error(t("list.error-duplicate"));
        });
    }

    const deleteList = async () => {
        if (influencerList?.id) {
            deleteInfluencerList(influencerList?.id).then((success) => {
                toast.success(t("list.confirm-suppress"));
                return navigate(ROUTES.EMPLOYEE.INFLUENCER_LISTS);
            }).catch(err => {
                toast.error(t("list.error-delete"));
            });
        }
    }

    const buildXLS = () => {
        if (!influencerList?.network) return;
        const _influencersCategoryData = influencerList.influencersCategories.map(c => c.influencers).flat();
        if (_influencersCategoryData.length === 0) {
            return toast.error(t("list.error-export-empty"));
        }

        // Build metadata with just the titles
        let oMetadata = [{}];

        influencers.forEach((influencer: { [x: string]: any; }) => {
            const oDataInfluencer = influencer[influencerList?.network];
            oMetadata.push({
                "Username": oDataInfluencer?.profile?.username || oDataInfluencer?.profile?.handle,
                "Bio": oDataInfluencer?.bio || oDataInfluencer?.description,
                "Fullname": oDataInfluencer?.profile?.fullname,
                "URL": oDataInfluencer?.profile?.url,
                "Gender": oDataInfluencer?.gender,
                "Country": oDataInfluencer?.country,
                "City": oDataInfluencer?.city,
                "Posts Count": oDataInfluencer?.postsCount,
                "Engagement Rate": oDataInfluencer?.profile?.engagementRate,
                "Followers": oDataInfluencer?.profile?.followers,
                "Following": oDataInfluencer?.profile?.engagements,
                "Avg Likes": oDataInfluencer?.avgLikes,
                "Avg Comments": oDataInfluencer?.avgComments,
                "Avg Views": oDataInfluencer?.avgViews || oDataInfluencer?.profile?.averageViews,
                "Total Views": oDataInfluencer?.totalViews,
                "Verified?": oDataInfluencer?.isVerified,
                "Account Type": oDataInfluencer?.accountType,
            });

        });

        // Build file
        const worksheet = XLSX.utils.json_to_sheet(oMetadata);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "List of influencers");

        // Download
        let fileName = new Date().getTime() + " - " + influencerList.name + " - " + influencerList.network + ".xlsx";
        XLSX.writeFile(workbook, fileName);

    }

    return (
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                    <h3 className="font-bold text-3xl mb-8">⚙️ {t("list.tools")}</h3>

                    {influencerList && (
                        <div>
                            <div className="flex">
                                <button
                                    className="btn btn-error mr-4"
                                    onClick={() => {
                                        (window as any)["modal_confirm_delete_list"].showModal()
                                    }}>
                                    🗑️ {t("list.delete")}
                                </button>
                                <button className="btn mr-4" onClick={() => {
                                    duplicateList(influencerList)
                                }}>
                                    📑 {t("list.duplicate")}
                                </button>
                                <button
                                    className='btn btn-success'
                                    onClick={() => buildXLS()}>
                                    📄 {t("list.export-xls")}
                                </button>
                            </div>
                        </div>
                    )}

                </form>
            </dialog>


            <ConfirmMessage
                name="modal_confirm_delete_list"
                title={t("list.suppress-title")}
                message={t("list.suppress-message")}
                onSubmit={() => deleteList()}
            />

        </div>
    )
}
