import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {useTranslation} from "react-i18next";

type ChoicePageProps = {
    name: string;
    onCancel: (redirection: boolean) => void,
    onSearch: () => void,
}

export default function InfluencerSearchChoiceModal({onCancel, onSearch, name}: ChoicePageProps) {
    const {t} = useTranslation();

    return <>
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-4xl">
                    <div className="card w-full bg-base-100 p-4">
                        <div className='flex justify-end'>
                            <button className='cursor-pointer' onClick={onCancel.bind(null, false)}>
                                <FontAwesomeIcon size={'xl'} icon={['fas', 'xmark']}/>
                            </button>
                        </div>
                        <div className='flex flex-col items-center justify-center my-12'>
                            <h1 className='text-4xl font-bold mb-4'>{t("are-you-sure")}</h1>
                            <div className='w-2/3 text-sm'>
                                {t("text-are-you-sure-new-search")}
                            </div>
                        </div>
                        <div className='flex gap-2 justify-center'>
                            <button className="btn btn-info" onClick={onSearch}>{t('labels.launch_search')}</button>
                            <button className="btn btn-neutral" onClick={onCancel.bind(null, true)}>
                                {t('labels.search_in_existing_list')}
                            </button>
                        </div>
                    </div>

                </form>
            </dialog>
        </div>
    </>
}