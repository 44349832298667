import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { getFirestore } from 'firebase/firestore';

const app = firebase.initializeApp({
  apiKey: "AIzaSyBuFgzTSI09RtUkjUmTc8WK17jU9AVqRnU",
  databaseURL: "https://clark-machine-default-rtdb.europe-west1.firebasedatabase.app",
  authDomain: "clark-machine.firebaseapp.com",
  projectId: "clark-machine",
  storageBucket: "clark-machine.appspot.com",
  messagingSenderId: "1028120454542",
  appId: "1:1028120454542:web:6d8f49f14c33cd8309f0df",
  measurementId: "G-YVWYRMEVZ0"
});

export const db = getFirestore(app);
export const auth = app.auth();

export default app;
