import React, {useEffect, useState} from 'react';
import {CampaignFormData, ListSettingFormData} from '../../types/formData';
import FormInput from '../FormInput';
import {useFirebase} from '../../contexts/FirebaseContext';
import {Campaign, InfluencerList} from '../../types/dbModels';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import {ROUTES} from "../../constants/routes";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faChevronDown, faChevronUp, faPencilAlt, faTrash, faXmark} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import {FileInput} from "grommet";
import {MultiElementSelect} from "../select/MultiElementSelect";


library.add(faXmark, faChevronDown, faChevronUp, faPencilAlt, faTrash);

type tabNames = 'general' | 'data' | 'columns' | 'sharing' | 'categories';

type Props = {
    name: string;
    onSubmit: any;
    influencerList: InfluencerList | undefined;
    campaign: Campaign | undefined
};

export default function InfluencerListClientSettingsModal({
                                                     name,
                                                     influencerList,
                                                     onSubmit,
                                                     campaign
                                                 }: Props) {
    const {putInfluencerList, getListsLabels, putCampaign, getInfluencerListAvailableMarkets} = useFirebase();
    const baseUrl = window.location.host + ROUTES.CUSTOMER.PREFIX + "/";
    const {t} = useTranslation();
    const [showCampaign, setShowCampaign] = useState(false);
    const [availableMarketValues, setAvailableMarketValues] = useState<any[]>(getInfluencerListAvailableMarkets());

    // States
    const [formData, setFormData] = useState<ListSettingFormData>({
        name: '',
        labels: ['-', '-', '-'],
        market: [],
        campaign: '',
        network: 'instagram',
        password: '',
        settings: {
            onDetails: {
                showAge: false,
                showCity: false,
                showCountry: false,
                showFollowers: false,
                showGender: false,
            },
            onList: {
                showFollowers: false,
                showEngagementRate: false,
                showBio: false,
                showTopAge: false,
                showAudienceGender: false,
                topOneCity: false,
                topOneCountry: false,
                nbComments: false,
                nbLikes: false,
                nbPublications: false,
                topFollowersEthnicOrigins: false,
                readings: false,
            }
        },
        influencersCategories: [],
        note: "",
        link: '',
        status: '',
        banner: '',
        isTemplate: false,
        isArchived: false
    });

    const [campaignFormData, setCampaignFormData] = useState<CampaignFormData['nb_expected']>(campaign?.nb_expected || 10)


    const [labels, setLabels] = useState<{ label1: string, label2: string, label3: string }>({
        label1: '-',
        label2: '-',
        label3: '-'
    });

    const [selectedTab, setSelectedTab] = useState<tabNames>('general');
    const [newCategoryInputValue, setNewCategoryInputValue] = useState<string>('');
    const [newBannerBase64, setNewBannerBase64] = useState<string>('');

    // UseEffects
    useEffect(() => {
        if (influencerList) {
            setFormData(() => {
                return {
                    id: influencerList.id,
                    campaign: influencerList.campaign,
                    name: influencerList.name,
                    labels: influencerList.labels,
                    market: influencerList.market,
                    network: influencerList.network,
                    password: influencerList.password,
                    note: influencerList.note ?? "",
                    isTemplate: influencerList.isTemplate,
                    isArchived: influencerList.isArchived,
                    settings: {
                        onDetails: {
                            showAge: influencerList.settings.onDetails?.showAge ?? true,
                            showCity: influencerList.settings.onDetails?.showCity ?? true,
                            showCountry: influencerList.settings.onDetails?.showCountry ?? true,
                            showFollowers: influencerList.settings.onDetails?.showFollowers ?? true,
                            showGender: influencerList.settings.onDetails?.showGender ?? true,
                        },
                        onList: {
                            showFollowers: influencerList.settings.onList?.showFollowers ?? true,
                            showEngagementRate: influencerList.settings.onList?.showEngagementRate ?? true,
                            showBio: influencerList.settings.onList?.showBio ?? true,
                            showTopAge: influencerList.settings.onList?.showTopAge ?? true,
                            showAudienceGender: influencerList.settings.onList?.showAudienceGender ?? true,
                            topOneCity: influencerList.settings.onList?.topOneCity ?? true,
                            topOneCountry: influencerList.settings.onList?.topOneCountry ?? true,
                            nbComments: influencerList.settings.onList?.nbComments ?? true,
                            nbLikes: influencerList.settings.onList?.nbLikes ?? true,
                            nbPublications: influencerList.settings.onList?.nbPublications ?? true,
                            topFollowersEthnicOrigins: influencerList.settings.onList?.topFollowersEthnicOrigins ?? true,
                            readings: influencerList.settings.onList?.readings ?? true,
                        }
                    },
                    influencersCategories: influencerList.influencersCategories,
                    link: influencerList.link ?? '',
                    status: influencerList.status ?? '',
                    banner: influencerList.banner ?? ''
                };
            });

            const rawL = {label1: '', label2: '', label3: ''};

            for (let i = 0; i < Math.min(3, influencerList.labels.length); i++) {
                const key: "label1" | "label2" | 'label3' = `label${i + 1}` as any;
                rawL[key] = influencerList.labels[i];
            }

            setLabels({...rawL});
            return;
        }

        setFormData(() => {
            return {
                name: '',
                campaign: '',
                labels: ['-', '-', '-'],
                market: [],
                language: '',
                network: 'instagram',
                password: '',
                settings: {
                    onDetails: {
                        showAge: false,
                        showCity: false,
                        showCountry: false,
                        showFollowers: false,
                        showGender: false,
                    },
                    onList: {
                        showFollowers: false,
                        showEngagementRate: false,
                        showBio: false,
                        showTopAge: false,
                        showAudienceGender: false,
                        topOneCity: false,
                        topOneCountry: false,
                        nbComments: false,
                        nbLikes: false,
                        nbPublications: false,
                        topFollowersEthnicOrigins: false,
                        readings: false,
                    }
                },
                influencersCategories: [],
                link: '',
                status: '',
                banner: '',
                isTemplate: false,
                isArchived: false
            };
        });
        // setRawLabels('');
    }, [influencerList]);

    useEffect(() => {
        setFormData(old => {
            const newLabels = [];
            for (const value of Object.values(labels)) {
                if (value) {
                    newLabels.push(value);
                }
            }

            return {
                ...old,
                labels: newLabels
            };
        })
    }, [labels]);

    useEffect(() => {
        if (campaign) {
            setCampaignFormData(campaign.nb_expected)
            if (!showCampaign) {
                setShowCampaign(true)
            }
        } else if (showCampaign) {
            setShowCampaign(false)
        }
    }, [campaign])

    // Handlers
    const inputChangeHandler = (event: any) => {
        let {name, value, checked, type} = event;

        if (name.includes("onList") || name.includes("onDetails")) {
            const newFormData = {...formData};

            // Split the field name into nested properties
            const fieldNames = (name as string).split('.').map(d => d.replace(/\s+/g, ''));
            let currentField: any = newFormData['settings'];

            // Traverse the nested properties to update the value
            for (const fieldName of fieldNames) {
                if (currentField.hasOwnProperty(fieldName)) {
                    if (fieldNames.indexOf(fieldName) === fieldNames.length - 1) {
                        currentField[fieldName] = checked;
                    } else {
                        currentField = currentField[fieldName];
                    }
                }
            }

            setFormData(newFormData);
            return
        }

        if (name === 'link') {
            value = value?.replace(/ /g, '_');
        }

        setFormData(old => ({
            ...old,
            [name]: type === 'checkbox' ? checked : value,
        }))
    }


    const submitHandler = (e: any) => {
        if (!influencerList) return;

        const newFormData = {...formData};

        if (!formData['link']) {
            newFormData['link'] = newFormData['name']?.replace(/ /g, '_');
        }

        for (const [key, value] of Object.entries(formData)) {
            if (key === "campaign" || key === "note" || key === 'banner' || key === 'isTemplate' || key === 'isArchived' ) continue;
            if (!value && key !== 'settings') {
                e.preventDefault();
                toast.error(t('other.fill_all_fields'))
                return;
            }
        }

        if (newBannerBase64) {
            newFormData['banner'] = newBannerBase64;
        }

        setFormData(newFormData)

        putInfluencerList(influencerList.id, newFormData).then(() => {
            if (campaign && campaignFormData !== campaign.nb_expected) {
                return putCampaign(campaign.id, {nb_expected: campaignFormData})
            }

            toast.success(t('list.update_success'));
            onSubmit();
        })
            .then(() => {
                toast.success(t('list.update_success'));
                onSubmit();
            })
            .catch(() => {
                toast.error(t('list.update_error'));
            });
    };

    const labelChangeHandler = (event: any) => {
        const {name, value} = event;
        setLabels(old => ({...old, [name]: value}));
    }

    const selectedTabHandler = (tab: tabNames) => {
        setSelectedTab(tab);
    }

    const marketChangerHandler = (newValue: string[]) => {
        setFormData(old => ({...old, market: newValue}));
    }

    // Helpers
    const getBase64 = (file: File, callback: any, onError: any = null) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function () {
            callback(reader.result);
        }
        reader.onerror = onError || function (error) {
            setNewBannerBase64('');
            toast.error(t("error-read-file"));
        }
    }

    const copyClipboard = async () => {
        try {
            // Copier la chaîne dans le presse-papier
            await navigator.clipboard.writeText(`${baseUrl}${formData['link']?.replace(/ /g, '_') ?? ""}`);
            toast.success(t("message-copied-link"))
        } catch (error) {
            toast.error(t("error-copy-link"))
        }
    }

    const addInfluencersCategoryToFormData = (name: string) => {
        if (!influencerList) return;
        if (newCategoryInputValue.length < 3) return;

        // Verifier que le nom est pas deja prit

        const hasSameName = influencerList.influencersCategories.some(c => c.name === name);
        if (hasSameName) {
            toast.error(t("error-same-name-category"));
            return;
        }

        const oldFormData = {...formData};

        oldFormData.influencersCategories.push({
            id: generateUniqueID(),
            name,
            influencers: []
        });

        setFormData(oldFormData)
        setNewCategoryInputValue('')

    }

    const changeInfluencersCategoryOrder = (id: string, direction: 'up' | 'down') => {
        if (!influencerList || !formData) return;
        const oldFormData = {...formData};
        const categories = oldFormData?.influencersCategories;

        const index = categories.findIndex(cat => cat.id === id);

        if (index < 0 || (index === 0 && direction === 'up') || (index === categories.length - 1 && direction === 'down')) return;

        const newIndex = direction === 'up' ? index - 1 : index + 1;

        // Inversion des items
        [categories[index], categories[newIndex]] =
            [categories[newIndex], categories[index]]

        setFormData(oldFormData);

    }

    const renameInfluencersCategory = (id: string, name: string) => {
        if (!name) return;
        if (name.length < 3) {
            toast.error(t("error-short-name-category"));
            return;
        }

        const oldFormData = {...formData};
        const categoryIndex = oldFormData.influencersCategories.findIndex(cat => cat.id === id);

        if (categoryIndex < 0) {
            toast.error(t("error-category-not-found"));
            return;
        }

        oldFormData.influencersCategories[categoryIndex].name = name;

        setFormData(oldFormData);
    }

    const deleteInfluencersCategory = (id: string) => {
        if (!formData) return;

        const {influencersCategories} = {...formData};
        const index = influencersCategories.findIndex(cat => cat.id === id);

        if (index < 0) return;
        if (influencersCategories.length === 1) {
            toast.error(t("error-suppress-last-list"));
            return;
        }

        // Si la liste à supprimer n'a pas d'influenceurs
        if (influencersCategories[index].influencers.length === 0) {
            influencersCategories.splice(index, 1);
            setFormData(old => ({...old, influencersCategories}));
            return;
        }

        const indexToTransfert = index === 0 ? index + 1 : index - 1;

        // Confirmation
        const confirmed = window.confirm(t("message-category-transfer-1") + influencersCategories[indexToTransfert].name + t("message-category-transfer-2"));
        if (!confirmed) return;

        // Transfert des influenceurs + suppression
        influencersCategories[indexToTransfert].influencers.push(...influencersCategories[index].influencers);
        influencersCategories.splice(index, 1);

        setFormData(old => ({...old, influencersCategories}));
    }

    const openLink = async () => {
        try {
            window.open(`http://${baseUrl}${formData['link']?.replace(/ /g, '_') ?? ""}`, "blank");
        } catch (error) {/* Nothing */
        }
    }

    if (!influencerList) return t("message-influencer-list-needed");
    if (!(formData as any).id) return;

    return (
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                    <h3 className="font-bold text-3xl mb-8">📭 {t("list.client-settings")}</h3>

                    <div role="tablist" className="tabs tabs-boxed">
                        <a role="tab"
                           className={`tab ${selectedTab === 'general' ? 'tab-active' : ''}`}
                           onClick={selectedTabHandler.bind(null, 'general')}>
                            {t("list.general")}
                        </a>

                        <a role="tab"
                           className={`tab ${selectedTab === 'categories' ? 'tab-active' : ''}`}
                           onClick={selectedTabHandler.bind(null, 'categories')}>
                            {t("list.categories")}
                        </a>

                        <a role="tab"
                           className={`tab ${selectedTab === 'data' ? 'tab-active' : ''}`}
                           onClick={selectedTabHandler.bind(null, 'data')}>
                            {t("list.data-columns")}
                        </a>

                        {!influencerList.isTemplate && (
                            <a role="tab"
                               className={`tab ${selectedTab === 'sharing' ? 'tab-active' : ''}`}
                               onClick={selectedTabHandler.bind(null, 'sharing')}>
                                {t("list.share-client")}
                            </a>
                        )}

                    </div>

                    {selectedTab === 'general' && (
                        <>
                            <div
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr',
                                    gap: '1rem',
                                }}>
                                <FormInput
                                    clearable={false}
                                    type="text"
                                    onChange={inputChangeHandler}
                                    name="name"
                                    value={formData['name']}
                                    placeholder={t('labels.name')}
                                    label={t("list.tab-general.name")}
                                />
                                {campaign && showCampaign && (
                                    <FormInput
                                        type="number"
                                        onChange={(event => {
                                            const {value} = event.target
                                            if (value !== campaignFormData && value > 0) {
                                                setCampaignFormData(+value)
                                            }
                                        })}
                                        name="nb_expected"
                                        placeholder={t("list.tab-general.expected-nr")}
                                        value={campaignFormData}
                                        label={t("list.tab-general.expected-nr")}
                                    />
                                )}
                                {/* <FormInput
                                    clearable={false}
                                    type="select"
                                    onChange={inputChangeHandler}
                                    name="status"
                                    label={'Statut Clark'}
                                    value={formData['status']}>
                                    {getListStatusInternal().map((status: any) => (
                                        <option key={status.id} value={status.id}>
                                            {status.text}
                                        </option>
                                    ))}
                                </FormInput> */}
                            </div>

                            <div className='grid grid-cols-3 gap-4 my-4'>
                                <FormInput
                                    defaultValue={'-'}
                                    type="select"
                                    onChange={labelChangeHandler}
                                    name={'label1'}
                                    value={labels['label1']}
                                    label={t("label-txt") + ' #1'}
                                >
                                    {getListsLabels().sort((a, b) => t(a.text).localeCompare(t(b.text))).map((label: any) => (
                                        <option key={label.id} id={label.id} value={label.id}>
                                            {t(label.text)}
                                        </option>
                                    ))}
                                </FormInput>
                                <FormInput
                                    defaultValue={'-'}
                                    type="select"
                                    onChange={labelChangeHandler}
                                    name={'label2'}
                                    value={labels['label2']}
                                    label={t("label-txt") + ' #2'}
                                >
                                    {getListsLabels().sort((a, b) => t(a.text).localeCompare(t(b.text))).map((label: any) => (
                                        <option key={label.id} id={label.id} value={label.id}>
                                            {t(label.text)}
                                        </option>
                                    ))}
                                </FormInput>
                                <FormInput
                                    defaultValue={'-'}
                                    type="select"
                                    onChange={labelChangeHandler}
                                    name={'label3'}
                                    value={labels['label3']}
                                    label={t("label-txt") + ' #3'}
                                >
                                    {getListsLabels().sort((a, b) => t(a.text).localeCompare(t(b.text))).map((label: any) => (
                                        <option key={label.id} id={label.id} value={label.id}>
                                            {t(label.text)}
                                        </option>
                                    ))}
                                </FormInput>
                            </div>

                            <div>
                                <MultiElementSelect label={t('list.market.label')} values={availableMarketValues}
                                                    onSelectedValueChanged={marketChangerHandler}
                                                    selectedValues={formData.market.map(v => availableMarketValues.find(tmp => tmp.id === v))}/>
                            </div>

                            <div>
                                <FormInput
                                    type="textarea"
                                    onChange={inputChangeHandler}
                                    name="note"
                                    value={formData['note']}
                                    placeholder={t("note")}
                                    label={t("note")}
                                />
                            </div>

                            <div className='my-4'>
                                {!formData?.banner && (
                                    <>
                                        <label htmlFor="banner" className='label'><span
                                            className="label-text">{t("list.tab-general.banner-info")}</span></label>
                                        <FileInput
                                            id={'banner'}
                                            name={'banner'}
                                            multiple={false}
                                            contextMenu={'ok'}
                                            maxSize={1500}
                                            messages={{
                                                browse: t("list.tab-general.add-banner"),
                                                dropPrompt: " ",
                                                dropPromptMultiple: " ",
                                                files: "files",
                                                remove: "remove",
                                                removeAll: "remove all",
                                                maxFile: t("list.tab-general.error-size")
                                            }}
                                            onChange={async (event, test) => {
                                                if (!test) {
                                                    setNewBannerBase64('');
                                                    return;
                                                }

                                                const fileList = test.files;
                                                if (fileList.length > 0) {
                                                    getBase64(fileList[0], (base: string) => {
                                                        setNewBannerBase64(base);
                                                    })

                                                } else {
                                                    setNewBannerBase64('');
                                                }
                                            }}
                                        />
                                    </>
                                )}
                                {formData?.banner && !newBannerBase64 && (
                                    <div>
                                        <img src={formData?.banner} alt="List banner" style={{width: '200px'}}/>
                                        <button type='button' className='btn btn-secondary btn-sm' onClick={() => {
                                            setFormData(old => ({...old, banner: ''}))
                                        }}>
                                            {t("list.tab-general.change-banner")}
                                        </button>
                                    </div>
                                )}
                            </div>

                        </>
                    )}

                    {selectedTab === 'data' && (
                        <div className='my-8'>

                            <h1 className='text-xl'>{t("list.tab-data.data")} (5)</h1>

                            <div
                                className="my-4"
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr',
                                    gap: '1rem',
                                }}
                            >
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onDetails.showGender">{t('labels.gender')}</label>
                                    <input
                                        id="onDetails.showGender"
                                        name="onDetails.showGender"
                                        type="checkbox"
                                        checked={formData['settings']['onDetails']['showGender']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onDetails.showCity">{t('labels.city')}</label>
                                    <input
                                        id="onDetails.showCity"
                                        name="onDetails.showCity"
                                        type="checkbox"
                                        checked={formData['settings']['onDetails']['showCity']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onDetails.showCountry">{t('labels.country')}</label>
                                    <input
                                        id="onDetails.showCountry"
                                        name="onDetails.showCountry"
                                        type="checkbox"
                                        checked={formData['settings']['onDetails']['showCountry']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onDetails.showAge">{t('labels.age')}</label>
                                    <input
                                        id="onDetails.showAge"
                                        name="onDetails.showAge"
                                        type="checkbox"
                                        checked={formData['settings']['onDetails']['showAge']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onDetails.showFollowers">{t('labels.followers')}</label>
                                    <input
                                        id="onDetails.showFollowers"
                                        name="onDetails.showFollowers"
                                        type="checkbox"
                                        checked={formData['settings']['onDetails']['showFollowers']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                            </div>

                            <h1 className='text-xl'>{t("list.tab-data.columns")} (12)</h1>

                            <div
                                className="my-4"
                                style={{
                                    display: 'grid',
                                    gridTemplateColumns: '1fr',
                                    gap: '1rem',
                                }}
                            >
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.showFollowers">{t('labels.followers')}</label>
                                    <input
                                        id="onList.showFollowers"
                                        name="onList.showFollowers"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['showFollowers']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onList.showEngagementRate">Engagement</label>
                                    <input
                                        id="onList.showEngagementRate"
                                        name="onList.showEngagementRate"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['showEngagementRate']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onList.showBio">{t("labels.bio")}</label>
                                    <input
                                        id="onList.showBio"
                                        name="onList.showBio"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['showBio']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onList.showTopAge">{t("labels.top-age")}</label>
                                    <input
                                        id="onList.showTopAge"
                                        name="onList.showTopAge"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['showTopAge']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.showAudienceGender">{t("labels.audience_gender")}</label>
                                    <input
                                        id="onList.showAudienceGender"
                                        name="onList.showAudienceGender"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['showAudienceGender']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.topOneCity">{t("labels.top-one-city")}</label>
                                    <input
                                        id="onList.topOneCity"
                                        name="onList.topOneCity"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['topOneCity']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.topOneCountry">{t("labels.top-one-country")}</label>
                                    <input
                                        id="onList.topOneCountry"
                                        name="onList.topOneCountry"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['topOneCountry']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.nbComments">{t("labels.count-comments")}</label>
                                    <input
                                        id="onList.nbComments"
                                        name="onList.nbComments"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['nbComments']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onList.nbLikes">{t("labels.count-likes")}</label>
                                    <input
                                        id="onList.nbLikes"
                                        name="onList.nbLikes"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['nbLikes']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.nbPublications">{t("labels.count-posts")}</label>
                                    <input
                                        id="onList.nbPublications"
                                        name="onList.nbPublications"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['nbPublications']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>

                                <div className="flex flex-row justify-between">
                                    <label className='ml-8'
                                           htmlFor="onList.topFollowersEthnicOrigins">{t("labels.top-etnical-followers")}</label>
                                    <input
                                        id="onList.topFollowersEthnicOrigins"
                                        name="onList.topFollowersEthnicOrigins"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['topFollowersEthnicOrigins']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                                <div className="flex flex-row justify-between">
                                    <label className='ml-8' htmlFor="onList.readings">{t("labels.count-views")}</label>
                                    <input
                                        id="onList.readings"
                                        name="onList.readings"
                                        type="checkbox"
                                        checked={formData['settings']['onList']['readings']}
                                        className="checkbox"
                                        onChange={(e) => {
                                            inputChangeHandler(e.target)
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    )}

                    {!influencerList.isTemplate && selectedTab === 'sharing' && (
                        <div className='my-8'>

                            <h1 className='text-xl'>{t("list.share-client")}</h1>

                            <div className="my-4"
                                 style={{
                                     display: 'grid',
                                     gridTemplateColumns: '3fr 1fr',
                                     gap: '1rem',
                                 }}>
                                <div className='flex gap-4 items-end'>
                                    <FormInput
                                        clearable={false}
                                        className='join-item'
                                        type="text"
                                        onChange={inputChangeHandler}
                                        name="link"
                                        value={baseUrl + formData['link']}
                                        label={t('labels.client_link')}
                                        disabled
                                    />
                                    <button
                                        className='btn btn-outline'
                                        type='button'
                                        onClick={() => copyClipboard()}>
                                        {t("labels.copy")}
                                    </button>
                                    <button
                                        className='btn btn-outline'
                                        type='button'
                                        onClick={() => openLink()}>
                                        {t("labels.open")}
                                    </button>
                                </div>
                                <FormInput
                                    clearable={false}
                                    type="text"
                                    onChange={inputChangeHandler}
                                    name="password"
                                    value={formData['password']}
                                    label={t('labels.password')}
                                />
                            </div>
                            <a
                                href={`https://mail.google.com/mail/?view=cm&fs=1&su=Clark Machine - Liste ${influencerList.name}&body=Bonjour,%0D%0A%0D%0ALien: https://${baseUrl}${influencerList.link?.replace(/ /g, '_') ?? ""}%0D%0AMot de passe: ${influencerList.password}%0D%0A%0D%0AMerci,`}
                                className='btn btn-outline'>
                                📧 {t("list.generate-email")}
                            </a>
                        </div>
                    )}

                    {selectedTab === 'categories' && (
                        <div className='my-8'>

                            <h1 className='text-xl'>{t("list.tab-categories.title")}</h1>

                            <div className="flex items-end gap-4 mb-4">
                                <FormInput onChange={(event: any) => {
                                    setNewCategoryInputValue(event.value)
                                }} name={'newCategoryName'} label={t('list.tab-categories.new')}
                                           value={newCategoryInputValue}/>
                                <button type='button' className='btn btn-success'
                                        disabled={newCategoryInputValue.length < 3}
                                        onClick={addInfluencersCategoryToFormData?.bind(null, newCategoryInputValue)}>{t('list.tab-categories.add')}
                                </button>
                            </div>

                            <div className='flex flex-col items-start gap-4 mb-4'>
                                {influencerList.influencersCategories.map(category => (
                                    <div key={category.id}
                                         className='card bg-base-300 py-2 px-4 w-1/2 flex flex-row justify-between items-center'>
                                        <div>{category.name} - {category.influencers.length} 👥</div>
                                        <div className='flex justify-end items-center gap-4'>

                                            <div className='flex gap-1'>
                                                <button className="btn btn-error btn-sm" type='button'
                                                        onClick={deleteInfluencersCategory.bind(null, category.id)}>
                                                    <FontAwesomeIcon icon={faTrash}/>
                                                </button>
                                                <button className="btn btn-warning btn-sm" type='button'
                                                        onClick={(() => {
                                                            const newName = prompt(t("list.tab-categories.message-new-name"));

                                                            if (newName) {
                                                                renameInfluencersCategory(category.id, newName);
                                                            }
                                                        })}>
                                                    <FontAwesomeIcon icon={faPencilAlt}/>
                                                </button>
                                            </div>
                                            <div className='flex gap-1'>
                                                <button className="btn btn-primary btn-sm" type='button'
                                                        onClick={() => {
                                                            changeInfluencersCategoryOrder(category.id, 'up');
                                                        }}>
                                                    <FontAwesomeIcon icon={faChevronUp}/>
                                                </button>
                                                <button className="btn btn-primary btn-sm" type='button'
                                                        onClick={() => {
                                                            changeInfluencersCategoryOrder(category.id, 'down');
                                                        }}>
                                                    <FontAwesomeIcon icon={faChevronDown}/>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                    <div className="flex justify-center">
                        <button className="btn btn-primary mr-8" onClick={submitHandler}>
                            {t('labels.save')}
                        </button>
                    </div>
                </form>
            </dialog>
        </div>
    );
}
