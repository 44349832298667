import React from "react";
import { isValidURL } from "../../helpers/functions";

type Props = {
    value: string,
    label?: string;
}

export default function InlineTextChart({value, label = "-"}: Props) {

    return (
        <div className="grid grid-cols-3 gap-4">
            <div className={`text-sm flex self-center justify-end`}>{label}</div>
            <div className="col-span-2 flex flex-row content-center self-center">
                {isValidURL(value) ? (
                        <div className={`text-sm content-center self-center font-bold overflow-hidden`}>
                            <a href={value} target="_blank" className="underline">{value}</a>
                        </div>
                ) : (
                    <div className={`text-sm content-center self-center font-bold overflow-hidden`}>{value}</div>
                )}
            </div>
        </div>
    )
}