import React, {useEffect} from 'react';
import {BrowserRouter as Router, Navigate, Route, Routes, useNavigate} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './global.scss';

// Auth
import Signup from './components/auth/Signup';
import Login from './components/auth/Login';
import ForgotPassword from './components/auth/ForgotPassword';

// Router
import PrivateRoutes from './components/router/PrivateRoutes';

// Pages
import Profile from './components/pages/employee/profile/Profile';
import {ApiProvider} from './contexts/ApiContext';
import {AuthProvider} from './contexts/AuthContext';
import {FirebaseProvider} from './contexts/FirebaseContext';
import AppContextProviders from './contexts/AppContextProvider';
import ChoosePage from './components/pages/ChoosePage/ChoosePage';
import Homepage from './components/pages/employee/homepage/Homepage';
import EnterPasswordPage from './components/pages/customer/EnterPasswordPage';
import TermsOfUsePageEN from './components/pages/customer/TermsOfUseEN';
import TermsOfUsePageFR from './components/pages/customer/TermsOfUseFR';
import CampaignsPage from './components/pages/employee/CampaignsPage';
import InfluencerSearchPage from './components/pages/employee/InfluencerSearchPage/InfluencerSearchPage';
import EmployeeLayout from './components/layout/EmployeeLayout/EmployeeLayout';
import InfluencerListPage from './components/pages/employee/InfluencerListPage/InfluencerListPage';
import InfluencerListDetailsPage
    from './components/pages/employee/InfluencerListPage/InfluencerListDetailsPage/InfluencerListDetailsPage';
import {ROUTES} from './constants/routes';

import {library} from '@fortawesome/fontawesome-svg-core';

import {faMessage, faEnvelope} from '@fortawesome/free-regular-svg-icons';
import {
    faPhone,
    faIcons,
    faLocationDot,
    faMars,
    faVenus,
    faEarthAmericas,
    faGear,
    faHeart,
    faIdCardClip,
    faTable,
    faQuestion,
    faXmark,
    faPowerOff,
    faArrowLeft,
    faArrowRight,
    faList,
    faCartArrowDown,
    faCheck,
    faDoorOpen,
    faRefresh,
    faArrowsRotate,
    faBars,
    faArrowsLeftRight,
    faFilePdf,
    faSignature, faGhost
} from '@fortawesome/free-solid-svg-icons';
import {faYoutube, faTiktok, faInstagram} from '@fortawesome/free-brands-svg-icons'

import {CustomerProvider} from './contexts/CustomerContext';
import CustomerRoutes from './components/router/CustomerRoutes';
import CustomerRoutesAuthenticated from "./components/router/CustomerRoutesAuthenticated";
import CampaignOverall from "./components/pages/customer/CampaignOverall/CampaignOverall";
import CustomerLayout from "./components/layout/CustomerLayout/CustomerLayout";
import CampaignInfluencer from "./components/pages/customer/CampaignInfluencer";
import UsersSettings from "./components/pages/UsersSettings/UsersSettings";
import moment from 'moment';
import CertifiedInfluencers from "./components/pages/customer/CertifiedInfluencers/CertifiedInfluencers";

library.add(faMessage);
library.add(faIcons);
library.add(faPhone);
library.add(faEnvelope);
library.add(faLocationDot);
library.add(faMars);
library.add(faVenus);
library.add(faEarthAmericas);
library.add(faGear);
library.add(faHeart);
library.add(faIdCardClip);
library.add(faTable);
library.add(faList);
library.add(faCartArrowDown);
library.add(faQuestion);
library.add(faXmark);
library.add(faPowerOff);
library.add(faArrowLeft);
library.add(faArrowRight);
library.add(faInstagram);
library.add(faTiktok);
library.add(faYoutube);
library.add(faCheck);
library.add(faDoorOpen)
library.add(faRefresh);
library.add(faArrowsRotate);
library.add(faBars);
library.add(faArrowsLeftRight);
library.add(faFilePdf);
library.add(faSignature);
library.add(faGhost);



function App() {
    moment().locale(localStorage.getItem("i18nextLng") || "fr");
    const providers = [
        AuthProvider,
        FirebaseProvider,
        ApiProvider,
        CustomerProvider,
    ];
    return (
        <Router>
            <AppContextProviders components={providers}>
                <ToastContainer 
                    theme='colored'
                    hideProgressBar
                />
                <Routes>

                    {/* Non protected */}
                    <Route path={ROUTES.EMPLOYEE.SIGNUP} element={<Signup/>}/>
                    <Route path={ROUTES.EMPLOYEE.LOGIN} element={<Login/>}/>
                    <Route path={ROUTES.CHOOSE} element={<ChoosePage/>}/>
                    <Route path={ROUTES.EMPLOYEE.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
                    <Route path={ROUTES.CUSTOMER.CERTIFIEDINFLUENCERS} element={<CertifiedInfluencers />}/>

                    {/* Employee */}
                    <Route element={<PrivateRoutes/>}>
                        <Route element={<EmployeeLayout/>}>
                            <Route
                                path={ROUTES.EMPLOYEE.HOME}
                                element={<Homepage/>}
                            />
                            <Route
                                path={ROUTES.EMPLOYEE.CAMPAIGNS}
                                element={<CampaignsPage/>}
                            />
                            <Route
                                path={ROUTES.EMPLOYEE.INFLUENCER_LISTS}
                                element={<InfluencerListPage/>}
                            />
                            <Route
                                path={ROUTES.EMPLOYEE.INFLUENCER_LIST}
                                element={<InfluencerListDetailsPage/>}
                            />
                            <Route path={ROUTES.EMPLOYEE.SEARCH} element={<InfluencerSearchPage/>}/>
                            <Route path={ROUTES.EMPLOYEE.PROFILE} element={<Profile/>}/>
                            <Route path={ROUTES.EMPLOYEE.USERS_SETTINGS} element={<UsersSettings />}/>
                        </Route>
                    </Route>

                    {/* Client */}
                    <Route element={<CustomerRoutes/>}>
                        <Route path={ROUTES.CUSTOMER.AUTH} element={<EnterPasswordPage/>} />
                        <Route path={ROUTES.CUSTOMER.TERMSOFUSEEN} element={<TermsOfUsePageEN/>} />
                        <Route path={ROUTES.CUSTOMER.TERMSOFUSEFR} element={<TermsOfUsePageFR/>} />
                        <Route element={<CustomerLayout/>}>
                            <Route element={<CustomerRoutesAuthenticated/>}>
                                <Route path={ROUTES.CUSTOMER.OVERALL} element={<CampaignOverall/>}/>
                                <Route path={ROUTES.CUSTOMER.INFLUENCER} element={<CampaignInfluencer/>}/>
                            </Route>
                        </Route>
                        <Route path="*" element={<RedirectCustomerAuth/>}/>
                    </Route>
                </Routes>
            </AppContextProviders>
        </Router>
    );
}

export default App;

function RedirectCustomerAuth() {
    const navigate = useNavigate();

    useEffect(() => {

        if (window.location.href.split('/').includes("employee")) {
            return
        }

        const location = window.location.href;

        const authRoute = ROUTES.CUSTOMER.AUTH;
        let slugParamIndex = authRoute.split("/").findIndex(i => i === ":slug");
        slugParamIndex--;

        // Terms of use
        if (window.location.href.split('/').includes("termsofuse")) {
            alert("terms")
            return;
        }

        if (slugParamIndex < 0) {
            navigate(ROUTES.CHOOSE);
            return;
        }


        const actualUrlPrefixIndex = location.split("/").findIndex(i => i === authRoute.split("/")[slugParamIndex]);
        if (actualUrlPrefixIndex < 0 || actualUrlPrefixIndex + 1 === location.split('/').length) {
            navigate(ROUTES.CHOOSE);
            return;
        }

        window.location.href = ROUTES.CUSTOMER.AUTH.replace(":slug", location.split('/')[actualUrlPrefixIndex + 1])
        // navigate(ROUTES.CUSTOMER.AUTH.replace(":slug", location.split('/')[actualUrlPrefixIndex + 1]))
        return;
    }, [navigate]);

    return <>
        {window.location.href.split('/').includes("employee") && <Navigate to={ROUTES.EMPLOYEE.CAMPAIGNS} replace/>}
    </>
}