import React, {useEffect, useState} from 'react';
import {useCustomer} from "../../../contexts/CustomerContext";
import {NavLink, useParams} from "react-router-dom";
import {Influencer} from "../../../types/dbModels";
import {InfluencerReportInformation} from "../../../types/api";
import {useTranslation} from "react-i18next";
import {ROUTES} from '../../../constants/routes';
import InfluencerSearchDetailsModal from '../../modal/InfluencerSearchDetailsModal';
import {generateUid} from "../../../helpers/functions";
import InfluencerCommentsModal from "../../modal/InfluencerCommentsModal";

export default function CampaignInfluencer() {
    const {slug} = useParams();
    const {t} = useTranslation();
    const {influencers, influencerList, refreshInfos} = useCustomer();
    const {influencerId} = useParams();

    const [influencer, setInfluencer] = useState<Influencer>();
    const [influencerData, setInfluencerData] = useState<InfluencerReportInformation>();

    const modalName = 'influencer_search_details_modal';
    const commentModalName = "comment_modal_" + generateUid();

    useEffect(() => {
        setInfluencer(influencers.find(i => i.userId === influencerId))
    }, [influencerId, influencers]);

    useEffect(() => {
        if (!influencerList || !influencer) {
            setInfluencerData(undefined);
            return;
        }
        const influencerState = influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencerId);
        if (!influencerState) return;

        setInfluencerData(influencer[influencerState.network] ?? undefined)
    }, [influencer, influencerList]);

    if (!influencer || !influencerList) return <span className="loading loading-lg loading-spinner text-info"></span>

    if (!influencerData) return <div>No data</div>

    return (
        <>
            {/* Breadcrumb */}
            <div className="text-sm breadcrumbs">
                <ul>
                    <li><NavLink
                        to={ROUTES.CUSTOMER.OVERALL.replace(':slug', slug as string)}>{t("home-title")}</NavLink></li>
                    <li><NavLink
                        to={ROUTES.CUSTOMER.OVERALL.replace(':slug', slug as string)}>{t("menu-influencers-choice")}</NavLink>
                    </li>
                    <li>{influencer[influencerList.network]?.profile.fullname}</li>
                </ul>
            </div>

            <div className="card bg-base-100 shadow-xl my-8 p-8">
                <InfluencerSearchDetailsModal
                    name={modalName}
                    influencerId={influencerId as string}
                    platform={influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencerId)?.network || influencerList.network}
                    getFromFirebase={true}
                    allowReload={false}
                    influencerList={influencerList}
                    manageProfilAsClark={false}
                    showInModal={false}
                    showInCustomerViewMode={true}
                    onAddComment={(comment) => {
                        slug && refreshInfos(slug)
                    }}
                    comments={influencerList?.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencerId)?.comments}
                    showJudging={true}
                    onCommentButtonClick={() => {
                        (window as any)[commentModalName].showModal()
                    }}
                />
            </div>

            {influencer && <InfluencerCommentsModal
                name={commentModalName}
                influencerList={influencerList}
                influencerId={influencer.userId}
                showInCustomerViewMode={true}
                onAddComment={(comment) => {
                    slug && refreshInfos(slug)
                }}
            />}
        </>
    )
}

