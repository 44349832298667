import React, {useState} from 'react';
import { useTranslation } from 'react-i18next';
import './ChoosePage.scss';

import logo from '../../../assets/images/Clark_LogoNoir.png';
import { useAuth } from '../../../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../constants/routes';
import i18next from "i18next";
import {toast} from "react-toastify";

const ChoosePage = () => {
  const { currentUser } = useAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "fr")

  const employeeClicked = () => {
    if (currentUser) {
      navigate(ROUTES.EMPLOYEE.HOME);
    } else {
      navigate(ROUTES.EMPLOYEE.LOGIN);
    }
  };

  const changerLanguage = (lang: string) => {
    setLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    toast.success(t('language.update_success'));
    i18next.changeLanguage(lang);
  };

  return (
    <div className="h-screen flex flex-col justify-center items-center bg-primary">
      <img src={logo} className="mb-28 logo" />
      <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              {language === "fr" ? "🇫🇷" : "🇺🇸"}
          </label>
          <ul tabIndex={0}
              className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
              <li><a onClick={() => changerLanguage("fr")}>🇫🇷 {t("french")}</a></li>
              <li><a onClick={() => changerLanguage("en")}>🇺🇸 {t("english")}</a></li>
          </ul>
      </div>
      <br/>
      <div className="flex justify-center gap-4">
        <button onClick={employeeClicked} className="btn btn-outline">
          {t('employee-homepage')}
        </button>
        <button className="btn btn-outline" onClick={() => navigate(ROUTES.CUSTOMER.AUTH)}>
          {t('customer-homepage')}
        </button>
      </div>
    </div>
  );
};

export default ChoosePage;
