export const ROUTES = {
    EMPLOYEE: {
        HOME: `/`,
        PREFIX: '/employee',
        CAMPAIGNS: `/employee/campaigns`,
        INFLUENCER_LISTS: `/employee/influencers-lists`,
        INFLUENCER_LIST: `/employee/influencers-lists/:listId`,
        INFLUENCERS: `/employee/influencers`,
        INFLUENCER: `/employee/influencers/:influencerId`,
        SEARCH: `/employee/search`,
        PROFILE: `/employee/profile`,
        SIGNUP: `/employee/auth/signup`,
        LOGIN: `/employee/auth/login`,
        FORGOT_PASSWORD: `/employee/auth/forgot-password`,
        USERS_SETTINGS: '/employee/users-settings'
    },
    CHOOSE: '/choose',
    CUSTOMER: {
        PREFIX: '/customer',
        TERMSOFUSEEN: '/customer/termsofuse-en',
        TERMSOFUSEFR: '/customer/termsofuse-fr',
        CERTIFIEDINFLUENCERS: '/customer/certified-influencers',
        AUTH: '/customer/:slug/auth',
        OVERALL: "/customer/:slug/overall",
        INFLUENCER: "/customer/:slug/influencers/:influencerId"
    },
};