import React, {useEffect, useState} from 'react';
import {useFirebase} from '../../contexts/FirebaseContext';

import {DataTable} from 'grommet';
import {useTranslation} from "react-i18next";
import {Campaign, InfluencerList} from "../../types/dbModels";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
// Images - Flags
import imgMegaphoneDone from "../../assets/images/megaphone-done.png";
import imgMegaphoneSimple from "../../assets/images/megaphone-simple.png";
import emptyFile from "../../assets/images/emptyfile.png";
import {ROUTES} from "../../constants/routes";
import FormInput from "../FormInput";
import ConfirmMessage from '../modal/ConfirmMessage';
import Loading from '../loading/Loading';
import moment from 'moment';
import 'moment/locale/fr';

export default function CampaignsDatatable({campaigns = [], refreshCampaigns}: {
    campaigns: Campaign[],
    refreshCampaigns: () => any
}) {
    const {getInfluencerLists, deleteCampaign: firebaseDeleteCampaign} = useFirebase();
    const {t} = useTranslation();
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(true);
    const [campaignIdToDelete, setCampaignIdToDelete] = useState<any>();
    const [influencerLists, setInfluencerLists] = useState<InfluencerList[]>([])

    useEffect(() => {
        reloadDataTable();
    }, []);


    const reloadDataTable = async () => {
        let lists = await getInfluencerLists();
        setInfluencerLists(lists ?? []);
        refreshCampaigns();
        setLoading(false);
    }

    function renderNetwork(list: any) {
        switch (list.status) {
            case 'signed':
                return <img src={imgMegaphoneDone} className="flag-table" alt='Signed'/>;
            case 'draft':
                return <img src={imgMegaphoneSimple} className="flag-table" alt='Draft'/>;
        }
    }

    const deleteCampaign = async (campaignId: string) => {
        const resp = await firebaseDeleteCampaign(campaignId);
        if (resp) {
            reloadDataTable();
        }
    }

    return (
        <>
                {loading && (
                    <Loading/>
                )}

                {!loading && campaigns.length > 0 && (
                    <div className="my-10">
                        <DataTable
                            sortable={true}
                            paginate={true}
                            step={20}
                            background={["white", "light-2"]}
                            primaryKey="id"
                            columns={[
                                {
                                    property: 'name',
                                    header: t('labels.name'),
                                    primary: false,
                                    search: true,
                                    render: (data) => {
                                        const list = influencerLists.find(l => l.id === data.list);
                                        return (
                                            <div className="flex flex-row items-center hand" onClick={() => {
                                                navigate(ROUTES.EMPLOYEE.INFLUENCER_LIST.replace(':listId', data.list))
                                            }}>
                                                <div className="avatar mr-3">
                                                    {renderNetwork(list)}
                                                </div>
                                                <div className="flex flex-col text-start">
                                                    <p className='font-bold text-start txtEllipsis nowrap'>{data?.name}</p>
                                                    <p className='text-start txtEllipsis nowrap'>{list?.name}</p>
                                                </div>
                                            </div>
                                        )
                                    }
                                },
                                {
                                    property: 'client',
                                    header: t('labels.client'),
                                    search: true,
                                    render: (data) => {
                                        return <>{data.client}</>
                                    },
                                },
                                {
                                    property: 'endDate',
                                    header: t("table-end-date"),
                                    render: (data) => {
                                        return <>{moment(data.endDate).locale(moment().locale(localStorage.getItem("i18nextLng") || "fr")).fromNow()}</>
                                    },
                                },
                                {
                                    property: 'status_ext',
                                    header: t("last-update"),
                                    search: false,
                                    render: (data: Campaign) => {
                                        return <StatusSelect internal={false} campaign={data}/>
                                    },
                                },
                                {
                                    property: 'office',
                                    header: t('labels.office'),
                                    search: true,
                                    render: (data) => {
                                        return <>{t('labels.offices.' + data.office)}</>
                                    },
                                },
                                {
                                    property: '',
                                    header: '',
                                    primary: false,
                                    render: data => {
                                        return <button
                                            className='btn btn-ghost btn-xs btn-circle'
                                            onClick={() => {
                                                (window as any)["modal_confirm_delete_campaign"].showModal();
                                                setCampaignIdToDelete(data.id);
                                            }}>
                                            🗑️
                                        </button>
                                    }
                                }
                            ]}
                            data={campaigns}
                        />
                    </div>
                )}

                {!loading && campaigns.length === 0 && (
                    <div className='flex flex-col justify-center text-center my-16'>
                        <img src={emptyFile} className='imgEmpty' alt="empty" />
                        <p className='mt-8'>{t("no-campaign")}</p>
                    </div>
                )}



                <ConfirmMessage
                    name="modal_confirm_delete_campaign"
                    title="Suppression d'une campagne"
                    message='Etes-vous sur de vouloir supprimer cette campagne ?'
                    onSubmit={() => deleteCampaign(campaignIdToDelete)}
                />
        </>
    );
}


function StatusSelect({internal = true, campaign}: { internal?: boolean, campaign: Campaign }) {
    const {getCampaignAvailableStatusExternal, putCampaign} = useFirebase();

    const [status, setStatus] = useState<string>();
    const {t} = useTranslation();

    useEffect(() => {
        setStatus(campaign.status_ext);
    }, [campaign]);

    useEffect(() => {
        updateStatus();
    }, [status]);

    const updateStatus = async () => {
        if (!status || !campaign) return;
        if (status === campaign.status_ext) return;

        try {
            await putCampaign(campaign.id, {status_ext: status});
            campaign.status_ext = status;
            toast.success(t("message-campaign-updated"));
        } catch (err) {
            toast.error(t("error-edit-campaign-status"));
            setStatus(campaign.status_ext);
        }
    }

    return <FormInput
        clearable={false}
        type="select"
        onChange={(e) => {
            setStatus(e.value.toString());
        }}
        name="status"
        value={status}>
        {getCampaignAvailableStatusExternal().map((status: any) => (
            <option key={status.id} value={status.id}>
                {status.text}
            </option>
        ))}
    </FormInput>
}