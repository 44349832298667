import React, {useRef, useState} from 'react';
import {useAuth} from '../../contexts/AuthContext';
import {Link, useNavigate} from 'react-router-dom';
import logo from '../../assets/images/Clark_LogoNoir.png';
import {ROUTES} from '../../constants/routes';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

export default function Signup() {
    const emailRef = useRef<any>(null);
    const passwordRef = useRef<any>(null);
    const passwordConfirmRef = useRef<any>(null);
    const {signup} = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();

    async function handleSubmit(e: any) {
        e.preventDefault();

        if (passwordRef.current?.value !== passwordConfirmRef.current?.value) {
            toast.error(t('login.password_not_match'))
            return;
        }

        try {
            setLoading(true);
            await signup(emailRef.current?.value, passwordRef.current?.value);
            navigate(ROUTES.EMPLOYEE.LOGIN);
        } catch {
            toast.error(t('login.account_create_error'))
        }

        setLoading(false);
    }

    return (
        <div
            className="relative flex flex-col justify-center h-screen overflow-hidden"
            style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}
        >
            <div className="w-full p-6 m-auto bg-white rounded-md lg:max-w-xl">
                <div className="flex justify-center mb-8">
                    <img src={logo} alt="Clark Logo"/>
                </div>
                <div className="mb-14">
                    <h2 className="text-center text-3xl font-bold tracking-tight text-gray-900">
                        {t('login.account_create')}
                    </h2>
                </div>

                <form
                    className="space-y-4 flex flex-col items-center justify-center"
                    onSubmit={handleSubmit}
                >
                    <div>
                        <label className="label">
                            <span className="text-base label-text">{t('labels.email')}</span>
                        </label>
                        <input
                            className="w-full input input-bordered"
                            id="email-address"
                            name="email"
                            type="email"
                            autoComplete="email"
                            ref={emailRef}
                            required
                            placeholder={t('labels.email')}
                        />
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text">{t('labels.password')}</span>
                        </label>
                        <input
                            className="w-full input input-bordered"
                            id="password"
                            name="password"
                            type="password"
                            ref={passwordRef}
                            required
                            placeholder={t('labels.password')}
                        />
                    </div>
                    <div>
                        <label className="label">
                            <span className="text-base label-text">{t('labels.password_confirm')}</span>
                        </label>
                        <input
                            className="w-full input input-bordered"
                            id="confirm-password"
                            name="confirm-password"
                            type="password"
                            ref={passwordConfirmRef}
                            required
                            placeholder={t('labels.password_confirm')}
                        />
                    </div>

                    <button
                        type="submit"
                        disabled={loading}
                        className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-md"
                    >
                        {t('labels.signup')}
                    </button>
                    <div>
                        <div>
                            <Link
                                className="text-blue-600 hover:text-blue-800 hover:underline"
                                to={ROUTES.EMPLOYEE.LOGIN}
                            >
                                {t('login.back_to_login')}
                            </Link>
                        </div>
                    </div>
                </form>
            </div>
            <div style={{backgroundColor: 'lightgrey'}}></div>
        </div>
    );
}
