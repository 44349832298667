import React, {useEffect, useState} from 'react';
import CreateListModal from '../../../modal/CreateListModal';
import {useFirebase} from '../../../../contexts/FirebaseContext';

// Styles
import "./InfluencerListPage.scss"

import {Link, NavLink} from 'react-router-dom';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

// Images - Flags
import {ROUTES} from '../../../../constants/routes';
import InfluencerListsDatatable from "../../../datatables/InfluencerListsDatatable";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";
import {faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons"
import {Influencer, InfluencerList} from "../../../../types/dbModels";


library.add(faMagnifyingGlass);

export default function InfluencerListPage() {
    const {getInfluencerLists, bigInfluencerPatch} = useFirebase();
    const {t} = useTranslation();

    const modalName = 'create_list_modal';
    const [lists, setLists] = useState<any>([]);

    useEffect(() => {
        bigInfluencerPatch();
        reloadDataTable();
    }, []);

    function onResponse(valid: boolean, _: any) {
        if (valid) {
            toast.success(t('list.add_success'));
            reloadDataTable();
        } else {
            toast.error(t('list.add_error'))
        }
    }

    function reloadDataTable() {
        getInfluencerLists().then((res: any) => {
            setLists(res);
        }).catch((err) => {
            setLists([]);
        });
    }

    return (
        <>
            {/* Breadcrumb */}
            <div className="text-sm breadcrumbs">
                <ul>
                    <li><NavLink to={ROUTES.EMPLOYEE.HOME}>{t("home-title")}</NavLink></li>
                    <li>{t("all-lists")}</li>
                </ul>
            </div>

            {/* Content */}
            <div className="card bg-base-200">
                <div className="card-body">
                    <h1 className="text-3xl font-bold">📂 {t("all-lists")}</h1>
                    <h3 className="">{t("influencers-list")} {lists ? ('('+lists.length+')') : ''}</h3>
                    <div className="flex justify-end gap-4">
                        <button
                            className="btn btn-primary"
                            onClick={() => (window as any)[modalName].showModal()}>
                            ➕ {t('list.create')}
                        </button>
                        <Link to={ROUTES.EMPLOYEE.SEARCH} className='btn btn-accent'>
                            <FontAwesomeIcon icon={['fas', 'magnifying-glass']}/> {t("new-search")}
                        </Link>
                    </div>

                    <InfluencerListsDatatable influencerLists={lists.sort((a : Influencer, b : Influencer) => b.update_date - a.update_date)} refreshInfluencerLists={reloadDataTable}/>
                </div>
            </div>

            <CreateListModal name={modalName} onResponse={onResponse}/>
        </>
    );
}
