import React from "react";

import {CommentType, InfluencerList} from "../../types/dbModels";
import InfluencerComments from "../influencer/InfluencerComments";

type Props = {
    name: string;
    influencerId: string,
    influencerList: InfluencerList,
    showInCustomerViewMode: boolean,
    onAddComment?: (comment: CommentType) => void
};

export default function InfluencerCommentsModal({
    onAddComment,
                                                    name,
                                                    influencerId,
                                                    influencerList,
                                                    showInCustomerViewMode = false,
                                                }: Props) {

    return (
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-4xl">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>

                    {influencerList &&
                        <InfluencerComments
                            influencerId={influencerId as string}
                            influencerListId={influencerList.id}
                            showInternal={!showInCustomerViewMode}
                            onUpdateComment={onAddComment}
                        />
                    }
                </form>

                <form method="dialog" className="modal-backdrop">
                    <button>close</button>
                </form>
            </dialog>
        </div>
    )
}
