import React, {useEffect, useState} from 'react';
import logo from '../../../assets/images/Clark_LogoNoir.png';
import {NavLink, Outlet, useLocation, useNavigate, useParams} from 'react-router-dom';

import './CustomerLayout.scss';
import {ROUTES} from '../../../constants/routes';
import {useCustomer} from "../../../contexts/CustomerContext";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {useTranslation} from "react-i18next";
import {CUSTOMER_AUTH_KEY} from '../../../constants/localstorage';
import {toast} from "react-toastify";
import i18next from "i18next";


export default function CustomerLayout() {
    const [showCampaignCard, setShowCampaignCard] = useState<boolean>(JSON.parse(localStorage.getItem('show-customer-campaing-card') || "true") as boolean);

    const location = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [location]);

    return (
        <>
            <div>
                <NavBar onMenuClick={() => {
                    localStorage.setItem('show-customer-campaing-card', (!showCampaignCard) + "");
                    setShowCampaignCard(!showCampaignCard)
                }
                }/>
            </div>

            {showCampaignCard ? (
                <div className="flex gap-6" style={{backgroundColor: 'white'}}>
                    <div className="w-1/5 max-w-xs overflow-hidden flex-none p-2" style={{backgroundColor: 'white'}}>
                        <CampaignCard/>
                    </div>
                    <div className="w-4/5 grow p-2" style={{backgroundColor: 'white'}}>
                        <div className="container max-w-7xl">
                            <Outlet/>
                        </div>
                    </div>
                </div>
            ) : (
                <div style={{backgroundColor: 'white'}}>
                    <div style={{backgroundColor: 'white'}}>
                        <div className="container">
                            <Outlet/>
                        </div>
                    </div>

                </div>
            )}
        </>
    );
}

function CampaignCard() {
    const {t} = useTranslation();
    const {influencerList, influencers, campaign} = useCustomer();
    const {slug, influencerId} = useParams();
    const navigate = useNavigate();


    const [shouldShowDetails, setShouldShowDetails] = useState<boolean>(false);

    useEffect(() => {
        if (slug && influencerId && influencerList) {
            setShouldShowDetails(true);
        } else {
            setShouldShowDetails(false)
        }
    }, [influencerId, slug, influencerList, campaign]);

    useEffect(() => {
        if (influencerList && influencerId) {
            const influencer = influencerList.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencerId);
            if (!influencer) return;
        }
    }, [influencerId, influencerList]);


    const navigateToInfluencer = (targetIndex: number) => {
        if (!influencerId || !slug) return;

        const actualIndex = influencers.findIndex(i => i.userId === influencerId);
        const newIndex = (actualIndex + targetIndex + influencers.length) % influencers.length;
        const newInfluencerId = influencers[newIndex].userId;

        navigate(
            ROUTES.CUSTOMER.INFLUENCER
                .replace(":slug", slug)
                .replace(":influencerId", newInfluencerId)
        );
    }

    const goToNextInfluencer = () => {
        navigateToInfluencer(1);
    }

    const goToPreviousInfluencer = () => {
        navigateToInfluencer(-1);
    }

    return (
        <div className="h-screen justify-between">
            <div>
                {/* Clark X Customer */}
                <div className="flex flex-col justify-center gap-2 mb-4">
                    <img src={logo} alt="Clark Logo" className='w-20 align-center self-center'/>
                    {campaign && (
                        <>
                            <h1 className='text-xl text-center font-bold'>x</h1>
                            <h1 className='text-xl text-center font-bold'>{campaign.client}</h1>
                        </>
                    )}
                </div>


                <hr className='my-8'/>
                <div>
                    <h3 className='text-3xl text-center leading-normal font-bold mb-4 break-normal'>{campaign?.name ?? influencerList?.name}</h3>
                    <div className='text-center text-sm'>
                        {campaign?.status_ext === "validated" && (
                            <div className="badge badge-lg badge-success">
                                {t('labels.status_ext_campaign.' + campaign?.status_ext)}
                            </div>
                        )}
                        {campaign?.status_ext === "in_review" && (
                            <div className="badge badge-lg badge-ghost">
                                {t('labels.status_ext_campaign.' + campaign?.status_ext)}
                            </div>
                        )}
                    </div>
                </div>
                <hr className='my-8'/>
                {influencerList?.note && (
                    <div className='bg-base-200 m-2 mb-0 p-2 card text-center'>
                        <h5 className="text-xl font-bold">ℹ️ Notes</h5>
                        <hr className='my-2'/>
                        <p className='my-4 text-red-600'>
                            {influencerList.note}
                        </p>
                    </div>
                )}

                {/* Image sidebar campaign */}
                {/* <img src={placeholder} alt="Campaign Img" className='h-32 rounded-lg mt-8'/> */}
                {/* Status recap */}

                <div className='flex flex-row justify-center gap-2 text-center py-8'>
                    <table className="table w-full">
                        <tr>
                            {/* Coup de coeur */}
                            <td className="p-2 text-center items-center justify-center">
                                <h5 className='text-2xl font-bold'>
                                    {influencerList?.influencersCategories.map(m => m.influencers).flat().reduce((current, i) => {
                                        if (i.state === 'loved') return current + 1;
                                        return current;
                                    }, 0)}
                                </h5>
                                <span className='text-sm'>{t('labels.influencer_state.loved_small')}</span><br/>
                                <FontAwesomeIcon icon={['fas', 'heart']} color='purple'/>
                            </td>

                            {/* Accepté */}
                            <td className="p-2 text-center items-center justify-center">
                                <h5 className='text-2xl font-bold'>
                                    {influencerList?.influencersCategories.map(m => m.influencers).flat().reduce((current, i) => {
                                        if (i.state === 'accepted') return current + 1;
                                        return current;
                                    }, 0)}
                                </h5>
                                <span className='text-sm'>{t('labels.influencer_state.accepted')}</span><br/>
                                <FontAwesomeIcon icon={['fas', 'check']} color='green'/>
                            </td>

                            {/* Refusé */}
                            <td className="p-2 text-center items-center justify-center">
                                <h5 className='text-2xl font-bold'>
                                    {influencerList?.influencersCategories.map(m => m.influencers).flat().reduce((current, i) => {
                                        if (i.state === 'rejected') return current + 1;
                                        return current;
                                    }, 0)}
                                </h5>
                                <span className='text-sm'>{t('labels.influencer_state.rejected')}</span><br/>
                                <FontAwesomeIcon icon={['fas', 'times']} color='red'/>
                            </td>
                        </tr>
                    </table>
                </div>

                {shouldShowDetails && <>
                    <hr/>
                    <div className='flex justify-center gap-2 mt-2'>
                        <button className="btn btn-sm btn-circle" onClick={goToPreviousInfluencer}>
                            <FontAwesomeIcon size='lg' icon={['fas', 'arrow-left']}/>
                        </button>
                        <button className="btn btn-sm btn-circle" onClick={goToNextInfluencer}>
                            <FontAwesomeIcon size='lg' icon={['fas', 'arrow-right']}/>
                        </button>
                    </div>
                </>}
            </div>

            <div className="flex justify-center my-8">
                <button
                    className={'btn btn-sm'}
                    onClick={() => {
                        localStorage.removeItem(CUSTOMER_AUTH_KEY);
                        navigate(ROUTES.CUSTOMER.AUTH.replace(':slug', influencerList?.link || ':slug'))
                    }}>
                    <FontAwesomeIcon size='lg' icon={['fas', 'power-off']}/>
                    {t("logout")}
                </button>
            </div>
        </div>
    );
}

function NavBar({onMenuClick}: { onMenuClick: () => void }) {
    const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "fr")
    const {t} = useTranslation();

    const {slug, influencerId} = useParams();

    const changerLanguage = (lang: string) => {
        setLanguage(lang);
        localStorage.setItem("i18nextLng", lang);
        toast.success(t('language.update_success'));
        i18next.changeLanguage(lang);
    }

    return (
        <div className="navbar bg-base-100 ">
            <div className="navbar-start pl-4">
                <button type='button' onClick={onMenuClick}>
                    <FontAwesomeIcon icon={['fas', 'bars']} size='2xl'/>
                </button>

            </div>
            <div className="navbar-center">
                <ul className="menu menu-sm flex-row items-center flex  bg-base-100">
                    <li className="mr-2">
                        <NavLink className="text-lg" to={ROUTES.CUSTOMER.OVERALL.replace(":slug", slug || "")}>
                            ✍️ {t("menu-influencers-choice")}
                        </NavLink>
                    </li>
                    <li className="mr-2">
                        <NavLink className="text-lg" to="/notavailable" onClick={(e) => {
                            e.preventDefault();
                            (window as any)["not_available"].showModal();
                        }}
                        >
                            💕 {t("menu-influencers-relation")}
                        </NavLink>
                    </li>
                    <li className="mr-2">
                        <NavLink className="text-lg" to="/notavailable" onClick={(e) => {
                            e.preventDefault();
                            (window as any)["not_available"].showModal();
                        }}
                        >
                            📊 {t("menu-campaign-followup")}
                        </NavLink>
                    </li>
                    <li className="mr-2">
                        <NavLink className="text-lg" to="/notavailable" onClick={(e) => {
                            e.preventDefault();
                            (window as any)["not_available"].showModal();
                        }}
                        >
                            📄 {t("menu-campaign-report")}
                        </NavLink>
                    </li>
                </ul>
            </div>

            {/* End */}
            <div className="navbar-end gap-2">
                {/* Language */}
                <div className="dropdown dropdown-end">
                    <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
                        {language === "fr" ? "🇫🇷" : "🇺🇸"}
                    </label>
                    <ul tabIndex={0}
                        className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
                        <li><a onClick={() => changerLanguage("fr")}>🇫🇷 {t("french")}</a></li>
                        <li><a onClick={() => changerLanguage("en")}>🇺🇸 {t("english")}</a></li>
                    </ul>
                </div>
                <p className="text-md">

                </p>
                {/* Profile picture */}
                <div className="dropdown dropdown-end">

                </div>
            </div>

            {/* Not available modal */}
            <dialog id="not_available" className="modal">
                <div className="modal-box">
                    <h3 className="font-bold text-2xl">Oops</h3>
                    <p className="py-4">{t("error-view-not-available-campaign")}</p>
                    <div className="modal-action">
                        <form method="dialog">
                            <button className="btn">{t("labels.close")}</button>
                        </form>
                    </div>
                </div>
            </dialog>

        </div>
    );
}