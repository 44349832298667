import React, { FC } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import Footer from '../layout/Footer';

const PrivateRoutes: FC = () => {
  const { currentUser } = useAuth();

  return currentUser ? (
    <>
      <div
        className="min-h-screen"
        style={{
          display: 'grid',
          gridTemplateRows: '1fr min-content min-content',
        }}
      >
        <Outlet />
        <Footer />
      </div>
    </>
  ) : (
    <Navigate to="/choose" />
  );
};

export default PrivateRoutes;
