import React, {useEffect, useState} from "react";
import {InfluencerList, Networks} from "../../types/dbModels";
import {useTranslation} from "react-i18next";
import {useFirebase} from "../../contexts/FirebaseContext";
import {InfluencerReport} from "../../types/api";
import {toast} from "react-toastify";
import {useApi} from "../../contexts/ApiContext";
import {generateRandomStringId} from "../../helpers/functions";
import {TextInput} from "grommet";
import Loading from "../loading/Loading";
import CreateListModal from "../modal/CreateListModal";

type SelectCampaignProps = {
    influencerId: string;
    platform: Networks;
    showCreate?: boolean;
}

export default function SelectAvailableList({platform, influencerId, showCreate = true}: SelectCampaignProps) { // il faut le report et la platform
    const [availableLists, setAvailableLists] = useState<InfluencerList[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const {
        postInfluencer,
        getInfluencer,
        putInfluencer,
        addInfluencerToInfluencerList,
        influencerLists: firebaseLists
    } = useFirebase();
    const {t} = useTranslation();
    const {getReport} = useApi();

    const createListModalName = "create_list_modal_" + generateRandomStringId();

    const showModal = () => {
        (window as any)[createListModalName].showModal()
    }

    useEffect(() => {
        updateAvailableLists();
    }, [influencerId, firebaseLists]);

    // Réponse de la création de la liste
    function onResponse(valid: boolean, createdListDocument: any) {
        if (valid) {
            applyListChanges(createdListDocument.id);
        }
    }

    const applyListChanges = async (listId: string) => {
        if (!listId) return;
        if (!loading) setLoading(true)
        // Chercher si l'influencerReport existe dans firebase
        const firebaseInfluencer = await getInfluencer(influencerId);
        let report: InfluencerReport | null = null;
        try {
            report = await getReport(influencerId, platform);
            if (!report) throw new Error();

            if (firebaseInfluencer === null) {
                // Ajout de l'influencer dans la base de données
                await postInfluencer(report, platform)
            } else {
                // Mis à jour des informations
                await putInfluencer(report.profile.userId, {[platform]: report.profile})
            }


        } catch (error) {
            toast.error(t('error-get-reports'));
        }

        if (report) {
            try {
                await addInfluencerToInfluencerList(listId, report.profile.userId, platform)

                updateAvailableLists();
                toast.success(t('influencer.add_to_list_success'))
            } catch (_) {
                toast.success(t('influencer.add_to_list_error'))
            }
        }

        setLoading(false)
    }

    const updateAvailableLists = () => {
        if (!influencerId) return;
        setAvailableLists(
            firebaseLists.filter(l =>
                l.influencersCategories.every(c => c.influencers.findIndex(i => i.userId === influencerId) < 0))
        );
    }

    function influencersAlphabeticSorting(names: string[]) {
        for (let i = 0; i < names.length - 1; i++) {
            for (let j = 0; j < names.length - i - 1; j++) {
                if (compareStrings(names[j], names[j + 1]) > 0) {
                    // Échange les objets si nécessaire pour les ordonner
                    [names[j], names[j + 1]] = [names[j + 1], names[j]];
                }
            }
        }

        return names;
    }

    function compareStrings(a: string, b: string) {
        const stringWithoutSpacesA = a.replace(/\s/g, '').toLowerCase();
        const stringWithoutSpacesB = b.replace(/\s/g, '').toLowerCase();

        const minLength = Math.min(stringWithoutSpacesA.length, stringWithoutSpacesB.length);

        for (let i = 0; i < minLength; i++) {
            const charCodeA = stringWithoutSpacesA.charCodeAt(i);
            const charCodeB = stringWithoutSpacesB.charCodeAt(i);

            if (charCodeA !== charCodeB) {
                return charCodeA - charCodeB;
            }
        }

        return stringWithoutSpacesA.length - stringWithoutSpacesB.length;
    }

    const [listNameInputValue, setListNameInputValue] = useState<string>('');
    const [listSuggestions, setListSuggestions] = useState<string[]>([]);


    const onListNameInputValueChange = (event: any) => {
        const allSuggestions = availableLists.map(l => l.name);

        const nextValue = event.target.value;
        setListNameInputValue(nextValue);
        if (!nextValue) setListSuggestions(allSuggestions)
        else {
            const regexp = new RegExp(`^${nextValue.toLowerCase()}`);
            setListSuggestions(allSuggestions.filter((s) => regexp.test(s.toLowerCase())));
        }
    }

    const onSuggestionSelect = (event: any, n: string) => {
        setLoading(true);
        const selectedList = availableLists.find(l => l.name === event.suggestion);
        if (!selectedList) {
            toast.error(t("error-list-notfound"));
            setLoading(false)
            return;
        }
        applyListChanges(selectedList.id);
    }

    // UseEffects

    useEffect(() => {
        setListSuggestions(availableLists.map(l => l.name))
    }, [availableLists]);


    if (loading) {
        return <Loading/>
    }

    return (
        <div>
            <div className='flex gap-4'>
                <TextInput
                    id="grommet-text-combobox"
                    className="input input-bordered w-full"
                    value={listNameInputValue}
                    name={"influencer-location"}
                    onChange={onListNameInputValueChange}
                    onSuggestionSelect={(event: any) => onSuggestionSelect(event, 'influencer-location')}
                    suggestions={influencersAlphabeticSorting(listSuggestions)}
                    placeholder={t("list.please-select-list")}
                    style={{minWidth: '200px'}}
                />

                {showCreate && <button className='btn btn-primary' onClick={showModal}>{t("list.create")}</button>}
            </div>
            {showCreate && <CreateListModal name={createListModalName} onResponse={onResponse}/>}

        </div>
    )
}