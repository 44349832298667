import React, {useEffect, useState} from 'react';
import {SearchFormData, SearchResults} from "../../../../types/api";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../../constants/routes";
import InfluencerSearchForm from "../../../influencer/Search/InfluencerSearchForm";
import InfluencerSearchResults from "../../../influencer/Search/InfluencerSearchResults";
import {useApi} from "../../../../contexts/ApiContext";
import InfluencerSearchChoiceModal from "../../../modal/InfluencerSearchChoiceModal";

export default function InfluencerSearchPage() {
    const modalName = 'influencer-search-choice-mal';
    const singleInfluencerModalName = 'influencer-search-single-modal';
    const navigate = useNavigate();
    const {search, getReport} = useApi()

    const [formData, setFormData] = useState<SearchFormData>();
    const [searchResults, setSearchResults] = useState<SearchResults>();
    const [searchLoading, setSearchLoading] = useState(false);

    const formSubmitHandler = (data: SearchFormData) => {
        data.page = 0;
        setFormData(data);
        if(data) {
            setSearchResults(undefined);
        }
    }

    const onChoiceSearchHandler = async () => {
        if (!formData) return;

        try {
            setSearchLoading(true);

            if(formData?.users.length > 0) {

                const reports = [];

                for (const user of formData?.users) {
                    const report = await getReport(user.id, formData?.platform);
                    // Wait 1 second to avoid rate limit
                    await new Promise(resolve => setTimeout(resolve, 1000));
                    if (report && !report.error) {
                        reports.push(report.profile);
                    }
                }

                const search = {
                    error:false,
                    lookalikes: reports,
                    total: reports.length
                }
                setSearchResults(search as any);
            } else {
                const responseData = await search(formData);
                if (responseData) {
                    setSearchResults(responseData);
                }
            }
            setSearchLoading(false);
        } catch (error) {
            setSearchLoading(false);
        }
    }

    const onChoiceCancelHandler = (redirection: boolean) => {
        resetHandler()
        if (redirection) {
            navigate(ROUTES.EMPLOYEE.INFLUENCER_LISTS);
            return;
        }
    }

    const resetHandler = () => {
        setFormData(undefined)
        setSearchResults(undefined)
    }

    const loadNextPage = () => {
        let oNewFormData = formData;
        if (oNewFormData) {
            oNewFormData.page = (oNewFormData.page || 0) + 1;
            setFormData(oNewFormData)
            onChoiceSearchHandler();
        }
    }

    const loadPrevPage = () => {
        let oNewFormData = formData;
        if (oNewFormData) {
            oNewFormData.page = (oNewFormData.page || 1) - 1;
            setFormData(oNewFormData)
            onChoiceSearchHandler();
        }
    }

    useEffect(() => {
        try {
            if(formData && !searchResults) {
                (window as any)[modalName].showModal()
            }
            if (formData && formData?.users) {
                (window as any)[singleInfluencerModalName].showModal()
            }
        } catch {
            // nothing
        }
    }, [formData, searchResults]);

    return (
        <>
            {/* VIEW: Search form */}
            <InfluencerSearchForm onSubmit={formSubmitHandler} searchLoading={searchLoading}/>

            {/* VIEW: Result of search */}
            {(!searchLoading && formData && searchResults) &&
                <InfluencerSearchResults
                    platform={formData?.platform}
                    searchResults={searchResults}
                    onReset={resetHandler}
                    currentPage={formData?.page}
                    nextPage={loadNextPage}
                    prevPage={loadPrevPage}
                />
            }

            {/* MODAL: Are you sure to launch the search */}
            <InfluencerSearchChoiceModal
                name={modalName}
                onSearch={onChoiceSearchHandler}
                onCancel={onChoiceCancelHandler}
            />
        </>
    );
}

