import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React, {useEffect, useState} from "react";
import {useCustomer} from "../../contexts/CustomerContext";
import {InfluencerListInfluencerState} from "../../types/dbModels";
import {useParams} from "react-router-dom";

type Props = {
    influencerId: string
}

export default function StateJudging({influencerId}: Props) {
    const {slug} = useParams();

    const {
        changeInfluencerState,
        influencerList,
    } = useCustomer();

    const [influencerState, setInfluencerState] = useState<InfluencerListInfluencerState>()

    useEffect(() => {
        if (!influencerId || !influencerList) return;

        const entry = influencerList.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencerId);
        setInfluencerState(entry);

    }, [influencerId, influencerList]);


    if (!influencerId || !influencerState || !slug ) return;

    return (
        <div
            className="card-actions justify-center border rounded-xl border-gray-500 px-2">
            <div
                className='flex justify-center gap-2 my-2'>
                <button
                    className={`btn btn-sm btn-square ${influencerState.state === "loved" ? ' btn-outline btn-active btn-pink ' : 'btn-ghost'}`}
                    onClick={() => changeInfluencerState(slug, influencerState, 'loved')}
                >
                    <FontAwesomeIcon
                        icon={['fas', 'heart']}/>
                </button>
                <button
                    className={`btn btn-sm btn-square ${influencerState.state === "accepted" ? ' btn-outline btn-active btn-success' : 'btn-ghost'}`}
                    onClick={() => changeInfluencerState(slug, influencerState, 'accepted')}
                >
                    <FontAwesomeIcon
                        size='lg'
                        icon={['fas', 'check']}/>
                </button>
                <button
                    className={`btn btn-sm btn-square ${influencerState.state === "rejected" ? 'btn-outline btn-active btn-error' : 'btn-ghost'}`}
                    onClick={() => changeInfluencerState(slug, influencerState, 'rejected')}
                >
                    <FontAwesomeIcon
                        size='lg'
                        icon={['fas', 'xmark']}/>
                </button>
            </div>
        </div>
    )

}
