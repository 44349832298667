import React, {useContext, useEffect, useState} from 'react';
import {auth, db} from '../firebase';
import {collection, getDocs, query, where} from 'firebase/firestore';
import {User} from '../types/user';
import {AuthContextType} from '../types/auth';

const AuthContext = React.createContext<AuthContextType | null>(null);

export function useAuth() : AuthContextType {
  return useContext(AuthContext) as AuthContextType;
}

type Props = {
  children: any;
};



export function AuthProvider({ children }: Props) {
  // State
  const [currentUser, setCurrentUser] = useState<User>();
  const [currentUserData, setCurrentUserData] = useState<any>();
  const [loading, setLoading] = useState(true);

  // ------------------------------------------------------------------
  // Listeners
  // ------------------------------------------------------------------

  useEffect(() => {
    return auth.onAuthStateChanged(async (user: any) => {
      setCurrentUser(user);

      if (user) {
        // Load user data
        const colUsers = collection(db, 'Users');

        // #2 Query
        const q = query(colUsers, where('uid', '==', user.uid));
        let aDocs = await getDocs(q).then((querySnapshot) => {
          return querySnapshot.docs.map((doc) => ({
            ...doc.data(),
            id: doc.id,
          }));
        });

        setCurrentUserData(aDocs[0]);
      }

      setLoading(false);
    });
  }, []);

  // ------------------------------------------------------------------
  // Methods
  // ------------------------------------------------------------------

  const signup = (email: string, password: string) => {
    return auth.createUserWithEmailAndPassword(email, password);
  };

  const login = async (email: string, password: string) => {
    return await auth.signInWithEmailAndPassword(email, password);
  };

  const logout = () => {
    return auth.signOut();
  };

  const resetPassword = (email: string) => {
    return auth.sendPasswordResetEmail(email);
  };

  const updateEmail = (email: string) => {
    return currentUser?.updateEmail(email);
  };

  const updatePassword = (password: string) => {
    return currentUser?.updatePassword(password);
  };

  const getCurrentUserToken = () => {
    return new Promise<string | null>(() => null);
  }

  // ------------------------------------------------------------------
  // Interface
  // ------------------------------------------------------------------

  const value : AuthContextType = {
    currentUser,
    currentUserData,
    login,
    signup,
    logout,
    resetPassword,
    updateEmail,
    updatePassword,
    getCurrentUserToken
  };

  

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
