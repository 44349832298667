import {
    InfluencerList,
    InfluencerListInfluencerState,
    InfluencerListInfluencerStatus
} from "../../../../../../types/dbModels";
import {useFirebase} from "../../../../../../contexts/FirebaseContext";
import {useTranslation} from "react-i18next";
import React, {useEffect} from "react";
import {toast} from "react-toastify";
import FormInput from "../../../../../FormInput";


export default function StatusSelect({influencerList, influencerState, shouldReload}: {
    influencerList: InfluencerList,
    shouldReload: () => any,
    influencerState: InfluencerListInfluencerState
}) {
    const {putInfluencerList, getInfluencerAvailableStatus} = useFirebase();
    const {t} = useTranslation();


    useEffect(() => {
        if (!influencerList) {
            return;
        }

    }, [influencerList, influencerState.userId]);

    const updateStatus = async (newStatus: InfluencerListInfluencerStatus) => {
        if (!influencerState || newStatus === influencerState.status) return;

        let influencerIndex = -1;
        const categoryIndex = influencerList.influencersCategories.findIndex(c => {
            const i = c.influencers.findIndex(influencer => influencer.userId === influencerState.userId)
            if (i > -1) {
                influencerIndex = i;
            }
            return i > -1;
        })

        if (influencerIndex === -1 || categoryIndex === -1) return;

        try {
            const newCategories = [...influencerList.influencersCategories];
            newCategories[categoryIndex].influencers[influencerIndex].status = newStatus;

            const res = await putInfluencerList(influencerList.id, {influencersCategories: newCategories});
            if (!res) throw new Error("");

            toast.success(t("message-success-status-updated"));

            shouldReload();
        } catch (error) {
            toast.error(t("error-edit-status"));
        }
    }


    return <FormInput
        defaultValue={influencerState['fame']}
        clearable={false}
        type="select"
        onChange={(e) => updateStatus(e.value as InfluencerListInfluencerStatus)}
        name="status"
        value={influencerState['status']}
    >
        {getInfluencerAvailableStatus().map((status: any) => (
            <option key={Math.random()} value={status.id}>
                {status.text}
            </option>
        ))}
    </FormInput>
}
