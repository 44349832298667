import React, {useCallback, useEffect, useState} from 'react';
import {clear} from "@testing-library/user-event/dist/clear";

type Props = {
    type?:
        | 'color'
        | 'date'
        | 'email'
        | 'month'
        | 'number'
        | 'password'
        | 'range'
        | 'tel'
        | 'text'
        | 'time'
        | 'url'
        | 'week'
        | 'select'
        | 'textarea'
        | 'checkbox'
        | 'file';
    children?: React.ReactNode;
    onChange: (event: {
        type: string,
        value: string | number,
        checked: boolean,
        name: string,
        target: any,
        min: any,
        max: any
    }) => any;
    value?: string | number;
    checked?: boolean;
    label?: string;
    hideLabel?: boolean;
    className?: string;
    placeholder?: string;
    name: string;
    min?: number;
    minLength?: number
    max?: number;
    maxLength?: number
    rows?: number
    required?: boolean
    disabled?: boolean
    clearable?: boolean
    defaultValue?: string | number | boolean
};


export default function FormInput({
                                      type = 'text',
                                      className = '',
                                      label,
                                      hideLabel = false,
                                      value = "",
                                      checked = false,
                                      onChange,
                                      placeholder = '',
                                      children,
                                      name,
                                      rows,
                                      min,
                                      minLength,
                                      max,
                                      maxLength,
                                      required = false,
                                      disabled,
                                      clearable = true,
                                      defaultValue = undefined
                                  }: Props) {


    const [v, setV] = useState<string | number>(value);
    const [c, setC] = useState<boolean>(checked);
    const [dValue, setDValue] = useState<string | number | boolean>(defaultValue !== undefined ? defaultValue : (type === 'checkbox' ? checked : value));


    useEffect(() => {
        if (v !== value) {
            setV(value)
        }
    }, [value]);

    useEffect(() => {
        if (c !== checked) {
            setC(checked)
        }
    }, [checked]);

    const clearInputHandler = () => {
        if (type === 'checkbox') {
            setC(dValue as boolean);
        } else {
            setV(dValue as any);
        }

        onChange({
            type: type,
            value: type === 'checkbox' ? '' : dValue as string | number,
            checked: type === 'checkbox' ? dValue as boolean : false,
            name: name,
            target: {
                type: type,
                value: type === 'checkbox' ? '' : dValue as string | number,
                checked: type === 'checkbox' ? dValue as boolean : false,
                name: name,
                min: min,
                max: max,
            },
            min: min,
            max: max,
        });

    }

    const inputChangeHandler = (e: any) => {

        if (type === 'checkbox') {
            setC(e.target.checked);
        } else {
            setV(e.target.value);
        }

        onChange({
            type: e.target.type,
            value: e.target.type !== "checkbox" ? e.target.value : "",
            checked: e.target.type === "checkbox" ? e.target.checked : false,
            name: e.target.name,
            target: e.target,
            min: min,
            max: max,
        });
    }

    const shouldShowClearButton = useCallback(() => {
        if (!clearable) return false;
        return !(!v || v === dValue);

    }, [clearable, v, dValue]);

    const shouldBgYellow = useCallback(() => {
        return clearable && v !== dValue;
    }, [clearable, v, dValue])


    return (
        <div className={`form-control w-full ${className}`}>

            {
                type !== 'checkbox' && (
                    <div className="flex items-center justify-between">
                        {label && !hideLabel && (
                            <label className="label">
                                <span className="label-text">{label}</span>
                            </label>
                        )}

                        {shouldShowClearButton() && (
                            <div className="pr-1 cursor-pointer" style={{color: "#ffbf00"}}
                                 onClick={clearInputHandler}>Clear</div>
                        )}
                    </div>

                )
            }

            {(type !== 'select' && type !== 'textarea' && type !== 'checkbox') && (
                <input
                    type={type}
                    name={name}
                    className={`input input-bordered w-full ${shouldBgYellow() ? "bg-yellow" : ""}`}
                    value={v}
                    placeholder={placeholder}
                    onChange={inputChangeHandler}
                    min={min}
                    max={max}
                    minLength={minLength}
                    maxLength={maxLength}
                    required={required}
                    disabled={disabled}
                />
            )}

            {type === 'select' && (
                <select
                    className={`select select-bordered w-full ${shouldBgYellow() ? "bg-yellow" : ""}`}
                    style={{minWidth: "200px"}}
                    name={name}
                    value={v}
                    onChange={inputChangeHandler}
                >
                    {children}
                </select>
            )}

            {type === 'textarea' && (
                <textarea
                    className={`textarea textarea-bordered ${shouldBgYellow() ? "bg-yellow" : ""}`}
                    name={name}
                    value={v}
                    onChange={inputChangeHandler}
                    placeholder={placeholder}
                    rows={rows}
                >
                </textarea>
            )}

            {type === 'checkbox' && (
                <div className="form-control">
                    <label className="label cursor-pointer justify-start items-center">
                        {label && <span className="label-text text-sm mr-4">{label}</span>}
                        <input 
                            type="checkbox"
                            name={name}
                            onChange={inputChangeHandler}
                            checked={c}
                            className="checkbox checkbox-primary"
                            
                        />
                    </label>
                </div>
            )}

        </div>
    );
}
