import React, {useState} from 'react';
import FormInput from "../FormInput";
import {defaultUserAvatar} from "../../types/user";
import {useFirebase} from "../../contexts/FirebaseContext";
import {UserFormData} from "../../types/formData";
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

type Props = {
    name: string;
    onSubmit?: (...args: any[]) => any;
    onCancel?: (...args: any[]) => any
}
export default function CreateUserModal({name, onSubmit}: Props) {
    const [formData, setFormData] = useState<UserFormData>({
        lastname: "",
        firstname: "",
        password: "",
        confirmPassword: "",
        email: "",
        role: "employee",
        avatar: defaultUserAvatar
    })

    const {postUser} = useFirebase();
    const {t} = useTranslation();

    const inputChangeHandler = (event: any) => {
        const {name, value} = event.target;
        setFormData(old => ({...old, [name]: value}))
    }

    const submitHandler = (event: any) => {

        for (const [key, value] of Object.entries(formData)) {
            if (!value) {
                toast.error(t("users.please-add-all-fields"));
                event.preventDefault();
                return;
            }
        }

        if(formData?.password !== formData?.confirmPassword) {
            toast.error(t("users.identical-passwords"));
            event.preventDefault();
            return;
        }

        postUser(formData).then(() => {
            onSubmit && onSubmit()
        })


    }

    return (
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                    <h3 className="font-bold text-lg mb-8">{t("users.add-new")}</h3>


                    <div className='grid grid-cols-2 gap-8 my-4'>
                        <FormInput
                            type="text"
                            onChange={inputChangeHandler}
                            name="firstname"
                            value={formData['firstname']}
                            placeholder={t("users.firstname")}
                            label={t("users.firstname")}
                        />
                        <FormInput
                            type="text"
                            onChange={inputChangeHandler}
                            name="lastname"
                            value={formData['lastname']}
                            placeholder={t("users.lastname")}
                            label={t("users.lastname")}
                        />
                    </div>

                    <div className='grid grid-cols-1 gap-8 my-4'>
                        <FormInput
                            type="email"
                            onChange={inputChangeHandler}
                            name="email"
                            value={formData['email']}
                            placeholder={t("users.email")}
                            label={t("users.email")}
                        />
                    </div>

                    <div className='grid grid-cols-2 gap-8 my-4'>
                        <FormInput
                            type="password"
                            onChange={inputChangeHandler}
                            name="password"
                            value={formData['password']}
                            placeholder={t("users.password")}
                            label={t("users.password")}
                        />
                        <FormInput
                            type="password"
                            onChange={inputChangeHandler}
                            name="confirmPassword"
                            value={formData['confirmPassword']}
                            placeholder={t("users.confirm-password")}
                            label={t("users.confirm-password")}
                        />
                    </div>

                    <div className='grid grid-cols-1 gap-8 my-4'>
                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="role"
                            value={formData['role']}
                            placeholder={t("users.role")}
                            label={t("users.role")}
                        >
                            <option value='' disabled>Sélectionnez un role</option>
                            <option value='admin'>{t("users.admin")}</option>
                            <option value='employee'>{t("users.employee")}</option>
                            <option value='customer'>{t("users.client")}</option>
                        </FormInput>
                    </div>

                    <div className='flex justify-end'>
                        <button  className='btn btn-primary' onClick={submitHandler}>{t("users.create")}</button>
                    </div>


                </form>
            </dialog>
        </div>
    )
}