import React, { useState } from 'react';
import logo from '../../../assets/images/Clark_LogoNoir.png';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';

import './EmployeeLayout.scss';
import { ROUTES } from '../../../constants/routes';
import { useAuth } from '../../../contexts/AuthContext';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import {toast} from "react-toastify";
import i18next from 'i18next';
import {useTranslation} from 'react-i18next';
import moment from 'moment';

library.add(faDoorOpen)

export default function EmployeeLayout() {
  return (
    <div>
      <NavBar />
      <div className="flex">
        <div className="container">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

function NavBar() {

  const { currentUserData, logout } = useAuth();
  const [language, setLanguage] = useState(localStorage.getItem("i18nextLng") || "fr")
  const {t} = useTranslation();

  const navigate = useNavigate();

  const onLogout = () => {
    logout().then(() => {
      navigate(ROUTES.CHOOSE);
    })
  }

  const changerLanguage = (lang: string) => {
    setLanguage(lang);
    localStorage.setItem("i18nextLng", lang);
    toast.success(t('language.update_success'));
    i18next.changeLanguage(lang);
    moment().locale(lang);
  }

  return (
    <div className="navbar bg-base-100 mb-6">
      <div className="navbar-start">
        <div className="pb-4 flex justify-center">
          <button className="btn glass" onClick={() => navigate(ROUTES.EMPLOYEE.HOME)} >
            <img src={logo} alt="Clark Logo" className="app-logo" /></button> 
          </div>
      </div>
      <div className="navbar-center">
        <ul className="menu menu-sm flex-row items-center flex  bg-base-100">
          <li className="mr-2">
            <NavLink className="text-lg" to={ROUTES.EMPLOYEE.HOME}>
              ✌️ {t("home-title")}
            </NavLink>
          </li>
          <li className="mr-2">
            <NavLink className="text-lg" to={ROUTES.EMPLOYEE.INFLUENCER_LISTS}>
              🔎 {t("influencers-search")}
            </NavLink>
          </li>
          <li className="mr-2">
            <NavLink className="text-lg" to={ROUTES.EMPLOYEE.CAMPAIGNS}>
              🤳 {t("campaign-management")}
            </NavLink>
          </li>
        </ul>
      </div>

      {/* End */}
      <div className="navbar-end gap-2">
        {/* Language */}
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
              {language === "fr" ? "🇫🇷" : "🇺🇸"}
          </label>
          <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
            <li><a onClick={() => changerLanguage("fr")}>🇫🇷 {t("french")}</a></li>
            <li><a onClick={() => changerLanguage("en")}>🇺🇸 {t("english")}</a></li>
          </ul>
        </div>
        {/* User connected name */}
        <p className="text-md">
          {(currentUserData?.firstname) + ' ' + (currentUserData?.lastname)}
        </p>
        {/* Profile picture */}
        <div className="dropdown dropdown-end">
          <label tabIndex={0} className="btn btn-ghost btn-circle avatar">
            <div className="w-10 rounded-full">
              <img src={currentUserData?.avatar || "https://st3.depositphotos.com/6672868/13701/v/450/depositphotos_137014128-stock-illustration-user-profile-icon.jpg"} alt='avatar'/>
            </div>
          </label>
          <ul tabIndex={0} className="mt-3 z-[1] p-2 shadow menu menu-sm dropdown-content bg-base-100 rounded-box w-52">
            <li>
              <a onClick={() => navigate(ROUTES.EMPLOYEE.PROFILE)} className="justify-between">
                {t("my-profile")}
              </a>
            </li>
            <li>
              <a
                onClick={()=>window.open(ROUTES.CUSTOMER.CERTIFIEDINFLUENCERS,'_blank', 'rel=noopener noreferrer')}
                className="justify-between">
                {t("ethical-influencers-page")}
              </a>
            </li>
            {currentUserData?.role === 'admin' && <li><a onClick={() => navigate(ROUTES.EMPLOYEE.USERS_SETTINGS)}>{t("settings")}</a></li>}
            <li><a onClick={onLogout}>{t("logout")}</a></li>
          </ul>
        </div>
      </div>
    </div>
  );
}
