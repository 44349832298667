import React, { useEffect, useState} from 'react';
import {SearchResults} from "../../../types/api";
import {DataTable} from "grommet";
import InfluencerSearchDetailsModal from "../../modal/InfluencerSearchDetailsModal";
import {Networks} from "../../../types/dbModels";
import {formatNumber} from "../../../helpers/functions";
import imgVerified from "../../../assets/images/verified.png";
import SelectAvailableList from "../../select/SelectAvailableList";
import {useTranslation} from "react-i18next";


type InfluencerSearchResultsProps = {
    platform: Networks,
    searchResults: SearchResults
    onReset: () => any
    currentPage: number
    nextPage: () => any
    prevPage: () => any
}

export default function InfluencerSearchResults({platform, searchResults, onReset, currentPage, nextPage, prevPage} : InfluencerSearchResultsProps) {
    const modalName = 'influencer_search_details_modal';

    const [results, setResults] = useState<SearchResults['lookalikes']>([]);
    const [selectedInfluencer, setSelectedInfluencer] = useState<string>();
    const {t} = useTranslation();

    useEffect(() => {
        setResults(searchResults.lookalikes)
    }, [searchResults]);

    useEffect(() => {
        if (selectedInfluencer) {
            openModal();
        }
    }, [selectedInfluencer]);

    const openModal = () => {
        (window as any)[modalName].showModal()
    }

    const updateSelectedInfluencer = (id: string) => {
        if (selectedInfluencer === id) {
            openModal();
        }
        setSelectedInfluencer(id);
    }

    return (
        <>

        <div className="card bg-base-200 mb-8">
            <div className="card-body">

                <div className='flex justify-between'>
                    <div>
                        <h1 className="text-3xl font-bold">📄 {t("results")}</h1>
                        <h3 className="">{t("results-text")} (page n°{currentPage+1})</h3>
                        <h3 className="">{t("results-nb-found")} : {formatNumber(searchResults.total)}</h3>
                    </div>
                </div>



                <DataTable
                    sortable={true}
                    paginate={true}
                    step={20}
                    background={["white", "light-2"]}
                    primaryKey="id"
                    columns={[
                        {
                            property: 'profile.fullname',
                            header: t("table-name"),
                            primary: false,
                            search: true,
                            render: data => {
                                return (
                                    <div className="flex flex-row items-center hand" onClick={() => updateSelectedInfluencer(data.userId)}>
                                        <div className="avatar mr-2">
                                            <div className="w-12 rounded-full">
                                                <img className='h-44 rounded-xl' src={data?.profile?.picture} alt="Influencer Image"/>
                                            </div>
                                        </div>
                                        <div className="flex flex-col text-start">
                                            <p className='font-bold'>
                                                {data.profile.fullname}
                                            </p>
                                            <p>{("@" + (data.profile.username || data.profile.handle)) || ""}</p>
                                        </div>
                                        {data.profile.isVerified && (
                                            <img className='ml-2' src={imgVerified} alt="profile-verified-badge" width={16} height={16} />
                                        )}
                                    </div>
                                )
                            }
                        },
                        {
                            property: 'profile.followers',
                            header: t("table-followers"),
                            primary: false,
                            search: true,
                            render: data => {
                                return <>{formatNumber(data.profile.followers)}</>
                            }
                        },
                        {
                            property: 'profile.engagementRate',
                            header: t("table-engagement"),
                            primary: false,
                            search: true,
                            render: data => {
                                return <>{`${(+data.profile.engagementRate * 100).toFixed(2)}%`}</>;
                            }
                        },
                        {
                            property: "",
                            header: "",
                            render: (data) => {
                                return <SelectAvailableList influencerId={data.userId} platform={platform} />
                            }
                        }
                    ]}
                    data={results}
                />

                <div className='flex justify-between items-center'>
                    {/* Button previous page */}
                    <div>
                        {currentPage > 0 && (
                            <button className="btn btn-ghost" onClick={prevPage}>👈 {t("previous-page")}</button>
                        )}
                    </div>

                    <div className="flex gap-2">
                        <div>Page {currentPage + 1 }</div>
                        <div><strong>/</strong></div>
                        <div>{Math.ceil(searchResults.total / 15)}</div>
                    </div>

                    {/* Button next page */}
                    <div>
                        {results && results.length > 14 && (
                            <button className="btn btn-ghost" onClick={nextPage}>{t("next-page")} 👉</button>
                        )}
                    </div>
                </div>

                {/* MODAL: Detailed view of an influencer */}
                <InfluencerSearchDetailsModal
                    name={modalName}
                    influencerId={selectedInfluencer as string}
                    platform={platform}
                    getFromFirebase={false}
                    manageProfilAsClark={true}
                    allowReload={false}
                    showInModal={true}
                    showInCustomerViewMode={false}
                    influencerList={undefined}
                />


            </div>
        </div>

    </>
    )
}
