import {
    InfluencerList,
    InfluencerListInfluencerState,
    InfluencerListInfluencerStatus,
    InfluencersCategory
} from "../../../../../../types/dbModels";
import {useFirebase} from "../../../../../../contexts/FirebaseContext";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import FormInput from "../../../../../FormInput";

export default function CategorySelect({influencerList, influencerState, shouldReload}: {
    influencerList: InfluencerList,
    shouldReload: () => any,
    influencerState: InfluencerListInfluencerState
}) {
    const {putInfluencerList} = useFirebase();
    const [actualCategory, setActualCategory] = useState<InfluencersCategory | undefined>(influencerList.influencersCategories.find(c => c.influencers.findIndex(i => i.userId === influencerState.userId) > -1))
    const {t} = useTranslation();


    useEffect(() => {
        if (!influencerList || !influencerState) {
            return;
        }

        setActualCategory(
            influencerList.influencersCategories.find(c => c.influencers.findIndex(i => i.userId === influencerState.userId) > -1)
        );

    }, [influencerList, influencerState]);

    const updateCategory = async (categoryId: string) => {
        const newCategoryIndex = influencerList.influencersCategories.findIndex(c => c.id === categoryId);
        const actualCategoryIndex = influencerList.influencersCategories.findIndex(c => c.influencers.findIndex(i => i.userId === influencerState.userId) > -1);
        if (newCategoryIndex < 0 || actualCategoryIndex < 0) return;

        const newInfluencerList = {...influencerList};
        newInfluencerList.influencersCategories[actualCategoryIndex].influencers = newInfluencerList.influencersCategories[actualCategoryIndex].influencers.filter(i => i.userId !== influencerState.userId);
        newInfluencerList.influencersCategories[newCategoryIndex].influencers.push(influencerState);

        try {
            const res = await putInfluencerList(influencerList.id, {influencersCategories: newInfluencerList.influencersCategories});
            if (!res) throw new Error("");

            toast.success(t("message-status-updated"));

            shouldReload();
        } catch (error) {
            toast.error(t("error-edit-category"));
        }


    }

    if (!actualCategory) return;

    return <FormInput
        defaultValue={actualCategory.id}
        clearable={false}
        type="select"
        onChange={(e) => updateCategory(e.value as InfluencerListInfluencerStatus)}
        name="category"
        value={actualCategory.id}
    >
        {influencerList.influencersCategories.map(category => (
            <option key={Math.random()} value={category.id}>{category.name}</option>
        ))}
    </FormInput>
}
