import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import logo from '../../../assets/images/Clark_LogoNoir.png';
import {useCustomer} from '../../../contexts/CustomerContext';
import {CUSTOMER_AUTH_KEY} from "../../../constants/localstorage";
import {useNavigate, useParams} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";
import {toast} from "react-toastify";

const EnterPasswordPage = () => {
    const {t} = useTranslation();
    const {influencerList} = useCustomer();
    const {slug} = useParams();

    const [passwordToReach, setPasswordToReach] = useState<string>();
    const [passwordInput, setPasswordInput] = useState<string>();
    const [campaignID, setCampaignID] = useState<string>();
    const [checkboxConditions, setCheckboxConditions] = useState<boolean>(false);

    const navigate = useNavigate();

    const [isClientDisabled, setIsClientDisabled] = useState(false);

    useEffect(() => {
        if (!influencerList) {
            return;
        }

        if(influencerList.isArchived) return;

        if (slug && slug !== ":slug") {
            setCampaignID(slug);
        }


        let campaignToConnect = influencerList.id + '.' + influencerList.password;
        let campaignCredentials = localStorage.getItem(CUSTOMER_AUTH_KEY);
        if (!campaignCredentials) {
            campaignCredentials = campaignID + '.' + passwordInput;
        }
        if (campaignToConnect === campaignCredentials) {
            if (campaignID) navigate(ROUTES.CUSTOMER.OVERALL.replace(':slug', campaignID))
        }
        // else {
        //     localStorage.removeItem(CUSTOMER_AUTH_KEY);
        // }
        setPasswordToReach(influencerList.password)
    }, [influencerList, navigate, slug]);

    useEffect(() => {
        if (!influencerList) {
            return;
        }

        if(influencerList.isArchived) {
            setIsClientDisabled(true);
        } else if (!influencerList.isArchived && isClientDisabled) {
            setIsClientDisabled(false);
        }

    }, [influencerList, isClientDisabled]);

    const submitHandler = () => {
        if (!influencerList || !slug) return;

        if (passwordInput === passwordToReach) {
            localStorage.setItem(CUSTOMER_AUTH_KEY, slug + "." + passwordToReach);
            navigate(ROUTES.CUSTOMER.OVERALL.replace(":slug", slug));
            setTimeout(() => {
                window.location.reload();
            });
        } else {
            toast.error("Mot de passe incorrect");
        }
    }

    return (
        <>
            <div className="h-screen bg-white flex flex-col justify-center items-center">
                <div className="md:container md:mx-auto flex justify-center ">
                    <div className="card bg-base-100">
                        <div className="card-body flex flex-col justify-center items-center">
                            <img src={logo} className="w-52 my-8" alt={'Logo'} />
                            {influencerList && !influencerList.isArchived && (
                                <form onSubmit={submitHandler} className=" flex flex-col justify-center items-center">
                                    <input
                                        value={campaignID}
                                        type="text"
                                        placeholder={"ID de campagne"}
                                        className="input input-lg input-bordered w-full max-w-xs mb-5"
                                        onChange={(e) => {
                                            setCampaignID(e.target.value)
                                        }}
                                    />
                                    <input
                                        type="password"
                                        placeholder={t('labels.password')}
                                        className="input input-lg input-bordered w-full max-w-xs mb-5"
                                        onChange={(e) => {
                                            setPasswordInput(e.target.value)
                                        }}
                                    />
                                    <label className="label cursor-pointer">
                                        <span className="label-text mr-4">J'accepte les termes et conditions <a
                                            href="/customer/termsofuse-fr" className='link'
                                            target='_blank'>FR 🇫🇷</a> | <a href="/customer/termsofuse-en"
                                                                           className='link'
                                                                           target='_blank'>EN 🇺🇸</a></span>
                                        <input type="checkbox" className="checkbox" checked={checkboxConditions}
                                               onChange={(e) => setCheckboxConditions(e.target.checked)}/>
                                    </label>
                                    <button
                                        type='button'
                                        className="btn btn-primary"
                                        onClick={submitHandler}
                                        disabled={!checkboxConditions}
                                    >
                                        Se connecter
                                    </button>
                                    <button
                                        type='button'
                                        className="btn btn-sm btn-ghost mt-2"
                                        onClick={() => navigate(ROUTES.CHOOSE)}
                                    >
                                        ← Retour
                                    </button>
                                </form>
                            )}

                            {
                                influencerList && influencerList.isArchived && (
                                    <h1>{t('disabled-customer-area')}</h1>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EnterPasswordPage;
