import React, { FC, useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { useApi } from "../../contexts/ApiContext";
import logoClark from "../../assets/images/Clark_LogoNoir.png"
import {useTranslation} from "react-i18next";

const Footer: FC = () => {

    const { currentUser } = useAuth();
    const { getQuota } = useApi();
    const {t} = useTranslation();

    // State
    const [quota, setQuota] = useState(null);
    const [quotaPercent, setQuotaPercent] = useState(0);

    useEffect(() => {
      if (!quota) {
        loadQuota();
      }
    }, []);

    const loadQuota = async () => {
      let oQuota = await getQuota();
      if (oQuota?.billing?.credits) {
        var percent = Math.round((oQuota.billing.credits/3000)*100);
        setQuota(oQuota.billing.credits);        
        setQuotaPercent(percent);
      }
    }
    
    return currentUser ? (
        <>
      <footer className="footer px-10 py-4 border-t bg-base-100 text-base-content border-base-200 content-center items-center">
        <div className="items-center grid-flow-col">
          <img src={logoClark} width={32} height={32} alt="Logo Clark" />
          <p>
            Clark Machine • {new Date().getFullYear()}
          </p>
        </div>
        <div className="md:place-self-center md:justify-self-end">
          <div className="flex flex-col gap-4">
            <span>{t("remaining-credits")}</span>
            {quota ? (
                <div className='flex flex-row content-center items-center'>
                  <span className='mr-4'>{Math.round(quota)}</span>
                  <progress className={quotaPercent > 25 ? 'progress w-56 progress-success' : 'progress w-56 progress-warning'} value={quota || 0} max="3000"></progress>
                </div>
              ) : (
                <progress className="progress w-56"></progress>
              )
            }
          </div>
        </div>
      </footer>
    </>
    ) : (<></>)
};

export default Footer;