import React, {useEffect, useState, useRef} from 'react';
import {useAuth} from '../../contexts/AuthContext';
import {useNavigate} from 'react-router-dom';
import logo from '../../assets/images/Clark_LogoNoir.png';
import {ROUTES} from '../../constants/routes';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import './Login.scss';

export default function Login() {
    const emailRef = useRef<any>(null);
    const passwordRef = useRef<any>(null);
    const {login, currentUser} = useAuth();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        if (currentUser) navigate(ROUTES.EMPLOYEE.HOME);
    }, [currentUser, navigate]);

    async function handleSubmit(e: any) {
        e.preventDefault();
        setLoading(true);
        login(emailRef.current?.value, passwordRef.current?.value).then(resLogin => {
            setLoading(false);
            navigate(ROUTES.EMPLOYEE.HOME);
        }).catch (err => {
            setLoading(false);
            toast.error(t('login.login_error'));
        });
    }

    return (
        <div
            className="bg-white relative justify-center h-screen w-screen overflow-hidden"
            style={{display: 'grid', gridTemplateColumns: '1fr 1fr'}}>
            <div className="p-6 m-auto bg-white rounded-md lg:max-w-xl">
                <div className="flex justify-center mb-8">
                    <img src={logo} alt="Logo" className='logo'/>
                </div>

                <form
                    className="space-y-4 flex flex-col items-center justify-center"
                    onSubmit={handleSubmit}>
                    <input
                        type="email"
                        autoComplete="email"
                        ref={emailRef}
                        required
                        //value="admin@clarkinfluence.com"
                        className="w-full input input-bordered"
                        placeholder={t('labels.email')}
                    />

                    <input
                        type="password"
                        ref={passwordRef}
                        autoComplete="current-password"
                        //value="admin123"
                        required
                        className="w-full input input-bordered"
                        placeholder={t('labels.password')}
                    />
                    <div>
            <span>
            </span>
                    </div>

                    <div className='hand' onClick={() => navigate(ROUTES.EMPLOYEE.FORGOT_PASSWORD)}>
                        {t("forgot-password")}
                    </div>
                    <button className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-md">
                        {t('labels.login')}
                    </button>
                    <button 
                        type='button'
                        className="btn btn-sm btn-ghost"
                        onClick={() => navigate(ROUTES.CHOOSE)}
                    >
                        ← {t("back")}
                    </button>
                </form>
            </div>
            <div style={{backgroundColor: 'lightgrey'}} className='imgLoginSide'>
            </div>
        </div>
    );
}
