import React, { useRef, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { Link, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../constants/routes';
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";

export default function ForgotPassword() {
  const emailRef = useRef<any>(null);
  const { resetPassword } = useAuth();
  const { t } = useTranslation();

  const navigate = useNavigate();

  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);

  async function handleSubmit(e: any) {
    e.preventDefault();

    try {
      setLoading(true);
      await resetPassword(emailRef.current?.value);
      setEmail('');
      toast.success("Vérifiez vos mails pour réinitialiser votre mot de passe");
    } catch {
      toast.error("Une erreur s'est produite. Veuillez vérifier l'adresse email et réessayer.");
    }

    setLoading(false);
  }
  return (
    <div className="relative bg-white flex flex-col justify-center h-screen overflow-hidden">
      <div className="w-full p-6 m-auto bg-white rounded-md lg:max-w-xl">
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          {t('forgot-password')}
        </h2>
        <form className="space-y-4" onSubmit={handleSubmit}>
          <div className='mt-8'>
            <input
              className="w-full input input-bordered"
              id="email-address"
              name="email"
              type="email"
              autoComplete="email"
              ref={emailRef}
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t('labels.email')}
            />
          </div>

          <button 
              type='button'
              className="btn btn-md btn-ghost mt-2 mr-4"
              onClick={() => navigate(ROUTES.EMPLOYEE.LOGIN)}
          >
              ← 
          </button>
          <button
            type="submit"
            disabled={loading}
            className="btn btn-primary btn-xs sm:btn-sm md:btn-md lg:btn-md"
          >
            {t('reset')}
          </button>
        </form>
      </div>
    </div>
  );
}
