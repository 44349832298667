import React from 'react' ;

type Props = {
    components: any[],
    children: any,
};


export default function AppContextProviders(props : Props) {
  const { components = [], children } = props

  return (
    <>
      {components.reduceRight((acc, Comp) => {
        return <Comp>{acc}</Comp>
      }, children)}
    </>
  )
}
