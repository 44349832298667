import React from 'react';
import {useTranslation} from 'react-i18next';
import logo from '../../../assets/images/Clark_LogoNoir.png';

const TermsOfUsePageFR = () => {
    const {t} = useTranslation();


    return (
        <div className="p-8 bg-white flex flex-col justify-center items-center">
            <img src={logo} className="w-52 my-8" alt={'Logo'} />
            <h1 className='text-3xl'>Conditions Générales d'Utilisation de la Plateforme</h1><br/><br/>
            <div>
                
                <p className='mb-6'>Bienvenue sur la plateforme technologique Clark Machine (ci-après dénommée "la Plateforme"). Avant d'utiliser la Plateforme, veuillez lire attentivement les présentes Conditions Générales d'Utilisation ("CGU"). En accédant ou en utilisant la Plateforme, vous acceptez d'être lié par les présentes CGU.</p>

                <h2 className='text-xl'>1. Utilisation de la Plateforme</h2>
                <p className='mb-6'>1.1. Vous acceptez d'utiliser la Plateforme uniquement à des fins légales et conformément aux présentes CGU, ainsi qu'à toutes les lois, règles et réglementations applicables.</p>
                <p className='mb-6'>1.2. Vous êtes responsable de maintenir la confidentialité de votre compte et de votre mot de passe, et vous acceptez de ne pas partager vos informations de connexion avec des tiers.</p>
                <p className='mb-6'>1.3. Vous acceptez de ne pas utiliser la Plateforme de manière à perturber, endommager ou compromettre son intégrité ou sa fonctionnalité.</p>

                <h2 className='text-xl'>2. Propriété Intellectuelle</h2>
                <p className='mb-6'>2.1. Tous les droits de propriété intellectuelle relatifs à la Plateforme et à son contenu (y compris, mais sans s'y limiter, les logiciels, le design, les textes, les graphiques et les images) restent la propriété de Clark Influence INC. ou de ses concédants de licence.</p>
                <p className='mb-6'>2.2. Vous acceptez de ne pas reproduire, distribuer, modifier, créer des œuvres dérivées, afficher publiquement, exécuter publiquement, republier, télécharger, stocker ou transmettre tout contenu trouvé sur la Plateforme sans l'autorisation préalable écrite de Clark Influence INC.</p>

                <h2 className='text-xl'>3. Confidentialité</h2>
                <p className='mb-6'>3.1. Clark Influence INC. respecte la confidentialité de ses utilisateurs.</p>
                <p className='mb-6'>3.2 Nous ne collectons aucune information personnelle identifiable lorsque vous utilisez la Plateforme. Nous ne demandons pas votre nom, votre adresse e-mail, votre numéro de téléphone ou toute autre information permettant de vous identifier personnellement.</p>

                <h2 className='text-xl'>4. Limitation de Responsabilité</h2>
                <p className='mb-6'>4.1. Clark Influence INC. ne peut être tenue responsable des dommages directs, indirects, accessoires, spéciaux ou consécutifs découlant de l'utilisation ou de l'incapacité à utiliser la Plateforme.</p>

                <h2 className='text-xl'>5. Modification des CGU</h2>
                <p className='mb-6'>5.1. Clark Influence INC. se réserve le droit de modifier les présentes CGU à tout moment. Les modifications seront effectives dès leur publication sur la Plateforme. Il est de votre responsabilité de consulter régulièrement les CGU pour vous tenir informé des changements.</p>
                <p className='mb-6'>En utilisant la Plateforme après la publication de modifications des CGU, vous acceptez d'être lié par ces modifications.</p>


                <h2 className='text-xl'>6. Gestion des services</h2>
                <p className='mb-6'>6.1 Vous comprenez et acceptez que Clark Influence puisse modifier, résilier, suspendre ou ajuster de toute autre manière tous les aspects des Services à tout moment et sans préavis préalable de votre part. Vous reconnaissez et acceptez que Clark Influence puisse désactiver l'accès aux Services en cas de non-paiement ou d'autres manquements substantiels aux Conditions, ce qui pourrait vous empêcher d'accéder à vos fichiers ou à d'autres contenus contenus dans la plateforme ou les Services de Clark Influence. Vous reconnaissez et acceptez que vous êtes autorisé à utiliser un seul compte par personne. En cas de personne morale, vous êtes autorisé à utiliser un seul compte pour plusieurs employés.</p>

                <h2 className='text-xl'>7. Droit Applicable et Juridiction</h2>
                <p className='mb-6'>7.1. Les présentes CGU sont régies par et interprétées conformément aux lois en vigueur au Québec. Tout litige découlant des présentes CGU sera soumis à la juridiction exclusive des tribunaux compétents de Montréal.</p>
                <p className='mb-6'>En utilisant la Plateforme, vous consentez à la compétence personnelle de ces tribunaux pour le règlement de tout litige.</p>
                <p className='mb-6'>Si vous avez des questions concernant les présentes CGU, veuillez nous contacter à info@clarkinfluence.com</p>

            </div>
        </div>
    );
};

export default TermsOfUsePageFR;