import React, {useCallback, useEffect, useState} from 'react';
import "./CampaignOverall.scss";
import {NavLink, useParams} from "react-router-dom";
import {ROUTES} from "../../../../constants/routes";
import {useCustomer} from "../../../../contexts/CustomerContext";
import {useFirebase} from "../../../../contexts/FirebaseContext";
import {capitalFirstLetter, generateUid} from "../../../../helpers/functions";
import moment from "moment";
import ConfirmMessage from '../../../modal/ConfirmMessage';
import {toast} from "react-toastify";
import {useApi} from '../../../../contexts/ApiContext';
import {useTranslation} from "react-i18next";

// Images
import header1 from "../../../../assets/images/headers/header-1.jpg";
import header2 from "../../../../assets/images/headers/header-2.jpg";
import header3 from "../../../../assets/images/headers/header-3.jpg";
import header4 from "../../../../assets/images/headers/header-4.jpg";
import header5 from "../../../../assets/images/headers/header-5.jpg";
import header6 from "../../../../assets/images/headers/header-6.jpg";
import header7 from "../../../../assets/images/headers/header-7.jpg";
import header8 from "../../../../assets/images/headers/header-8.jpg";
import header9 from "../../../../assets/images/headers/header-9.jpg";
import header10 from "../../../../assets/images/headers/header-10.jpg";

import {faPencil, faTrash, faChevronUp, faChevronDown} from "@fortawesome/free-solid-svg-icons";
import {library} from "@fortawesome/fontawesome-svg-core";
import CampaignJudging from "../../../customer/CampaignJudging";
import {Meter} from "grommet";

library.add(faPencil, faTrash, faChevronUp, faChevronDown)

export default function CampaignOverall() {
    const {t} = useTranslation();
    const {slug} = useParams();
    const {
        influencerList,
        influencers,
        campaign,
    } = useCustomer();
    const {putCampaign, putInfluencerList, getUser} = useFirebase();
    const {postEmail} = useApi();

    // Local state
    const [employeeInCharge, setEmployeeInCharge] = useState<any>();
    const [randomHeader] = useState(Math.floor(Math.random() * 10) + 1);
    let headerImg;
    switch (randomHeader.toString()) {
        case "1":
            headerImg = header1;
            break;
        case "2":
            headerImg = header2;
            break;
        case "3":
            headerImg = header3;
            break;
        case "4":
            headerImg = header4;
            break;
        case "5":
            headerImg = header5;
            break;
        case "6":
            headerImg = header6;
            break;
        case "7":
            headerImg = header7;
            break;
        case "8":
            headerImg = header8;
            break;
        case "9":
            headerImg = header9;
            break;
        case "10":
            headerImg = header10;
            break;
        default:
            headerImg = header1;
    }

    const [selectedInfluencerId, setSelectedInfluencerId] = useState<string>("");
    const commentModalName = "comment_modal_" + generateUid();


    // UseEffects
    useEffect(() => {
        if (selectedInfluencerId) {
            (window as any)[commentModalName].showModal();
        }
    }, [selectedInfluencerId])

    useEffect(() => {
        if (!campaign) return;

        getUser(campaign.employee_incharge).then((user) => {
            if (user) {
                setEmployeeInCharge(user);
            } else {
                setEmployeeInCharge(undefined);
            }
        });
    }, [campaign]);


    // Methods
    const updateCampaignStatusExt = async (status: string) => {
        if (!campaign) return;

        putCampaign(campaign.id, {status_ext: status}).then(async (res) => {
            toast.success("Statut de la campagne mis à jour");

            // Send email to employee in charge
            if (status === "validated") {
                let oBodyEmail = {
                    from: "noreply@clarkinfluence.app",
                    from_name: "Clark Machine",
                    to: employeeInCharge.email,
                    title: "🔔 Campagne " + campaign.name + " validée par le client",
                    message: "Bonjour " + employeeInCharge.firstname + ",<br><br>La campagne <a href='" + window.location + "' target='_blank'>" + campaign.name + "</a> a été validée depuis l'espace client.<br><br>Clark Machine <i>(email automatique)</i>."
                };
                await postEmail(oBodyEmail);
                window.location.reload();
            }

        }).catch(() => {
            toast.error(t("error-status-update"));
        });

    }

    const getChosenInfluencerNumber = useCallback(() => {
        if (!influencerList || !campaign) return;

        return influencerList.influencersCategories.map(c => c.influencers).flat().filter(i => ['accepted', 'loved'].includes(i.state)).length;
    }, [campaign, influencerList])

    const getRemainingChosen = useCallback(() => {
        if (!influencerList || !campaign) return;

        return campaign.nb_expected - (influencerList.influencersCategories.map(c => c.influencers).flat().filter(i => ['accepted', 'loved'].includes(i.state)).length);
    }, [campaign, influencerList])

    return (
        <div>
            {influencerList && (
                <>
                    {/* Breadcrumb */}
                    <div className="max-w-max flex text-sm breadcrumbs">
                        <ul>
                            <li><NavLink
                                to={ROUTES.CUSTOMER.OVERALL.replace(':slug', slug as string)}>{t("home-title")}</NavLink>
                            </li>
                            <li>{t("menu-influencers-choice")}</li>
                        </ul>
                    </div>
                    <div className="card bg-base-200 my-8">
                        <div className=" flex items-center">
                            <div className="overlay" style={{width: "100%"}}>
                                <img
                                    style={{
                                        borderTopRightRadius: "2em",
                                        borderTopLeftRadius: "2em",
                                        objectFit: "contain",
                                        objectPosition: "center",
                                        overflow: 'hidden',
                                        width: "100%"
                                    }}
                                    src={influencerList.banner.length > 0 ? influencerList.banner : headerImg}
                                    alt="Header Clark Influence"
                                />
                            </div>
                        </div>

                        {/* TODO: continue translation here */}

                        <div className="card-body">

                            {/* Campaign title */}
                            <h1 className='text-3xl font-bold'>{campaign?.name || influencerList.name}</h1>

                            {/* Header info & stats */}
                            <div className='mb-4'>
                                <div>
                                    {campaign && (
                                        <>
                                            <h3>{t("campaign.office-in-charge")}: <b>{capitalFirstLetter(campaign?.office)}</b>
                                            </h3>
                                            <h3>{t("campaign.dates-1")}: <b>{moment(campaign?.startDate).format("L")}</b> {t("campaign.dates-2")}
                                                <b>{moment(campaign?.endDate).format("L")}</b>
                                            </h3>
                                            <div className='my-4'></div>
                                            <h3>{t("campaign.nb-of-profils-listed")}: <b>{influencers.length}</b></h3>
                                            <h3>{t("campaign.nb-of-profils-expected")}: <b>{campaign.nb_expected}</b>
                                            </h3>
                                            <h3>{t("campaign.nb-of-profils-selected")}: <b>{getChosenInfluencerNumber()}</b>
                                            </h3>
                                        </>
                                    )}
                                </div>
                            </div>

                            {influencers.length > 0 && campaign && campaign.status_ext === "in_review" && (
                                <div className="flex  mt-2">
                                    <button className={`btn btn-primary ${getRemainingChosen() ?? 0  > 0 ? 'btn-disabled' : ''}`}
                                            onClick={() => {
                                                if(getRemainingChosen() as number <= 0) {
                                                    (window as any)[campaign.status_ext === "in_review" ? "modal_confirm_status_ext_change_validated" : "modal_confirm_status_ext_change_in_review"].showModal()
                                                }
                                            }}>
                                        {t("campaign.i-m-done")}
                                    </button>
                                </div>
                            )}


                            {campaign && (
                                <div className='flex flex-col items-center my-8'>
                                    <Meter size='full' type="bar"
                                           value={(getChosenInfluencerNumber() ?? 0)  / campaign?.nb_expected * 100}
                                           background={'#fff'}/>

                                    {getRemainingChosen() as number > 0 ? (
                                        <span
                                            className='mt-4'>{t("campaign.sentence-remaining-selection-1")} {getRemainingChosen()} {t("campaign.sentence-remaining-selection-2")}</span>
                                    ) : (
                                        <span className='mt-4'>{t("campaign.selection-done")}</span>
                                    )}
                                </div>
                            )}



                            <CampaignJudging/>
                        </div>
                    </div>
                </>
            )}

            <ConfirmMessage name="modal_confirm_status_ext_change_validated" title={t("campaign.validating")}
                            message={t("campaign.are-you-sure-validation")}
                            onSubmit={() => updateCampaignStatusExt("validated")}/>
            <ConfirmMessage name="modal_confirm_status_ext_change_in_review" title={t("campaign.revision")}
                            message={t("campaign.are-you-sure-revision")}
                            onSubmit={() => updateCampaignStatusExt("in_review")}/>
        </div>
    )
}
