import React, {useEffect, useState} from "react";
import {Simulate} from "react-dom/test-utils";


type Value = {
    id: string,
    value: string
}

type Props = {
    values: Value[],
    label: string,
    onSelectedValueChanged: (newValue: string[]) => void,
    selectedValues: Value[]
}

export function MultiElementSelect({values , label, onSelectedValueChanged, selectedValues}: Props) {

    // const [values, setValues] = useState<Value[]>(v);
    const [availableValues, setAvailableValues] = useState<Value[]>([]);
    const [selectedValue, setSelectedValue] = useState<string>();
    // const [selectedValues, setSelectedValues] = useState<Value[]>([]);


    useEffect(() => {
        // setSelectedValues([]);
        setSelectedValue('-');
        setAvailableValues(values);
    }, [values]);

    const debounce = <F extends (...args: any[]) => any>(func: F, delay: number): ((...args: Parameters<F>) => void) => {
        let timerId: NodeJS.Timeout | null = null;

        return (...args: Parameters<F>) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => func(...args), delay);
        };
    };

    const inputChangeHandler = (event: any) => {
        const {value: valueId} = event.target
        const value = values.find(v => v.id === valueId);
        if(!value) return;

        if(availableValues.findIndex(v => v.id === valueId) > -1) {
            // selectedValues.push(value);
            onSelectedValueChanged([...selectedValues, value].map(v => v.id))
            setAvailableValues(old => old.filter(v => v.id !== valueId))
        }

        setSelectedValue('-')
    }

    return (
        <div>
            <div className='flex gap-2 flex-wrap my-1'>
                {selectedValues.map((value, i) => (
                    <div key={Math.random()} className="badge badge-primary cursor-pointer" onClick={() => {
                        setAvailableValues(old => [...old, value]);
                        onSelectedValueChanged(selectedValues.filter(v => v.id !== value.id).map(v => v.id));
                        // setSelectedValues(old => old.filter(v => v.id !== value.id));
                    }}>{value.value} X</div>
                ))}
            </div>

            <div className='grid grid-cols-1 items-center'>
                <div className={'form-control w-full'}>
                    <div className="flex items-center justify-between">
                        <label className="label">
                            <span className="label-text">{label}</span>
                        </label>
                    </div>
                    <select
                        className={`select select-bordered w-full `}
                        style={{minWidth: "200px"}}
                        name={'dd'}
                        onChange={inputChangeHandler}
                        value={selectedValue}
                    >
                        <option value={'-'}>-</option>
                        {availableValues.map((v, i) => (
                            <option value={v.id} key={i}>{v.value}</option>
                        ))}
                    </select>
                </div>
            </div>

        </div>
    )
}
