import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Networks} from "../types/dbModels";

type Props = {
    value: Networks | null;
    onChange: (newValue: Networks | null) => void;
    allowNull?: boolean
}

export default function SelectNetwork({value, onChange, allowNull = false}: Props) {
    const updateNetwork = (network: Networks) => {
        if(network === value && allowNull) {
            // onChange(null as unknown as Networks)
            onChange(null)
            return;
        }
        onChange(network);
    }

    return (
        <div className="btn-group">
            <button
                type='button'
                className={`btn ${value === 'instagram' ? 'btn-active' : ''}`}
                onClick={updateNetwork.bind(null, 'instagram')}
            >
                <FontAwesomeIcon size="xl" icon={['fab', 'instagram']}/>
            </button>
            <button
                type='button'
                className={`btn ${value === 'tiktok' ? 'btn-active' : ''}`}
                onClick={updateNetwork.bind(null, 'tiktok')}
            >
                <FontAwesomeIcon size="lg" icon={['fab', 'tiktok']}/>
            </button>
            <button
                type='button'
                className={`btn ${value === 'youtube' ? 'btn-active' : ''}`}
                onClick={updateNetwork.bind(null, 'youtube')}
            >
                <FontAwesomeIcon size="lg" icon={['fab', 'youtube']}/>
            </button>
        </div>
    )
}