import React, {useCallback} from "react";

type Props = {
    title: string;
    subTitle: string;
    desc?: string
    theme?: 1 | 2
    icon?: any
}

export default function SimpleDataChart({title, subTitle, desc, theme, icon}: Props) {

    const toReturn = useCallback(() => {
        if (!theme || theme === 1) {
            return (
                <div className="stat">
                    {icon && (
                        <div className="stat-figure text-secondary">
                            {icon}
                        </div>
                    )}
                    <div className="stat-title">{subTitle}</div>
                    <div className="stat-value">{title}</div>
                    <div className="stat-desc">{desc}</div>
                </div>
            )
        }
        return (
            <div className="flex flex-col items-center justify-center gap-2">
                <span className="text-5xl font-bold">{title}</span>
                <span className="text-sm">{subTitle}</span>
            </div>
        )
    }, [subTitle, theme, title])

    return toReturn()
}