import React, {useEffect, useState} from 'react';
import {useFirebase} from "../../../contexts/FirebaseContext";
import {User, UserRole} from "../../../types/user";
import {DataTable} from "grommet";
import CreateUserModal from "../../modal/CreateUserModal";
import {useAuth} from "../../../contexts/AuthContext";
import {useNavigate} from "react-router-dom";
import {ROUTES} from "../../../constants/routes";
import {useTranslation} from "react-i18next";

export default function UsersSettings() {
    const modalName = 'add_user_modal';
    const {getUsers, putUser, resetPassword} = useFirebase();
    const { currentUserData } = useAuth();
    const navigate = useNavigate();
    const {t} = useTranslation();

    const [users, setUsers] = useState<User[]>([]);
    const [btnResetPasswordLoading, setBtnResetPasswordLoading] = useState(false);

    useEffect(() => {
        refreshUsers();
    }, []);

    useEffect(() => {
        if(currentUserData?.role !== 'admin') {
            navigate(ROUTES.EMPLOYEE.HOME);
        }
    }, [currentUserData]);


    const refreshUsers = () => {
        getUsers().then(users => {
            setUsers(users)
        })
    }

    const roleChangeHandler = (userId: string, role: UserRole) => {
        putUser(userId, {role}).then(() => {
            refreshUsers()
        })
    }

    return (
        <>
            <div className="card bg-base-200">
                <div className="card-body">
                    <h1 className="text-3xl font-bold">👥 {t("users.title")}</h1>
                    <h3 className="">{t("users.manage")} {users && users.length > 0 ? ('('+users.length+')') : ''}</h3>
                    <div className="flex justify-end">
                        <button className='btn btn-primary' onClick={() => {
                            (window as any)[modalName].showModal()
                        }}>{t("users.add")}
                        </button>
                    </div>

                    <div className="my-10">
                        <DataTable
                            paginate={true}
                            step={20}
                            pin
                            sortable
                            background={["white", "light-2"]}
                            primaryKey="id"
                            data={users}
                            columns={[
                                {
                                    property: 'firstname',
                                    header: t("users.firstname"),
                                    render: (data) => {
                                        return <>{data?.firstname}</>
                                    },
                                },
                                {
                                    property: 'lastname',
                                    header: t("users.lastname"),
                                    render: (data) => {
                                        return <>{data?.lastname}</>
                                    },
                                },
                                {
                                    property: 'email',
                                    header: t("users.email"),
                                    render: (data) => {
                                        return <>{data?.email}</>
                                    },
                                },
                                {
                                    property: 'actions',
                                    header: t("users.roles"),
                                    render: data => {
                                        return (
                                            <div className='flex items-center gap-6'>
                                                <div className='flex items-center gap-2'>
                                                    <input
                                                        type="radio"
                                                        name={`user_role_${data?.id}`}
                                                        id={`role_admin_${data?.id}`}
                                                        onClick={() => {
                                                            roleChangeHandler(data?.id, 'admin')
                                                        }}
                                                        checked={data?.role === 'admin'}
                                                        readOnly={true}
                                                        className='cursor-pointer'
                                                    />
                                                    <label htmlFor={`role_admin_${data?.id}`}
                                                        className='cursor-pointer'>{t("users.admin")}</label>
                                                </div>
                                                <div className='flex items-center gap-2'>
                                                    <input
                                                        type="radio"
                                                        name={`user_role_${data?.id}`}
                                                        id={`role_employee_${data?.id}`}
                                                        onClick={() => {
                                                            roleChangeHandler(data?.id, 'employee')
                                                        }}
                                                        checked={data?.role === 'employee'}
                                                        readOnly={true}
                                                        className='cursor-pointer'
                                                    />
                                                    <label htmlFor={`role_employee_${data?.id}`}
                                                        className='cursor-pointer'>{t('employee-homepage')}</label>
                                                </div>
                                                <div className='flex items-center gap-2'>
                                                    <input
                                                        type="radio"
                                                        name={`user_role_${data?.id}`}
                                                        id={`role_customer_${data?.id}`}
                                                        onClick={() => {
                                                            roleChangeHandler(data?.id, 'customer')
                                                        }}
                                                        checked={data?.role === 'customer'}
                                                        readOnly={true}
                                                        className='cursor-pointer'
                                                    />
                                                    <label htmlFor={`role_customer_${data?.id}`}
                                                        className='cursor-pointer'>{t('customer-homepage')}</label>
                                                </div>
                                                <button
                                                    className='btn btn-primary'
                                                    disabled={btnResetPasswordLoading}
                                                    onClick={() => {
                                                        setBtnResetPasswordLoading(true);
                                                        resetPassword(data?.email).then(() => {
                                                            setBtnResetPasswordLoading(false);
                                                        });
                                                    }}
                                                >{t("users.resetpassword")}</button>
                                            </div>
                                        )
                                    }
                                },

                            ]}
                        />
                    </div>

                    <CreateUserModal name={modalName} onSubmit={refreshUsers}/>
                </div>
            </div>
        </>
    )
}
