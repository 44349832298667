import React, {useEffect, useState} from 'react';
import {useFirebase} from '../../../../../contexts/FirebaseContext';

import {NavLink, useNavigate, useParams} from 'react-router-dom';
import {
    Campaign,
    Influencer,
    InfluencerList,
} from '../../../../../types/dbModels';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

import {User} from '../../../../../types/user';
import {ROUTES} from '../../../../../constants/routes';
import InfluencerListClientSettingsModal from '../../../../modal/InfluencerListClientSettingsModal';
import {useTranslation} from "react-i18next";

import emptyBox from "../../../../../assets/images/empty.png";
import Loading from '../../../../loading/Loading';
import CreateCampaignModal from "../../../../modal/CreateCampaignModal";
import {generateUniqueID} from "web-vitals/dist/modules/lib/generateUniqueID";
import InfluencerListToolsModal from "../../../../modal/InfluencerListToolsModal";
import InfluencerListDetailsDatatable from "../../../../datatables/InfluencerListDetailsDatatable";

export default function InfluencerListDetailsPage() {
    const {
        getInfluencerList,
        getCampaign,
        getUser,
        getInfluencersFromIds,
    } = useFirebase();

    const {t} = useTranslation();

    const params = useParams();
    const navigate = useNavigate();

    const modalName = 'share_list_modal';
    const modal2name = 'edit_list_modal';
    const createCampaignName = "create_campaign_modal_" + generateUniqueID();

    const [influencerList, setInfluencerList] = useState<InfluencerList>();
    const [influencers, setInfluencers] = useState<Influencer[]>([]); // Influencers
    const [campaign, setCampaign] = useState<Campaign>();
    const [creator, setCreator] = useState<User>();
    const [isListEmpty, setIsListEmpty] = useState(false);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        updateInfluencerList();
    }, []);


    // Mise a jour influencers + campagne + creator
    useEffect(() => {
        if (influencerList === undefined) return;
        const _influencersCategoryData = influencerList.influencersCategories.map(c => c.influencers).flat();
        // Get influencers
        if (influencerList && _influencersCategoryData.length > 0) {
            getInfluencersFromIds(_influencersCategoryData.map(u => u.userId)).then((influencers) => {
                setIsLoading(false);
                setInfluencers(influencers);
                setIsListEmpty(false);
            }).catch((err) => setIsLoading(false));
        } else {
            setIsListEmpty(true);
            setIsLoading(false);
            setInfluencers([]);
        }

        // Get campaign
        if (influencerList?.campaign) {
            getCampaign(influencerList.campaign).then((campaign) => {
                if (campaign) {
                    setCampaign(campaign);
                } else {
                    setCampaign(undefined);
                }
            });
        } else {
            setCampaign(undefined);
        }

        // Get creator
        if (influencerList?.creation_author) {
            getUser(influencerList.creation_author).then((user) => {
                if (user) {
                    setCreator(user);
                } else {
                    setCreator(undefined);
                }
            });
        } else {
            setCreator(undefined);
        }
    }, [influencerList]);


    const campaignModalSubmit = (res: any) => {
        updateInfluencerList()
    }

    const updateInfluencerList = () => {
        if (params.listId) {
            getInfluencerList(params.listId).then((newInfluencerList) => {
                if (newInfluencerList) {
                    setInfluencerList(newInfluencerList)
                } else {
                    navigate(ROUTES.EMPLOYEE.INFLUENCER_LISTS);
                }
            });

        } else {
            navigate(ROUTES.EMPLOYEE.INFLUENCER_LISTS);
        }
    };

    if (!influencerList) return

    return (
        <>
            {/* Breadcrumb */}
            <div className="text-sm breadcrumbs">
                <ul>
                    <li><NavLink to={ROUTES.EMPLOYEE.HOME}>{t("home-title")}</NavLink></li>
                    <li><NavLink to={ROUTES.EMPLOYEE.INFLUENCER_LISTS}>{t("all-lists")}</NavLink></li>
                    <li>{influencerList?.name}</li>
                </ul>
            </div>

            {/* Content */}
            <div className="card bg-base-200">
                <div className="card-body">
                    <div>
                        <div className="flex flex-row mb-4">
                            <div className="mr-4">
                                <FontAwesomeIcon
                                    size="xl"
                                    icon={['fab', (influencerList?.network ?? 'instagram') as any]}
                                />
                            </div>
                            <h1 className="text-[2rem] font-bold">
                                {influencerList?.name}
                            </h1>
                        </div>
                        <div className="flex items-center">
                            {creator?.firstname && (
                                <div className="mr-4">
                                    {t("by")} {`${creator?.firstname} ${creator?.lastname}`}
                                </div>
                            )}
                            {campaign && (
                                <div
                                    className="badge badge-lg badge-secondary mr-4 cursor-pointer"
                                    onClick={() => {
                                        // Redirect to the campaign page
                                        navigate(ROUTES.EMPLOYEE.CAMPAIGNS);
                                    }}
                                >
                                    {'Campagne ' + campaign.name}
                                </div>

                            )}
                        </div>
                    </div>


                    <div className="flex justify-between gap-2">
                        <div className="flex gap-2">
                            {!campaign && !influencerList.isTemplate && (
                                <div className="btn btn-outline" onClick={() => {
                                    (window as any)[createCampaignName].showModal();
                                }}>
                                    🤳 {t("campaign.create")}
                                </div>
                            )}
                        </div>
                        <div className="flex gap-2">
                            <button
                                className='btn btn-secondary'
                                onClick={() => {
                                    (window as any)[modal2name].showModal();
                                }}>
                                ⚙️ {t("list.tools")}
                            </button>
                            <button
                                className='btn btn-secondary'
                                onClick={() => {
                                    (window as any)[modalName].showModal();
                                }}>
                                📭 {t("list.client-settings")}
                            </button>
                            <NavLink to={ROUTES.EMPLOYEE.SEARCH}>
                                <button className='btn btn-primary'>
                                    ➕ {t("list.add-influencer")}
                                </button>
                            </NavLink>
                        </div>
                    </div>


                    <div className="my-10">
                        {isLoading && (
                            <Loading/>
                        )}
                        {(influencers && influencers.length > 0) && (
                            <InfluencerListDetailsDatatable influencerList={influencerList}
                                                            updateInfluencerList={updateInfluencerList}
                                                            campaign={campaign}
                                                            influencers={influencers}/>
                        )}
                        {(isListEmpty && !isLoading) && (
                            <div className='flex flex-col justify-center text-center'>
                                <img src={emptyBox} className='imgEmpty mb-8' alt="empty"/>
                                <p>{t("list.empty")}</p>
                            </div>
                        )}

                    </div>
                </div>
            </div>

            <InfluencerListClientSettingsModal
                name={modalName}
                onSubmit={updateInfluencerList}
                influencerList={influencerList}
                campaign={campaign}
            />

            <InfluencerListToolsModal
                name={modal2name}
                influencerList={influencerList}
                influencers={influencers}
            />


            {influencerList && <CreateCampaignModal influencerListId={influencerList.id} name={createCampaignName}
                                                    onSubmit={campaignModalSubmit}/>}
        </>
    );
}


