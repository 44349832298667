import React, {useEffect, useState} from "react";
import FormInput from "../FormInput";
import {CommentType, InfluencerList} from "../../types/dbModels";
import {toast} from "react-toastify";
import {useFirebase} from "../../contexts/FirebaseContext";
import Loading from "../loading/Loading";
import {useTranslation} from "react-i18next";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
    showForm?: boolean
    influencerId: string,
    influencerListId: string,
    showInternal?: boolean
    onUpdateComment?: (comment: CommentType) => void
}

export default function InfluencerComments({
                                               onUpdateComment,
                                               showForm = true,
                                               influencerId,
                                               influencerListId,
                                               showInternal = false
                                           }: Props) {
    const {putInfluencerList, getInfluencerList} = useFirebase();
    const [influencerList, setInfluencerList] = useState<InfluencerList>();
    const [influencerData, setInfluencerData] = useState<any>();
    const [loading, setLoading] = useState<boolean>(false);
    const {t} = useTranslation();

    const [formData, setFormData] = useState<Omit<CommentType, 'date'>>({
        name: '',
        text: "",
        internal: false,
    });

    const inputChangeHandler = (event: any) => {
        const {name, value, type, checked} = event;

        if (type === "checkbox") {
            setFormData(old => ({...old, [name]: checked}))
        } else {
            setFormData(old => ({...old, [name]: value}))
        }

    }

    const createCommentHandler = async () => {
        if (!influencerList) return;
        if (!formData?.name || !formData?.text) {
            toast.error("Veuillez renseigner tous les champs");
            return;
        }

        // Faire request firebase
        const influencersCategories = [...influencerList.influencersCategories];
        const categoryIndex = influencersCategories
            .findIndex(cat => cat.influencers
                .findIndex(i => i.userId === influencerId) > -1);

        if (categoryIndex < 0) {
            toast.error("Influenceur introuvable");
            return;
        }

        const influencerIndex = influencersCategories[categoryIndex].influencers.findIndex(i => i.userId === influencerId);
        const influencer = influencersCategories[categoryIndex].influencers[influencerIndex];


        const comment: CommentType = {
            name: formData?.name,
            text: formData?.text,
            date: new Date().getTime(),
            internal: formData?.internal
        }
        if (onUpdateComment) {
            onUpdateComment(comment);
        }


        influencer.comments = [...influencer.comments, comment];


        await putInfluencerList(influencerList.id, {...influencerList, influencersCategories});

        setFormData({name: "", text: "", internal: false})
    }

    const deleteCommentHandler = async (comment: CommentType) => {
        if (!influencerList) return;

        const influencersCategories = [...influencerList.influencersCategories];
        const categoryIndex = influencersCategories
            .findIndex(cat => cat.influencers
                .findIndex(i => i.userId === influencerId) > -1);

        if (categoryIndex < 0) {
            toast.error("Influenceur introuvable");
            return;
        }

        const influencerIndex = influencersCategories[categoryIndex].influencers.findIndex(i => i.userId === influencerId);
        const influencer = influencersCategories[categoryIndex].influencers[influencerIndex];

        influencer.comments = influencer.comments.filter(c => c !== comment);

        await putInfluencerList(influencerList.id, {...influencerList, influencersCategories});

        if (onUpdateComment) {
            onUpdateComment(comment);
        }
    }

    const loadInfluencerList = async () => {
        if (!influencerListId) return;
        setLoading(true);
        const i = await getInfluencerList(influencerListId)

        if (!i) {
            toast.error(t("list.doesnt-exist"));
            setInfluencerList(undefined);
            setInfluencerData(undefined)
            setLoading(false);
            return;
        }

        const data = i.influencersCategories.map(c => c.influencers).flat().find(i => i.userId === influencerId);
        if (!data) {
            toast.error(t("list.influencer-not-in-list"));
            setInfluencerList(undefined);
            setInfluencerData(undefined)
            setLoading(false);
            return;
        }

        setInfluencerList(i)
        setInfluencerData(data);
        setLoading(false);
    }

    useEffect(() => {
        loadInfluencerList()
    }, [influencerListId, influencerId]);


    if (loading) {
        return <Loading/>
    }

    if (!influencerList || !influencerData) return;

    return (
        <div className="card w-full bg-base-100 my-4">
            <div className="card w-full bg-base-100">


                {influencerData.comments.length > 0 && (
                    <>
                        <h5 className='text-xl font-bold'>{t("comments.comments-title")}</h5>
                        <div className="notes p-4">
                            {influencerData.comments.filter((c: CommentType) => showInternal ? showInternal : showInternal === c.internal).map((comment: any, i: number) => {
                                return (
                                    <Comment
                                        key={i}
                                        comment={comment}
                                        onDelete={deleteCommentHandler}
                                    />
                                )
                            })}
                        </div>
                    </>
                )}

                {showForm && (
                    <div>
                        <h5 className='text-xl font-bold'>{t("comments.add-comment")}</h5>
                        <div className='grid grid-cols-2 my-4 gap-4'>
                            <FormInput
                                clearable={false}
                                type="text"
                                onChange={inputChangeHandler}
                                name="name"
                                value={formData['name']}
                                placeholder={t("comments.your-name")}
                            />
                            {showInternal && (
                                <FormInput
                                    type="checkbox"
                                    onChange={inputChangeHandler}
                                    name="internal"
                                    checked={formData['internal']}
                                    label={t("comments.internal")}
                                    hideLabel
                                />
                            )}
                        </div>
                        <FormInput
                            clearable={false}
                            type="textarea"
                            onChange={inputChangeHandler}
                            name="text"
                            value={formData['text']}
                            placeholder={t("comments.your-comment")}
                        />
                        <div className='flex justify-end py-4'>
                            <button className='btn btn-primary' onClick={createCommentHandler}
                                    type='button'>{t("comments.comment")}</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
}

export function Comment({comment, onDelete}: { comment: CommentType, onDelete: (comment: CommentType) => any }) {

    const {t} = useTranslation();
    const [showDelete, setShowDelete] = useState(false);

    const onChooseToDelete = (shouldDelete: boolean) => {
        setShowDelete(false);

        if(shouldDelete) {
            onDelete(comment);
        }
    }

    return (
        <div className="note flex gap-4 mb-4 pb-4">
            <div className="avatar placeholder">
                <div className="bg-base-200 text-neutral-content rounded-full w-16 h-16">
                    <span className="text-xl">{comment.name.substring(0, 1)}</span>
                </div>
            </div>
            <div>
                <div className="flex flex-row items-center gap-4">
                    <h3 className="text-lg font-semibold self-center">{comment.name}</h3>
                    <p className="text-s self-center">{'@ ' + new Date(comment.date).toLocaleString()} {comment.internal && '-'} {comment.internal &&
                        <strong>{t("comments.internal")}</strong>}</p>
                    {!showDelete && (
                        <button type='button' className="btn btn-error btn-xs" onClick={() => {
                            setShowDelete(true)
                        }}>
                            <FontAwesomeIcon icon={['fas', 'trash']} color={'#fff'}/>
                        </button>
                    )}
                </div>
                <p className="text-sm">{comment.text}</p>
            </div>

            {showDelete && <div className='bg-base-200 p-4 rounded-2xl shadow-md ml-4'>
                <h2>Supprimer ce commentaire ?</h2>
                <div className="flex flex-row gap-4 justify-center mt-2">
                    <button type='button' className="btn btn-success btn-sm"
                            onClick={onChooseToDelete.bind(null, true)}>Oui
                    </button>
                    <button type='button' className="btn btn-error btn-sm"
                            onClick={onChooseToDelete.bind(null, false)}>Non
                    </button>
                </div>
            </div>}
        </div>
    );
}
