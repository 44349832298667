import React, {useCallback, useState} from "react";
import {TextInput} from "grommet";

type Props = {
    id: string,
    className?: string,
    name: string,
    label: string
    value: string | number,
    onChange: (e: any) => void,
    onSuggestionSelect: (e: any) => void,
    suggestions: string[]
    clearable?: boolean
    onClear?: () => void
}

export default function BasicSuggestionInput({id, className = '', name, label, value, onChange, onSuggestionSelect, suggestions, clearable = true, onClear}: Props) {

    const [dValue, setDValue] = useState<string | number>(value);

    const inputChangeHandler = (e: any) => {
        onChange(e);
    }

    const shouldShowClearButton = useCallback(() => {
        if(!clearable) return false;
        return !(!value || value === dValue);
    }, [clearable, value, dValue]);



    const shouldBgYellow = useCallback(() => {
        return clearable && value !== dValue;
    }, [clearable, value, dValue])

    const clearInputHandler = () => {
        onClear && onClear();
    }
    return (
        <div className="form-group w-full">
            <div className="flex items-center justify-between">
                    <label className="label" htmlFor={id}>
                        <span className="label-text">{label}</span>
                    </label>

                {shouldShowClearButton() && (
                    <div className="pr-1 cursor-pointer" style={{color: "#ffbf00"}}
                         onClick={clearInputHandler}>Clear</div>
                )}
            </div>
            <TextInput

                id={id}
                className={`input input-bordered w-full ${className} ${shouldBgYellow() ? "bg-yellow" : ""}`}
                name={name}
                value={value}
                onChange={inputChangeHandler}
                onSuggestionSelect={onSuggestionSelect}
                suggestions={suggestions}
            />
        </div>

    )
}