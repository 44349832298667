import FormInput from "../FormInput";
import React from "react";
import {Networks} from "../../types/dbModels";
import {useTranslation} from "react-i18next";

export default function PlatformFilter({filterInputChange, value, name, defaultValue = "none"}: {
    filterInputChange: (event: any) => void,
    value: "none" | Networks,
    name: string,
    defaultValue?: string
}) {

    const {t} = useTranslation();

    return (
        <FormInput
            defaultValue={defaultValue}
            type="select"
            onChange={filterInputChange}
            name={name}
            value={value}
            label={t("labels.platforms")}>
            <option value={'none'}>{t("all-feminine")}</option>
            <option value={'instagram'}>Instagram</option>
            <option value={'tiktok'}>Tiktok</option>
            <option value={'youtube'}>Youtube</option>
        </FormInput>
    );
}
