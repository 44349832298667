import React from 'react';
import {useTranslation} from 'react-i18next';
import logo from '../../../assets/images/Clark_LogoNoir.png';

const TermsOfUsePageEN = () => {
    const {t} = useTranslation();


    return (
        <div className="p-8 bg-white flex flex-col justify-center items-center">
            <img src={logo} className="w-52 my-8" alt={'Logo'} />
            <h1 className='text-3xl'>Terms of Use</h1><br/><br/>
            <div>
                
                <p className='mb-6'>Welcome to the Clark Machine platform (hereinafter referred to as "the Platform"). Before using the Platform, please read these Terms of Use ("TOU") carefully. By accessing or using the Platform, you agree to be bound by these TOU.</p>

                <h2 className='text-xl'>Use of the Platform</h2>
                <p className='mb-6'>1.1. You agree to use the Platform only for lawful purposes and in accordance with these TOU, as well as all applicable laws, rules, and regulations.</p>
                <p className='mb-6'>1.2. You are responsible for maintaining the confidentiality of your account and password, and you agree not to share your login information with third parties.</p>
                <p className='mb-6'>1.3. You agree not to use the Platform in a way that disrupts, damages, or compromises its integrity or functionality.</p>

                <h2 className='text-xl'>Intellectual Property</h2>
                <p className='mb-6'>2.1. All intellectual property rights related to the Platform and its content (including, but not limited to, software, design, text, graphics, and images) remain the property of Clark Influence INC. or its licensors.</p>
                <p className='mb-6'>2.2. You agree not to reproduce, distribute, modify, create derivative works, publicly display, publicly perform, republish, download, store, or transmit any content found on the Platform without the prior written consent of Clark Influence INC.</p>

                <h2 className='text-xl'>Privacy</h2>
                <p className='mb-6'>3.1. Clark Influence INC. respects the privacy of its users.</p>
                <p className='mb-6'>3.2 We do not collect any personally identifiable information when you use the Platform. We do not ask for your name, email address, phone number, or any other information that could personally identify you.</p>

                <h2 className='text-xl'>Limitation of Liability</h2>
                <p className='mb-6'>4.1. Clark Influence INC. shall not be liable for any direct, indirect, incidental, special, or consequential damages arising from the use or inability to use the Platform.</p>

                <h2 className='text-xl'>Modification of the TCU</h2>
                <p className='mb-6'>5.1. Clark Influence INC. reserves the right to modify these TCU at any time. The modifications will be effective upon their publication on the Platform. It is your responsibility to regularly check the TCU to stay informed of any changes.</p>
                <p className='mb-6'>By using the Platform after the publication of modifications to the TCU, you agree to be bound by these modifications.</p>


                <h2 className='text-xl'>Provision Of The Services</h2>
                <p className='mb-6'>6.1 You understand and agree that Clark Influence may modify, terminate, suspend, or otherwise adjust any and all aspects of the Services at any time without prior notice to you. You acknowledge and agree that Clark Influence can disable access to Services for non-payment or other material breach of the Terms, you may be prevented from accessing your files or other content which is contained in Clark Influence platform or Services. You acknowledge and agree that you are allowed to use only single account per person. In case of legal person you are allowed to use single account for multiple employees.</p>

                <h2 className='text-xl'>Applicable Law and Jurisdiction</h2>
                <p className='mb-6'>7.1. These TCU are governed by and interpreted in accordance with the laws in Toronto, Ontario. Any dispute arising from these TCU shall be subject to the exclusive jurisdiction of the competent courts of Torontol.</p>
                <p className='mb-6'>By using the Platform, you consent to the personal jurisdiction of these courts for the resolution of any dispute.</p>
                <p className='mb-6'>If you have any questions regarding these TCU, please contact us at info@clarkinfluence.com</p>

            </div>
        </div>
    );
};

export default TermsOfUsePageEN;