import React from 'react';

type Props = {
    name: string;
    title: string;
    message: string;
    onSubmit: () => void;
};

export default function ConfirmMessage({name, title, message, onSubmit}: Props) {
    
    return (    
        <dialog id={name} className="modal modal-bottom sm:modal-middle">
            <div className="modal-box">
                <h3 className="font-bold text-xl">{title}</h3>
                <p className="mt-4">{message}</p>
                <div className="modal-action">
                <form method="dialog">
                    <button className="btn mr-2">Annuler</button>
                    <button onClick={onSubmit} className="btn btn-primary">Oui</button>
                </form>
                </div>
            </div>
        </dialog>
    );
}