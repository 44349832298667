import React from 'react';
import ReactDOM from 'react-dom/client';
import './assets/css/index.css';
import App from './App';
import axios from 'axios';
import './i18n';
import { Grommet } from 'grommet';
import { deepFreeze } from "grommet/utils"

axios.defaults.headers.post['Content-Type'] = 'application/json';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

export const customTheme = deepFreeze(
  {
  "global": {
    "colors": {
      "active": "#ffd400",
      "brand": "#ffd400",
      "focus": "#ffd400",
      "accent-1": "#ffd400",
      "accent-2": "#12331f",
      "accent-3": "#f7e7c2",
      "accent-4": "#ffd400"
    },
    "font": {
      "size": "14px",
      "height": "20px"
    }
  },
  "dataTable": {
    "header": {
      "font":{
        "size": "14px",
        "weight": "bold"
      } 
    }
  },
  "paragraph": {
    "small": {
      "size": "10px",
      "height": "18px"
    },
    "medium": {
      "size": "12px",
      "height": "22px"
    },
    "large": {
      "size": "16px",
      "height": "26px"
    },
    "xlarge": {
      "size": "20px",
      "height": "28px"
    },
    "xxlarge": {
      "size": "26px",
      "height": "34px"
    }
  }
}
)

root.render(
    <Grommet theme={customTheme}>
      <App />
    </Grommet>
);
