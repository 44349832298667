import React, {useEffect, useState, useCallback} from 'react';
import {CampaignFormData} from '../../types/formData';
import FormInput from '../FormInput';
import {useFirebase} from '../../contexts/FirebaseContext';
import {InfluencerList} from '../../types/dbModels';
import {User} from '../../types/user';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";

type Props = {
    name: string;
    onSubmit: (values: CampaignFormData) => void;
    influencerListId: string
};

export default function CreateCampaignModal({name, onSubmit, influencerListId}: Props) {
    const {
        postNewCampaign,
        getAvailableInfluencerLists,
        getUsers,
        getAvailableOffices,
        getCampaignAvailableStatusExternal,
    } = useFirebase();

    const {t} = useTranslation();

    const [formData, setFormData] = useState<CampaignFormData>({
        startDate: '',
        endDate: '',
        status_ext: 'in_review',
        name: '',
        client: '',
        employee_incharge: '',
        office: '',
        list: influencerListId,
        nb_expected: 10
    });

    const [influencerLists, setInfluencerLists] = useState<InfluencerList[]>([]);
    const [users, setUsers] = useState<User[]>([]);

    useEffect(() => {
        getAvailableInfluencerLists().then((lists: InfluencerList[]) => {
            setInfluencerLists(lists);
        });
        getUsers().then((res: User[]) => {
            setUsers(res);
        }).catch(err => {});
    }, []);

    const formatUsers = useCallback(() => {
        return users
            .sort((a, b) => (a.firstname + " " + a.lastname).localeCompare((b.firstname + " " + b.lastname)))
            .map((user) => {
            return (
                <option key={user.id} value={user.id}>
                    {user?.firstname} {user?.lastname}
                </option>
            );
        });
    }, [influencerLists, users]);

    const inputChangeHandler = (event: any) => {
        let {name, value, type} = event.target;
        if(type === 'number') {
            value = +value;
        }
        setFormData(updateFormData(name, value));
    };

    const updateFormData = (
        name: keyof CampaignFormData,
        value: string | number
    ): CampaignFormData => {
        return {
            ...formData,
            [name]: value,
        };
    };

    const submitHandler = async (e: any) => {
        for (const [key, value] of Object.entries(formData)) {
            if (!value && key !== 'list') {
                e.preventDefault();
                return toast.warning(t('other.fill_all_fields'));

            }
        }

        if (formData?.list === "") return toast.warning(t('other.fill_all_fields'));

        if (new Date(formData['startDate']) > new Date(formData['endDate'])) {
            toast.warning(t('other.start_date_before_end_date'));
            return;
        }

        try {
            await postNewCampaign(formData);
            toast.success(t('campaign.add_success'));
            onSubmit(formData);
        } catch (err) {
            toast.error(t('campaign.add_error'));
        }
    };

    return (
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                    <h3 className="font-bold text-lg mb-8">{t('campaign.new')}</h3>

                    <div
                        className="my-4"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '1rem',
                        }}
                    >
                        <FormInput
                            clearable={false}
                            type="date"
                            onChange={inputChangeHandler}
                            name="startDate"
                            value={formData['startDate']}
                            label={t('labels.start_date')}
                        />
                        <FormInput
                            clearable={false}
                            type="date"
                            onChange={inputChangeHandler}
                            name="endDate"
                            value={formData['endDate']}
                            label={t('labels.end_date')}
                        />
                    </div>
                    <div
                        className="my-4"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '1rem',
                        }}
                    >
                        <FormInput
                            defaultValue={'in_review'}
                            type="select"
                            onChange={inputChangeHandler}
                            name="status_ext"
                            value={formData['status_ext']}
                            label={'Statut client'}
                        >
                            {getCampaignAvailableStatusExternal().map((status: any) => (
                                <option key={status.id} value={status.id}>
                                    {status.text}
                                </option>
                            ))}
                        </FormInput>


                        <FormInput
                            type="number"
                            onChange={inputChangeHandler}
                            name="nb_expected"
                            placeholder={"Nombre de profils attendus"}
                            value={formData['nb_expected']}
                            label={'Nombre de profils attendus'}
                        />
                    </div>

                    <div
                        className="my-2"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '1rem',
                        }}
                    >
                        <FormInput
                            type="text"
                            onChange={inputChangeHandler}
                            name="name"
                            placeholder={t("campaign.name")}
                            value={formData['name']}
                            label={t('labels.name')}
                        />

                        <FormInput
                            type="text"
                            onChange={inputChangeHandler}
                            name="client"
                            placeholder={t("campaign.client-name")}
                            value={formData['client']}
                            label={t('labels.client')}
                        />

                    </div>
                    <div
                        className="my-2"
                        style={{
                            display: 'grid',
                            gridTemplateColumns: '1fr 1fr',
                            gap: '1rem',
                        }}
                    >

                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="employee_incharge"
                            value={formData['employee_incharge']}
                            label={t('labels.employee_incharge')}
                        >
                            <option disabled value={''}>
                                {t('labels.select_employee_incharge')}
                            </option>
                            {formatUsers()}
                        </FormInput>

                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="office"
                            value={formData['office']}
                            label={t('labels.office')}
                        >
                            <option disabled value={''}>
                                {t('labels.select_office')}
                            </option>
                            {getAvailableOffices().map((office: any) => (
                                <option key={office.id} value={office.id}>
                                    {office.text}
                                </option>
                            ))}
                        </FormInput>
                    </div>
                    <div className="flex justify-center">
                        <button className="btn btn-primary mr-8" onClick={submitHandler}>
                            Créer
                        </button>
                    </div>
                </form>
            </dialog>
        </div>
    );
}
