import React, {useEffect, useState} from 'react';
import {useFirebase} from '../../../contexts/FirebaseContext';

import {NavLink} from "react-router-dom";
// Images - Flags
import {ROUTES} from "../../../constants/routes";
import CampaignsDatatable from "../../datatables/CampaignsDatatable";
import {useTranslation} from "react-i18next";

export default function CampaignsPage() {
    const {getCampaigns} = useFirebase();
    const {t} = useTranslation();

    const [campaigns, setCampaigns] = useState<any>([]);

    useEffect(() => {
        refreshCampaigns();
    }, []);


    const refreshCampaigns = async () => {
        let res = await getCampaigns();
        setCampaigns(res);
    }

    return (
        <>
            {/* Breadcrumb */}
            <div className="text-sm breadcrumbs">
                <ul>
                    <li><NavLink to={ROUTES.EMPLOYEE.HOME}>{t("home-title")}</NavLink></li>
                    <li>{t("campaigns-title")}</li>
                </ul>
            </div>

            <div className="card bg-base-200">
                <div className="card-body">
                    <h1 className="text-3xl font-bold">🤳 {t("campaigns-title")}</h1>
                    <h3 className="">{t("campaigns-for-clients")} 🫶</h3>

                    <CampaignsDatatable campaigns={campaigns} refreshCampaigns={refreshCampaigns} />

                </div>
            </div>
        </>
    );
}
