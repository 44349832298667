import React, {useState, useEffect} from 'react';
import {ListFormData} from '../../types/formData';
import {useFirebase} from '../../contexts/FirebaseContext';
import {toast} from "react-toastify";
import {useTranslation} from "react-i18next";
import SelectNetwork from "../SelectNetwork";
import FormInput from "../FormInput";
import {MultiElementSelect} from "../select/MultiElementSelect";
import {MultiSelect} from "../select/MultiSelect";

type Props = {
    name: string;
    onResponse: (valid: boolean, response: any) => any
    setLoading?: (value: boolean) => any
};

export default function CreateListModal({name, onResponse, setLoading}: Props) {
    const {
        getListsLabels,
        postInfluencerList,
        getInfluencerListAvailableMarkets
    } = useFirebase();

    const {t} = useTranslation();

    const [availableMarketValues, setAvailableMarketValues] = useState<any[]>(getInfluencerListAvailableMarkets());

    const [formData, setFormData] = useState<ListFormData>({
        network: 'instagram',
        campaign: '',
        labels: [],
        market: [],
        name: '',
        password: '',
        banner: '',
        link: '',
        influencersCategories: [],
        isTemplate: false,
        isArchived: false
    });

    const [labels, setLabels] = useState<{ label1: string, label2: string, label3: string }>({
        label1: '-',
        label2: '-',
        label3: '-'
    });


    useEffect(() => {
        setFormData(old => {
            const newLabels = [];
            for (const value of Object.values(labels)) {
                if (value) {
                    newLabels.push(value);
                }
            }

            return {
                ...old,
                labels: newLabels
            };
        })
    }, [labels]);

    const inputChangeHandler = (event: any) => {
        const {name, value, type, checked} = event;

        setFormData(old => ({
            ...old,
            [name]: type === "checkbox" ? checked : value,
        }))
    }

    const labelChangeHandler = (event: any) => {
        const {name, value} = event.target;
        setLabels(old => ({...old, [name]: value}));
    }

    const marketChangerHandler = (newValue: string[]) => {
        setFormData(old => ({...old, market: newValue}));
    }

    const submitHandler = (e: any) => {
        for (const [key, value] of Object.entries(formData)) {
            if (!value && key !== 'campaign' && key !== 'isTemplate' && key !== 'isArchived' && key !== 'banner' && key !== 'link') {
                e.preventDefault()
                toast.warning(t('other.fill_all_fields'));
                return;
            }
        }

        // Création de la liste
        if (setLoading) {
            setLoading(true);
        }
        postInfluencerList(formData).then((r: any) => {
            toast.success(t('list.add_success'));
            onResponse(true, r);
        }).catch((error) => {
            toast.error(t('list.add_error'));
            onResponse(false, null)
        }).finally(() => {
            if (setLoading) {
                setLoading(false);
            }
            resetForm()
        });
    };

    const resetForm = () => {
        setFormData({
            network: 'instagram',
            campaign: '',
            labels: [],
            market: [],
            name: '',
            password: '',
            banner: '',
            link: '',
            influencersCategories: [],
            isTemplate: false,
            isArchived: false
        });
        setLabels({
            label1: '-',
            label2: '-',
            label3: '-'
        });
    }

    return (
        <div>
            <dialog id={name} className="modal">
                <form method="dialog" className="modal-box w-11/12 max-w-5xl">
                    <button className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2">
                        ✕
                    </button>
                    <h3 className="font-bold text-lg mb-8">
                        {t('list.new')}
                    </h3>

                    <div className='flex justify-between'>
                        <FormInput onChange={inputChangeHandler} name={'isTemplate'} type={'checkbox'}
                                   label={t("template")} checked={formData?.isTemplate}/>
                        <SelectNetwork value={formData['network']} onChange={newValue => inputChangeHandler({
                            name: 'network',
                            value: newValue
                        })}/>
                    </div>

                    <div className='grid grid-cols-2 gap-4 my-4'>
                        <FormInput
                            onChange={inputChangeHandler}
                            value={formData['name']}
                            name={'name'}
                            placeholder={t("list.name-nomenclature")}
                            label={`${t("list.tab-general.name")} (${t("list.name-nomenclature")})`}
                        />
                        <FormInput
                            type='password'
                            onChange={inputChangeHandler}
                            value={formData['password']}
                            name='password'
                            placeholder={t('labels.password')}
                            label={t('labels.password')}
                        />
                    </div>

                    <div className='grid grid-cols-3 gap-4 my-4'>
                        <FormInput
                            type="select"
                            onChange={labelChangeHandler}
                            name={'label1'}
                            value={labels['label1']}
                            label={t("labels.label") + ' #1'}
                        >
                            {getListsLabels().sort((a, b) => t(a.text).localeCompare(t(b.text))).map((label: any) => (
                                <option key={label.id} id={label.id} value={label.id}>
                                    {t(label.text)}
                                </option>
                            ))}
                        </FormInput>
                        <FormInput
                            type="select"
                            onChange={labelChangeHandler}
                            name={'label2'}
                            value={labels['label2']}
                            label={t("labels.label") + ' #2'}
                        >
                            {getListsLabels().sort((a, b) => t(a.text).localeCompare(t(b.text))).map((label: any) => (
                                <option key={label.id} id={label.id} value={label.id}>
                                    {t(label.text)}
                                </option>
                            ))}
                        </FormInput>
                        <FormInput
                            type="select"
                            onChange={labelChangeHandler}
                            name={'label3'}
                            value={labels['label3']}
                            label={t("labels.label") + ' #3'}
                        >
                            {getListsLabels().sort((a, b) => t(a.text).localeCompare(t(b.text))).map((label: any) => (
                                <option key={label.id} id={label.id} value={label.id}>
                                    {t(label.text)}
                                </option>
                            ))}
                        </FormInput>
                    </div>

                    <hr className={'my-2'}/>

                    <div>
                        <MultiElementSelect label={t('list.market.label')} values={availableMarketValues}
                                            onSelectedValueChanged={marketChangerHandler}
                                            selectedValues={formData.market.map(v => availableMarketValues.find(tmp => tmp.id === v))}/>
                    </div>

                    <div className="flex justify-center mt-3">
                        <button
                            className="btn btn-primary mr-8"
                            onClick={submitHandler}
                        >
                            {t('list.create')}
                        </button>
                    </div>
                </form>
            </dialog>
        </div>
    );
}
