import {TextInput} from "grommet";
import React, {useEffect, useState} from "react";
import {toast} from "react-toastify";


type Suggestion = {
    id: string,
    text: string
}

type Props = {
    updateSuggestions?: (inputValue: string) => Promise<Suggestion[]>,
    onSelectedValuesChanged: (suggestions: Suggestion[]) => void,
    values?: Suggestion[],
    label: string
}
export function MultiSelect({updateSuggestions = undefined, onSelectedValuesChanged, values: v = [], label}: Props) {

    const [values, setValues] = useState<Suggestion[]>(v);
    const [selectedSuggestions, setSelectedSuggestions] = useState<Suggestion[]>([]);
    const [inputValue, setInputValue] = useState('');


    const inputChangeHandler = async (e: any) => {
        setInputValue(e.target.value);

        try {
            if(updateSuggestions) {
                const suggestions = await updateSuggestions(e.target.value);
                setValues(suggestions);
            }
        } catch(err) {
            toast.error("Une erreur est survenue lors de la récupération des suggestions.");
        }
        // On fait pour les suggestions
    }

    const clearInputHandler = () => {
        setInputValue('');
        setSelectedSuggestions([]);
    }


    const onSuggestionSelect = (event: any) => {
        const value = values.find(v => v.text === event.suggestion);
        if(value) {
            setSelectedSuggestions(selected => [...selected, value]);
            setInputValue('');
        }
    }

    useEffect(() => {
        onSelectedValuesChanged(selectedSuggestions)
    }, [selectedSuggestions]);

    const debounce = <F extends (...args: any[]) => any>(func: F, delay: number): ((...args: Parameters<F>) => void) => {
        let timerId: NodeJS.Timeout | null = null;

        return (...args: Parameters<F>) => {
            if (timerId) {
                clearTimeout(timerId);
            }
            timerId = setTimeout(() => func(...args), delay);
        };
    };

    return (
        <div>
            <div className='flex gap-2 flex-wrap'>
                {selectedSuggestions.map((suggestion, i) => (
                    <div key={Math.random()} className="badge badge-primary cursor-pointer" onClick={() => {
                        setSelectedSuggestions(selected => selected.filter(s => s.id !== suggestion.id))
                    }}>{suggestion.text} X</div>
                ))}
            </div>


            <div className='grid grid-cols-1 items-center'>
                <div className="form-control w-full">
                    <div className="flex items-center justify-between">
                        <label className="label">
                            <span className="label-text">{label}</span>
                        </label>

                        {selectedSuggestions.length > 0 &&
                            <div className="pr-1 cursor-pointer" style={{color: "#ffbf00"}}
                                 onClick={clearInputHandler}>Clear
                            </div>}
                    </div>
                    <TextInput
                        id="grommet-text-combobox"
                        className="input input-bordered w-full"
                        value={inputValue}
                        name={"users"}
                        onChange={(event: any) => {
                            setInputValue(event.target.value);
                            debounce(inputChangeHandler.bind(null, event), 500)()
                        }}
                        onSuggestionSelect={onSuggestionSelect}
                        suggestions={values.map(s => s.text)}
                    />
                </div>
            </div>
        </div>
    )
}