import React from "react";

type Props = {
    max: number,
    value: number,
    size?: 'medium' | 'large' | 'small' | 'xsmall' | 'xlarge' | 'full',
    label: string;
    textSize?: "sm" | "normal"
}

export default function ProgressChart({value = 0, max = 100, size, label, textSize = 'normal'}: Props) {
    const getTextSize = (title: boolean) => {
        if (textSize === "sm") {
            return title ? "1.5rem" : "text-xs";
        }
        return title ? "2rem" : "text-sm";
    }

    return (
        <div className="grid grid-cols-3 gap-4">
            <div className={`${getTextSize(false)} flex self-center text-right justify-end txtEllipsis nowrap`}>{label}</div>
            <div className="col-span-2 flex flex-row content-center self-center">
                <progress className="progress mr-2 self-center" value={value} max={max}></progress>
                <div className={`${getTextSize(false)} content-center self-center font-bold`}>{value+"%"}</div>
            </div>
        </div>
    )
}