import React, {useContext} from 'react'
import axios, {AxiosInstance} from 'axios'
import {InfluencerReport, Interest, Location, SearchFormData, SearchResults} from "../types/api";
import {Networks} from "../types/dbModels";
import {toast} from "react-toastify";

const ApiContext = React.createContext({})

type Context = {
    api: AxiosInstance,
    search: (data: SearchFormData) => Promise<SearchResults | null>,
    getReport: (influencerId: string, platform: Networks) => Promise<InfluencerReport | null>
    getQuota: any
    getInterests: (platform?: Networks) => Promise<Interest[]>
    getLocations: (platform?: Networks) => Promise<Location[]>
    getUsernamesBySearch: (platform: string, search: string) => Promise<any>
    postEmail: (mail: Object) => Promise<any>
    getLanguages: (platform: Networks) => Promise<{ code: string, name: string }[]>
}


export function useApi(): Context {
    return useContext(ApiContext) as Context;
}

type Props = {
    children: any
}

export function ApiProvider({children}: Props) {

    const axiosConfig = {
        baseURL: 'https://modash.joeybronner.fr',
    }
    const api = axios.create(axiosConfig);

    const getInterests = async (platform: Networks = "instagram"): Promise<Interest[]> => {
        try {
            const response = await api.get<{
                error: boolean,
                interests: Interest[],
                total: number
            }>('interests?platform=' + platform);

            const data = response.data;

            if (!data.error) {
                return data.interests;
            } else {
                return [];
            }
        } catch (err) {
            toast.error("Une erreur est survenue lors de la récupération des intérêts.");
            return [];
        }
    }

    const postEmail = async (mail: Object) => {
        try {
            const response = await api.post('sendmail', mail);

            const data = response.data;
            if (data?.status != "success") {
                toast.error(data.error_detail || "Une erreur est survenue lors de l'envoi de l'email de notification au chargé de projet.");
            }
        } catch (err) {
            toast.error("Une erreur est survenue lors de l'envoi de l'email de notification au chargé de projet.");
        }
    }

    const getLocations = async (platform: Networks = "instagram"): Promise<Location[]> => {
        try {
            const response = await api.get<{
                error: boolean,
                total: number,
                locations: Location[]
            }>('locations?platform=' + platform);
            const {data} = response;

            if (!data.error) return data.locations;
            return [];

        } catch (err) {
            toast.error("Une erreur est survenue lors de la récupération des locations.");
            return [];
        }
    }

    const getUsernamesBySearch = async (platform: string, search: string): Promise<any> => {
        try {
            const response = await api.get<{
                error: boolean,
                users: any
            }>('users?platform=' + platform + '&search=' + search);
            const {data} = response;

            if (!data.error) return data.users;
            return [];

        } catch (err) {
            toast.error("Une erreur est survenue lors de la récupération des usernames.");
            return [];
        }
    }

    const getLanguages = async (platform: Networks): Promise<{ code: string, name: string }[]> => {
        try {
            const response = await api.get<{
                error: boolean,
                total: number,
                languages: { code: string, name: string }[]
            }>(`languages?platform=${platform}`);
            const {data} = response;

            if (!data.error) return data.languages;
            throw new Error();
        } catch (err) {
            toast.error("Une erreur est survenue lors de la récupération des language de " + platform);
            return [];
        }
    }

    const search = async (data: SearchFormData) => {
        try {
            const jsonData = makeSearchJson(data);

            if (!jsonData) {
                toast.error("Il faut au moins 3 minimum ou maximum de chaque type de données !");
                return null;
            }

            const response = await api.post<SearchResults>('search', jsonData);
            const searchResults = response.data;

            if (!searchResults.error) {
                return searchResults;
            }
            throw new Error('')


        } catch (err) {
            toast.error("Recherche échouée")
            return null;
        }
    }

    function makeSearchJson(data: SearchFormData) {

        let minMaxCount = 0;
        const base: any = {
            platform: data.platform,
            sort: {
                field: "followers",
                direction: "desc"
            },
            page: data.page,
            filter: {
                influencer: {
                    followers: {},
                    engagementRate: data.influencer.engagementRate / 100,
                    lastposted: data.influencer.lastposted,
                    age: {},
                    hasContactDetails: [
                        {
                            contactType: "email",
                            filterAction: "must"
                        }
                    ],
                    keywords: data.influencer.keywords,
                },
                audience: {}
            }
        }

        const filteredLocations = data.influencer.location.filter(l => l > -1);
        if (filteredLocations.length > 0) {
            base.filter.influencer.location = filteredLocations;
        }

        // followers - min max
        if (data.influencer.followers.min) {
            base.filter.influencer.followers.min = data.influencer.followers.min;
        }

        if (data.influencer.followers.max) {
            base.filter.influencer.followers.max = data.influencer.followers.max;
        }

        if (data.influencer.followers.min !== '' || data.influencer.followers.max !== '') {
            minMaxCount++;
        }

        // age - min max
        if (data.influencer.age.min) {
            base.filter.influencer.age.min = data.influencer.age.min;
        }
        if (data.influencer.age.max) {
            base.filter.influencer.age.max = data.influencer.age.max;
        }

        if (data.influencer.age.min !== '' || data.influencer.age.max !== '') {
            minMaxCount++;
        }


        if(data.audience.language.id !== "") {
            base.filter.audience.language = {...data.audience?.language, weight: data.audience?.language.weight / 100};
        }

        if (data.audience?.age.length > 0) {
            base.filter.audience.age = data.audience?.age.map(age => ({id: age.label, weight: (age.weight / 100)}))
        }

        if (data.influencer.gender !== "ALL") {
            base.filter.influencer.gender = data.influencer.gender
        }

        if (data.audience?.gender.id !== "ALL") {
            base.filter.audience.gender = {...data.audience?.gender, weight: data.audience?.gender.weight / 100}
        }

        if (+data.audience?.location.id > -1) {
            base.filter.audience.location = [{id: +data.audience?.location.id, weight: data.audience?.location.weight / 100}]
        }


        if (data.platform === "tiktok" || data.platform === "youtube") {
            // views - min max
            base.filter.influencer.views = {}

            if (data.influencer.views.min) {
                base.filter.influencer.views.min = data.influencer.views.min;
            }
            if (data.influencer.views.max) {
                base.filter.influencer.views.max = data.influencer.views.max;
            }

            if (data.influencer.views.min !== '' || data.influencer.views.max !== '') {
                minMaxCount++;
            }
        }

        if (data.platform === "instagram") {
            if (data.influencer.accountTypes > 0) {
                base.filter.influencer.accountTypes = [data.influencer.accountTypes];
            }
            base.filter.influencer.interests = [+data.influencer.interests].filter(i => i >= 0)
        }

        if (data.platform === "tiktok" || data.platform === "instagram") {
            base.filter.influencer.bio = data.influencer.bio
        }

        if (data.platform === "instagram") {
            // reelsPlays - min max
            base.filter.influencer.reelsPlays = {}

            if (data.influencer.reelsPlays.min) {
                base.filter.influencer.reelsPlays.min = data.influencer.reelsPlays.min;
            }
            if (data.influencer.reelsPlays.max) {
                base.filter.influencer.reelsPlays.max = data.influencer.reelsPlays.max;
            }

            if (data.influencer.reelsPlays.min !== '' || data.influencer.reelsPlays.max !== '') {
                minMaxCount++;
            }
        }

        return minMaxCount >= 3 ? base : null;
    }

    const getReport = async (influencerId: string, platform: Networks) => {
        try {
            const response = await api.get<InfluencerReport>(`report?platform=${platform}&userid=${influencerId}`)
            const report = response.data;

            if (report) return report

            return null;
        } catch (err) {
            return null;
        }
    }

    const getQuota = async () => {
        try {
            const response = await api.get(`user`)
            const user = response.data;

            if (user) return user

            return null;
        } catch (err) {
            return null;
        }
    }

    const value: Context = {
        api,
        search,
        getReport,
        getQuota,
        getInterests,
        getLocations,
        getUsernamesBySearch,
        postEmail,
        getLanguages,
    }

    return <ApiContext.Provider value={value}>{children}</ApiContext.Provider>
}
