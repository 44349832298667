import React, {useCallback, useEffect, useState} from "react";
import {Location, SearchAudienceAge, SearchFormData, SearchUserData} from "../../../types/api";
import {useTranslation} from "react-i18next";
import SelectNetwork from "../../SelectNetwork";
import FormInput from "../../FormInput";
import {useApi} from "../../../contexts/ApiContext";
import {toast} from "react-toastify";
import {NavLink, useLocation} from 'react-router-dom';
import {ROUTES} from '../../../constants/routes';
import {capitalFirstLetter, getNbOfDaysBetweenTwoDates} from "../../../helpers/functions";
import Loading from "../../loading/Loading";
import {MultiSelect} from "../../select/MultiSelect";
import BasicSuggestionInput from "../../BasicSuggestionInput";
import {MultiUsernameSelect} from "../../select/MultiUsernameSelect";


export default function InfluencerSearchForm({onSubmit, searchLoading = false}: {
    onSubmit: (data: SearchFormData) => void,
    searchLoading?: boolean
}) {
    let location = useLocation();

    // States
    const [formData, setFormData] = useState<SearchFormData>({
        platform: location.state?.platform || "instagram",
        users: [],
        sort: {
            direction: "desc",
            field: "followers"
        },
        page: 0,
        influencer: {
            age: {
                min: 18,
                max: '',
            },
            gender: "ALL",
            engagementRate: 0,
            followers: {
                min: 1000,
                max: ''
            },
            keywords: "",
            views: {
                min: 1,
                max: ''
            },
            lastposted: 30,
            location: [],
            accountTypes: 0,
            bio: "",
            reelsPlays: {
                max: '',
                min: 1
            },
            interests: -1,
        },
        audience: {
            age: [],
            gender: {
                id: "ALL",
                weight: 0
            },
            location: {
                id: "-1",
                weight: 0
            },
            language: {
                id: "",
                weight: 0
            }
        }
    });

    const [interestsList, setInterestsList] = useState<any>([]);
    const [loadings, setLoadings] = useState<{ locations: boolean, languages: boolean }>({
        locations: false,
        languages: false
    });
    const {t} = useTranslation();
    const {getInterests, getLocations, getUsernamesBySearch, getLanguages} = useApi();


    const [locations, setLocations] = useState<{ instagram: Location[], youtube: Location[], tiktok: Location[] }>({
        youtube: [],
        tiktok: [],
        instagram: [],
    });
    const [locationSuggestions, setLocationsSuggestions] = useState<string[]>([]);

    const [languages, setLanguages] = useState<{ code: string, name: string }[]>([]);
    const [languageSuggestions, setLanguageSuggestions] = useState<string[]>([]);

    const [selectedUsernameSuggestions, setSelectedUsernameSuggestions] = useState<SearchUserData[]>([]);

    const [followersMinMaxAllSuggestions, setFollowersMinMaxAllSuggestions] = useState<string[]>(['1,000', '2,000', '3,000', '5,000', '10,000', '50,000', '100,000', '500,000', '1,000,000', '2,000,000']);
    const [followersMinSuggestions, setFollowersMinSuggestions] = useState<string[]>(['1,000', '2,000', '3,000', '5,000', '10,000', '50,000', '100,000', '500,000', '1,000,000', '2,000,000']);
    const [followersMaxSuggestions, setFollowersMaxSuggestions] = useState<string[]>(['1,000', '2,000', '3,000', '5,000', '10,000', '50,000', '100,000', '500,000', '1,000,000', '2,000,000']);


    /////////////////////////////////
    // ------------ Inputs ----------
    /////////////////////////////////
    const [languageAudienceInputValue, setLanguageAudienceInputValue] = useState<string>("");
    const [locationAudienceInputValue, setLocationAudienceInputValue] = useState<string>('');
    const [audienceAgeInputs, setAudienceAgeInputs] = useState<{
        "13-17": { labelChecked: boolean, weight: number },
        "18-24": { labelChecked: boolean, weight: number },
        "25-34": { labelChecked: boolean, weight: number },
        "35-44": { labelChecked: boolean, weight: number },
        "45-64": { labelChecked: boolean, weight: number },
        "65-": { labelChecked: boolean, weight: number },
    }>({
        "13-17": {labelChecked: false, weight: 30},
        "18-24": {labelChecked: false, weight: 30},
        "25-34": {labelChecked: false, weight: 30},
        "35-44": {labelChecked: false, weight: 30},
        "45-64": {labelChecked: false, weight: 30},
        "65-": {labelChecked: false, weight: 30}
    });

    /////////////////////////////////
    // ----------- Methods ----------
    /////////////////////////////////

    const resetForm = (shouldResetPlaform = true) => {
        setFormData({
            platform: shouldResetPlaform ? (location.state?.platform || "instagram") : formData?.platform,
            users: [],
            sort: {
                direction: "desc",
                field: "followers"
            },
            page: 0,
            influencer: {
                age: {
                    min: 18,
                    max: '',
                },
                gender: "ALL",
                engagementRate: 0,
                followers: {
                    min: 1000,
                    max: ''
                },
                keywords: "",
                views: {
                    min: 1,
                    max: ''
                },
                lastposted: 30,
                location: [],
                accountTypes: 0,
                bio: "",
                reelsPlays: {
                    max: '',
                    min: 1
                },
                interests: -1,
            },
            audience: {
                age: [],
                gender: {
                    id: "ALL",
                    weight: 0
                },
                location: {
                    id: "-1",
                    weight: 0
                },
                language: {
                    id: "",
                    weight: 0
                }
            }
        })
        setSelectedUsernameSuggestions([])
    }

    const onLanguageInputValueChange = (event: any) => {
        const allSuggestions = languages.map(l => l.name);
        const nextValue = event.target.value;

        setLanguageAudienceInputValue(nextValue);
        if (!nextValue) setLocationsSuggestions(allSuggestions);
        else {
            const regexp = new RegExp(`^${nextValue.toLowerCase()}`);
            setLanguageSuggestions(allSuggestions.filter((s) => regexp.test(s.toLowerCase())));
        }
    }

    const onLocationAudienceInputValueChange = (event: any) => {
        const allSuggestions = locations[formData?.platform].map(location => location.title);

        const nextValue = event.target.value;
        setLocationAudienceInputValue(nextValue);
        if (!nextValue) setLocationsSuggestions(allSuggestions);
        else {
            const regexp = new RegExp(`^${nextValue.toLowerCase()}`);
            setLocationsSuggestions(allSuggestions.filter((s) => regexp.test(s.toLowerCase())));
        }
    }

    const onFollowersMinMaxInputValueChange = (event: any, inputName: string) => {
        if (event.target.value.match(/[^0-9]/)) return;

        const allSuggestions = followersMinMaxAllSuggestions;

        const nextValue = event.target.value;
        updateFormData(inputName, !nextValue ? '' : +nextValue)
        if (inputName.split('.').slice(-1)[0] === 'min') {
            if (!nextValue) setFollowersMinSuggestions(allSuggestions);
            else {
                const regexp = new RegExp(`^${nextValue.toLowerCase()}`);
                setFollowersMinSuggestions(allSuggestions.filter((s) => regexp.test(s.replace(/,/g, '').toLowerCase())));
            }
        }
        if (inputName.split('.').slice(-1)[0] === 'max') {
            if (!nextValue) setFollowersMaxSuggestions(allSuggestions);
            else {
                const regexp = new RegExp(`^${nextValue.toLowerCase()}`);
                setFollowersMaxSuggestions(allSuggestions.filter((s) => regexp.test(s.replace(/,/g, '').toLowerCase())));
            }
        }
    }

    const onSuggestionSelect = (event: any, inputName: string) => {
        try {
            if (inputName === 'audience-location') {
                const locationId = locations[formData?.platform].find(l => l.title === event.suggestion)?.id || ''

                if (locationId) {
                    updateFormData('audience?.location.id', locationId + '');
                    setLocationAudienceInputValue(event.suggestion);
                } else {
                    updateFormData('audience?.location.id', '');
                    setLocationAudienceInputValue('');
                }
            }
            if (inputName === "audience-language") {
                const languageId = languages.find(l => l.name === event.suggestion)?.code || '';

                if (languageId) {
                    updateFormData('audience?.language.id', languageId);
                    setLanguageAudienceInputValue(event.suggestion);
                } else {
                    updateFormData('audience?.language.id', '');
                    setLanguageAudienceInputValue('');
                }

                return;
            }

            if (inputName === 'followers-min') {
                updateFormData('influencer.followers.min', +event.suggestion.replace(/,/g, ''));
            }

            if (inputName === 'followers-max') {
                updateFormData('influencer.followers.max', +event.suggestion.replace(/,/g, ''));
            }

        } catch {
        }
    };

    const loadLocations = useCallback(async () => {
        if (locations[formData?.platform].length > 0) return;

        setLoadings(old => ({...old, locations: true}));

        let oLocations: any = null;

        // retrieve values from #1 local storage or #2 Modash if non existant or outdated
        let localLocations = localStorage.getItem('clarkmachine-locations-' + formData?.platform);

        if (localLocations) {
            // #1 local storage
            oLocations = JSON.parse(localLocations);

            // check if data is outdated
            let localLocationsDate = localStorage.getItem('clarkmachine-locations-' + formData?.platform + '-updated');
            let days = getNbOfDaysBetweenTwoDates(new Date().getTime(), localLocationsDate);
            // outdated? --> delete from local storage to reload next time
            if (days > 5) {
                localStorage.removeItem('clarkmachine-locations-' + formData?.platform + '-updated');
                localStorage.removeItem('clarkmachine-locations-' + formData?.platform);
            }
        } else {
            // #2 modash
            oLocations = await getLocations(formData['platform']);
            localStorage.setItem('clarkmachine-locations-' + formData?.platform, JSON.stringify(oLocations));
            localStorage.setItem('clarkmachine-locations-' + formData?.platform + '-updated', new Date().getTime().toString());
        }

        // set locations in state :)
        if (oLocations) {
            setLocations(old => ({...old, [formData?.platform]: oLocations}));
        } else {
            toast.error(t("error-get-localisations"))
        }
        setLoadings(old => ({...old, locations: false}));
    }, [formData, getLocations, locations]);

    const loadLanguages = useCallback(async () => {
        setLoadings(old => ({...old, languages: true}));
        let oldLanguages: { code: string, name: string }[];

        // retrieve values from #1 local storage or #2 Modash if non existant or outdated
        let languagesStorage = localStorage.getItem('clarkmachine-languages-' + formData?.platform);
        if (languagesStorage) {
            // #1 local storage
            oldLanguages = JSON.parse(languagesStorage);

            // check if data is outdated
            let localLanguagesDate = localStorage.getItem('clarkmachine-languages-' + formData?.platform + '-updated');
            let days = getNbOfDaysBetweenTwoDates(new Date().getTime(), localLanguagesDate);
            // outdated? --> delete from local storage to reload next time
            if (days > 5) {
                localStorage.removeItem('clarkmachine-languages-' + formData?.platform + '-updated');
                localStorage.removeItem('clarkmachine-languages-' + formData?.platform);
            }


        } else {
            // #2 modash
            oldLanguages = await getLanguages(formData['platform']);
            localStorage.setItem('clarkmachine-languages-' + formData?.platform, JSON.stringify(oldLanguages));
            localStorage.setItem('clarkmachine-languages-' + formData?.platform + '-updated', new Date().getTime().toString());
        }

        setLoadings(old => ({...old, languages: false}));
        setLanguages(oldLanguages);

        // setLanguages(await getLanguages(formData?.platform));
    }, [formData, getLanguages]);


    const inputChangeHandler = (event: any) => {
        const {name, value, type, min, max} = event;

        if (type !== 'number') {
            updateFormData(name, value);
            return;
        }

        if (+min && +value < +min) {
            return
        }

        if (+max && +value > +max) {
            updateFormData(name, +max);
            return
        }
        updateFormData(name, +value);

    };

    const submitHandler = () => {
        const isValid = doAllValidation();
        if (!isValid) return;

        onSubmit(formData)
    };


    const doAllValidation = () => {
        const {min: ageMin, max: ageMax} = formData?.influencer.age
        if (ageMin !== '' && ageMax !== '') {
            if (ageMin > ageMax) {
                toast.error(t("search-age-warning-1"));
                return false;
            }
        } else {
            const res = doMinMaxValidation(ageMin, ageMax, t("search-age-warning-2"));
            if (!res) return false;
        }

        const {min: followersMin, max: followersMax} = formData?.influencer.followers;
        if (followersMin !== '' && followersMax !== '') {
            if (followersMin > followersMax) {
                toast.error(t("search-followers-warning-1"));
                return false;
            }
        } else {
            const res = doMinMaxValidation(followersMin, followersMax, t("search-followers-warning-2"));
            if (!res) return false;
        }


        if (formData['platform'] === "tiktok" || formData['platform'] === "youtube") {
            const {min: viewMin, max: viewMax} = formData?.influencer.views;
            if (viewMin !== '' && viewMax !== '') {
                if (viewMin > viewMax) {
                    toast.error(t("search-views-warning-1"));
                    return false;
                }
            } else {
                const res = doMinMaxValidation(viewMin, viewMax, t("search-views-warning-2"));
                if (!res) return false;
            }
        }

        if (formData['platform'] === "instagram") {
            const {min: reelsPlaysMin, max: reelsPlaysMax} = formData?.influencer.reelsPlays;
            if (reelsPlaysMin !== '' && reelsPlaysMax !== '') {
                if (reelsPlaysMin > reelsPlaysMax) {
                    toast.error(t("search-reals-warning-2"));
                    return false;
                }
            } else {
                const res = doMinMaxValidation(reelsPlaysMin, reelsPlaysMax, t("search-reals-warning"));
                if (!res) return false;
            }
        }

        if (formData?.audience?.age.length > 0) {
            // On doit verifier que les % doivent pas être au dessus de 100

            const reduced = formData?.audience?.age.reduce((acc, currentValue) => {
                return currentValue.weight + acc;
            }, 0);

            if (reduced > 100) {
                toast.error(t("search-percents-warning-limit-1"));
                return false;
            } else if (reduced < 0) {
                toast.error(t("search-percents-warning-limit-2"));
                return false;
            }
        }
        return true;
    }

    const doMinMaxValidation = (prop1: any, prop2: any, message: string) => {
        if (
            (prop1 === '' && prop2 === '') ||
            (prop1 === 0 && prop2 === '') ||
            (prop1 === '' && prop2 === 0) ||
            (prop1 === 0 && prop2 === 0)
        ) {
            toast.error(message);
            return false;
        }

        return true;
    }

    const updateFormData = (
        name: string,
        value: any
    ) => {
        const newState = {...formData};

        // Split the field name into nested properties
        const fieldNames = name.split('.').map(d => d.replace(/\s+/g, ''));
        let currentField: any = newState;

        // Traverse the nested properties to update the value
        for (const fieldName of fieldNames) {
            if (currentField.hasOwnProperty(fieldName)) {
                if (fieldNames.indexOf(fieldName) === fieldNames.length - 1) {
                    currentField[fieldName] = value;
                } else {
                    currentField = currentField[fieldName];
                }
            }
        }

        setFormData(newState)
    }

    const loadInterests = async () => {

        let oInterests: any = null;

        // retrieve values from #1 local storage or #2 Modash if non existant or outdated
        let localInterests = localStorage.getItem('clarkmachine-interests-' + formData?.platform);
        if (localInterests) {
            // #1 local storage
            oInterests = JSON.parse(localInterests);

            // check if data is outdated
            let localInterestsData = localStorage.getItem('clarkmachine-interests-' + formData?.platform + '-updated');
            let days = getNbOfDaysBetweenTwoDates(new Date().getTime(), localInterestsData);
            // outdated? --> delete from local storage to reload next time
            if (days > 5 || oInterests.length === 0) {
                localStorage.removeItem('clarkmachine-interests-' + formData?.platform + '-updated');
                localStorage.removeItem('clarkmachine-interests-' + formData?.platform);
            }
        } else {
            // #2 modash
            oInterests = await getInterests(formData['platform']);
            oInterests = oInterests.sort((a: any, b: any) => {
                let fa = a.name.toLowerCase(),
                    fb = b.name.toLowerCase();

                if (fa < fb) {
                    return -1;
                }
                if (fa > fb) {
                    return 1;
                }
                return 0;
            });
            localStorage.setItem('clarkmachine-interests-' + formData?.platform, JSON.stringify(oInterests));
            localStorage.setItem('clarkmachine-interests-' + formData?.platform + '-updated', new Date().getTime().toString());
        }

        // set locations in state :)
        if (oInterests) {
            setInterestsList(oInterests);
        } else {
            toast.error("Une erreur est survenue pendant la récupération des intérêts. Veuillez recharger la page.")
        }

    }

    const audienceAgeChangeHandler = (event: any) => {
        if (event.target.name.split('-')[0] === 'label') {
            const name = event.target.name.replace('label-audience-age-', '') as SearchAudienceAge["label"];
            if (name) {
                setAudienceAgeInputs(old => {
                    return {
                        ...old,
                        [name]: {
                            labelChecked: event.target.checked,
                            weight: old[name].weight
                        }
                    }
                })
            }
        } else {
            const name = event.target.name.replace('weight-audience-age-', '') as SearchAudienceAge["label"];
            setAudienceAgeInputs(old => {
                return {
                    ...old,
                    [name]: {
                        labelChecked: old[name].labelChecked,
                        weight: +event.target.value
                    }
                }
            })
        }
    }
    const handleSearchUsername = async (searchValue: string): Promise<{ id: string, text: string, picture: string }[]> => {
        try {
            if (searchValue && searchValue.length > 3) {
                let platform = localStorage.getItem("clarkmachine-employee-search-platform") || formData?.platform;

                const users = await getUsernamesBySearch(localStorage.getItem("clarkmachine-employee-search-platform") || formData?.platform, searchValue);
                if (platform === "instagram" || platform === "tiktok") {
                    return users.map((user: { username: string, userId: string, picture: string }) => ({
                        id: user.userId,
                        text: user.username,
                        picture: user.picture
                    }));
                }
                if (platform === "youtube") {
                    return users.map((user: { handle: string, userId: string, picture: string }) => ({
                        id: user.userId,
                        text: user.handle,
                        picture: user.picture
                    }))
                }

            }

        } catch {
            toast.error(t("error-search-users"))
            return []
        }

        return [];
    };

    /////////////////////////////////
    // --------- Use Effects --------
    /////////////////////////////////

    useEffect(() => {
        const ages: typeof formData.audience.age = [];
        for (const [key, value] of Object.entries(audienceAgeInputs)) {
            if (value.labelChecked) {
                ages.push({label: (key as SearchAudienceAge['label']), weight: value.weight});
            }
        }

        setFormData(old => {
            let tmp = {...old};
            tmp.audience.age = ages;
            return tmp;
        })
    }, [audienceAgeInputs]);

    useEffect(() => {
        setLocationsSuggestions(locations[formData?.platform].map(location => location.title));
    }, [locations]);

    useEffect(() => {
        setLanguageSuggestions(languages.map(l => l.name));
    }, [languages]);


    useEffect(() => {
        updateFormData('users', selectedUsernameSuggestions);
    }, [selectedUsernameSuggestions])

    useEffect(() => {
        resetForm(false);
        loadLocations();
        loadLanguages();
        localStorage.setItem('clarkmachine-employee-search-platform', formData?.platform);


    }, [formData?.platform])


    useEffect(() => {
        loadInterests();

        // retry locations
        setTimeout(() => {
            loadLocations();
        }, 2500);

        // retry locations
        setTimeout(() => {
            loadInterests();
        }, 5000);
    }, []);


    return (
        <>
            {/* Breadcrumb */}
            <div className="text-sm breadcrumbs">
                <ul>
                    <li><NavLink to={ROUTES.EMPLOYEE.HOME}>{t("home-title")}</NavLink></li>
                    <li>{t("new-search")}</li>
                </ul>
            </div>


            <div className="card bg-base-200 mb-8">

                <div className="card-body">
                    <h1 className="text-3xl font-bold">🔎 {t("new-search")} {capitalFirstLetter(formData?.platform) || ''}</h1>
                    <h3 className="">{t("search-find-right-person")}</h3>
                    <div className="flex justify-end">
                        <SelectNetwork
                            value={formData['platform']}
                            onChange={newValue => {
                                updateFormData('platform', newValue);
                            }}
                        />
                    </div>

                    <MultiUsernameSelect onSelectedValuesChanged={suggestions => {
                        setSelectedUsernameSuggestions(suggestions.map(s => ({id: s.id, username: s.text})))
                    }}
                                 values={[]}
                                 updateSuggestions={handleSearchUsername}
                                 label={t("search.username")}
                    />


                    <div className='grid grid-cols-4 items-center gap-2'>
                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="influencer.gender"
                            value={formData?.influencer.gender}
                            label={t('labels.gender')}>
                            <option value={'ALL'}>{t('labels.genders.all')}</option>
                            <option value={'MALE'}>{t('labels.genders.male')}</option>
                            <option value={'FEMALE'}>{t('labels.genders.female')}</option>
                        </FormInput>

                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="influencer.age.min"
                            value={formData?.influencer.age.min}
                            label={t('labels.age_min')}>
                            <option value={''}>Peu importe</option>
                            <option value={18}>18</option>
                            <option value={25}>25</option>
                            <option value={35}>35</option>
                            <option value={45}>45</option>
                        </FormInput>

                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="influencer.age.max"
                            value={formData?.influencer.age.max}
                            label={t('labels.age_max')}>
                            <option value={''}>{t("all-masculine")}</option>
                            <option value={18}>18</option>
                            <option value={25}>25</option>
                            <option value={35}>35</option>
                            <option value={45}>45</option>
                        </FormInput>

                        <div className="flex flex-col">
                            <div className="form-control w-full">

                                <FormInput
                                    type="select"
                                    onChange={inputChangeHandler}
                                    name="influencer.engagementRate"
                                    value={formData?.influencer.engagementRate}
                                    label={t("search.engagement-title")}>
                                    <option value={0}>⩾0%</option>
                                    <option value={1}>⩾1%</option>
                                    <option value={2}>⩾2%</option>
                                    <option value={3}>⩾3%</option>
                                    <option value={4}>⩾4%</option>
                                    <option value={5}>⩾5%</option>
                                    <option value={6}>⩾6%</option>
                                    <option value={7}>⩾7%</option>
                                    <option value={8}>⩾8%</option>
                                    <option value={9}>⩾9%</option>
                                    <option value={10}>⩾10%</option>
                                </FormInput>
                            </div>
                        </div>

                    </div>

                    <div className='grid grid-cols-3 items-center gap-2'>
                        <BasicSuggestionInput
                            id={'influencer.followers.min'}
                            name={'influencer.followers.min'}
                            label={t('labels.followers_min')}
                            value={formData?.influencer.followers.min}
                            onChange={(e: any) => {
                                onFollowersMinMaxInputValueChange(e, 'influencer.followers.min')
                            }}
                            onSuggestionSelect={(event: any) => onSuggestionSelect(event, 'followers-min')}
                            suggestions={followersMinSuggestions}
                            onClear={() => updateFormData('influencer.followers.min', 1000)}
                        />

                        <BasicSuggestionInput
                            id={'influencer.followers.max'}
                            name={'influencer.followers.max'}
                            label={t('labels.followers_max')}
                            value={formData?.influencer.followers.max}
                            onChange={(e: any) => {
                                onFollowersMinMaxInputValueChange(e, 'influencer.followers.max')
                            }}
                            onSuggestionSelect={(event: any) => onSuggestionSelect(event, 'followers-max')}
                            suggestions={followersMaxSuggestions}
                            onClear={() => updateFormData('influencer.followers.max', '')}
                        />

                        <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="influencer.lastposted"
                            value={formData?.influencer.lastposted}
                            label={t('labels.last_posted')}
                        >
                            <option value={30}>30 {t("search.days")}</option>
                            <option value={60}>60 {t("search.days")}</option>
                            <option value={90}>90 {t("search.days")}</option>
                            <option value={120}>120 {t("search.days")}</option>
                            <option value={150}>150 {t("search.days")}</option>
                        </FormInput>

                    </div>


                    <div className='grid grid-cols-2 items-center gap-2'>
                        {loadings.locations ? (
                            <div className='flex items-end justify-center'>
                                <span className="loading loading-spinner loading-lg text-primary"></span>
                            </div>
                        ) : (
                            <MultiSelect
                                onSelectedValuesChanged={suggestions => {
                                    updateFormData('influencer.location', suggestions.map(s => +s.id));
                                }}
                                updateSuggestions={async (inputValue) => {
                                    const allSuggestions = locations[formData?.platform].map(location => ({
                                        id: location.id.toString(),
                                        text: location.title
                                    }));

                                    const nextValue = inputValue;
                                    if (!nextValue) {
                                        return allSuggestions;
                                    } else {
                                        const regexp = new RegExp(`^${nextValue.toLowerCase()}`);
                                        return allSuggestions.filter((s) => regexp.test(s.text.toLowerCase()))
                                    }
                                }}
                                values={locations[formData?.platform].map(location => ({
                                    id: location.id.toString(),
                                    text: location.title
                                }))}
                                label={t("search.localisations")}/>
                        )}

                        <div className="form-control w-full">
                            <FormInput onChange={inputChangeHandler}
                                       value={formData?.influencer.keywords}
                                       name="influencer.keywords"
                                       placeholder="#cristiano #sport @audi"
                                       label={t("search.topics-title")}
                            />
                        </div>
                    </div>

                    <div className='grid grid-cols-4 items-center gap-2'>

                        {(formData?.platform === 'youtube' ||
                            formData?.platform === 'tiktok') && <>
                            <FormInput onChange={inputChangeHandler}
                                       type='number'
                                       value={formData?.influencer.views.min}
                                       name="influencer.views.min" label={t('labels.views_min')}
                            />

                            <FormInput onChange={inputChangeHandler}
                                       type='number'
                                       value={formData?.influencer.views.max}
                                       name="influencer.views.max" label={t('labels.views_max')}
                            /></>}

                        {formData?.platform === 'instagram' && <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="influencer.accountTypes"
                            value={formData?.influencer.accountTypes}
                            label={t('labels.account_type')}
                        >
                            <option value={0}>{t('labels.account_types.all')}</option>
                            <option value={1}>{t('labels.account_types.regular')}</option>
                            <option value={2}>{t('labels.account_types.business')}</option>
                            <option value={3}>{t('labels.account_types.creator')}</option>
                        </FormInput>}

                        {interestsList && formData?.platform === 'instagram' && <FormInput
                            type="select"
                            onChange={inputChangeHandler}
                            name="influencer.interests"
                            value={formData?.influencer.interests}
                            label={t('labels.interests')}
                        >
                            <option key={-1}></option>
                            {interestsList.map((interest: any) => {
                                return <option key={'interest-' + interest.id}
                                               value={interest.id}>{interest.name}</option>
                            })}
                        </FormInput>}

                        {formData?.platform === 'instagram' && <>
                            <FormInput onChange={inputChangeHandler}
                                       type='number'
                                       value={formData?.influencer.reelsPlays.min}
                                       name="influencer.reelsPlays.min" label={t('labels.reels_plays_min')}
                            />
                        </>
                        }

                    </div>

                    <div>
                        {(formData?.platform === 'instagram' || formData?.platform === 'tiktok') && (
                            <FormInput
                                onChange={inputChangeHandler}
                                value={formData?.influencer.bio}
                                type="textarea"
                                rows={1}
                                name="influencer.bio"
                                label={t('labels.bio')}
                            />
                        )}
                    </div>


                    <h2 className="card-title">{t('labels.audience_filters')}</h2>


                    <div className='grid grid-cols-2 items-center gap-2'>
                        <div className='flex gap-2 items-end'>
                            <div className={`${formData?.audience?.gender.id !== "ALL" ? 'w-1/2' : 'w-full'}`}>
                                <FormInput
                                    type="select"
                                    onChange={inputChangeHandler}
                                    name="audience?.gender.id"
                                    value={formData?.audience?.gender.id}
                                    label={t('labels.gender')}
                                >
                                    <option value={'ALL'}>{t('labels.genders.all')}</option>
                                    <option value={'MALE'}>{t('labels.genders.male')}</option>
                                    <option value={'FEMALE'}>{t('labels.genders.female')}</option>
                                </FormInput>
                            </div>
                            {formData?.audience?.gender.id !== "ALL" && (
                                <div className='flex items-center justify-center gap-2'>
                                    <FormInput onChange={inputChangeHandler}
                                               value={formData?.audience?.gender.weight}
                                               name={'audience?.gender.weight'}
                                               type={'number'}
                                               hideLabel
                                               min={0}
                                               max={100}
                                               placeholder={'%'}
                                    />
                                    <div className='text-xl'>
                                        %
                                    </div>
                                </div>
                            )}
                        </div>


                        {loadings.locations ? (
                            <div className='flex items-end justify-center'>
                                <span className="loading loading-spinner loading-lg text-primary"></span>
                            </div>
                        ) : (
                            <div className="form-control w-full">

                                <div className="flex gap-2 items-end">
                                        <BasicSuggestionInput
                                            id={'audience?.location.id'}
                                            name={'audience?.location.id'}
                                            label={t("search.localisation")}
                                            value={locationAudienceInputValue}
                                            onChange={onLocationAudienceInputValueChange}
                                            onSuggestionSelect={(event: any) => onSuggestionSelect(event, 'audience-location')}
                                            suggestions={locationSuggestions}
                                            onClear={() => {updateFormData('audience?.location.id', -1); setLocationAudienceInputValue('')}}
                                        />
                                    {+formData?.audience?.location.id > -1 && (
                                        <div className='flex items-center justify-center gap-2'>
                                            <FormInput onChange={inputChangeHandler}
                                                       value={formData?.audience?.location.weight}
                                                       name={'audience?.location.weight'} type={'number'} hideLabel
                                                       min={0} max={100}
                                                       placeholder={'%'}
                                                       clearable={false}
                                            />
                                            <div className='text-xl'>
                                                %
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="grid grid-cols-2 items-center gap-2">
                        {loadings.languages ? (
                            <div className='flex items-end justify-center'>
                                <span className="loading loading-spinner loading-lg text-primary"></span>
                            </div>
                        ) : (
                            <div className="form-control w-full">

                                <div className='flex gap-2 items-end'>
                                    <BasicSuggestionInput
                                        id={'audience-language'}
                                        name={'audience-language'}
                                        label={t("labels.language")}
                                        value={languageAudienceInputValue}
                                        onChange={onLanguageInputValueChange}
                                        onSuggestionSelect={(event: any) => onSuggestionSelect(event, 'audience-language')}
                                        suggestions={languageSuggestions}
                                        onClear={() => {updateFormData('audience?.language.id', ''); setLanguageAudienceInputValue('')}}
                                    />

                                    {formData?.audience?.language.id && (
                                        <div className='flex items-center justify-center gap-2 w-1/2'>
                                            <FormInput onChange={inputChangeHandler}
                                                       value={formData?.audience?.language.weight}
                                                       name={'audience?.language.weight'} type={'number'}
                                                       hideLabel
                                                       min={0}
                                                       max={100}
                                                       placeholder={'%'}
                                                       clearable={false}
                                            />
                                            <div className='text-xl'>
                                                %
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="form-control w-full">
                        <label className="label">
                            <span className="label-text">Ages</span>
                        </label>
                        <div className='grid grid-cols-6'>
                            <div>
                                <FormInput onChange={audienceAgeChangeHandler}
                                           checked={audienceAgeInputs['13-17'].labelChecked}
                                           name={'label-audience-age-13-17'}
                                           type={'checkbox'}
                                           label={'13-17'}
                                           hideLabel
                                />
                                {
                                    audienceAgeInputs['13-17'].labelChecked &&
                                    <div className='flex items-center justify-center gap-2'>
                                        <FormInput onChange={audienceAgeChangeHandler}
                                                   value={audienceAgeInputs['13-17'].weight}
                                                   name={'weight-audience-age-13-17'} type={'number'}
                                                   hideLabel
                                                   placeholder={'%'}
                                        />
                                        <div className='text-xl'>
                                            %
                                        </div>
                                    </div>
                                }

                            </div>
                            <div>
                                <FormInput onChange={audienceAgeChangeHandler}
                                           checked={audienceAgeInputs['18-24'].labelChecked}
                                           name={'label-audience-age-18-24'}
                                           type={'checkbox'}
                                           label={'18-24'}
                                           hideLabel
                                />
                                {
                                    audienceAgeInputs['18-24'].labelChecked &&
                                    <div className='flex items-center justify-center gap-2'>
                                        <FormInput onChange={audienceAgeChangeHandler}
                                                   value={audienceAgeInputs['18-24'].weight}
                                                   name={'weight-audience-age-18-24'} type={'number'}
                                                   hideLabel
                                                   placeholder={'%'}
                                        />
                                        <div className='text-xl'>
                                            %
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <FormInput onChange={audienceAgeChangeHandler}
                                           checked={audienceAgeInputs['25-34'].labelChecked}
                                           name={'label-audience-age-25-34'} type={'checkbox'} label={'25-34'}
                                           hideLabel/>
                                {
                                    audienceAgeInputs['25-34'].labelChecked &&
                                    <div className='flex items-center justify-center gap-2'>
                                        <FormInput onChange={audienceAgeChangeHandler}
                                                   value={audienceAgeInputs['25-34'].weight}
                                                   name={'weight-audience-age-25-34'} type={'number'}
                                                   hideLabel
                                                   placeholder={'%'}
                                        />
                                        <div className='text-xl'>
                                            %
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <FormInput onChange={audienceAgeChangeHandler}
                                           checked={audienceAgeInputs['35-44'].labelChecked}
                                           name={'label-audience-age-35-44'} type={'checkbox'} label={'35-44'}
                                           hideLabel/>
                                {
                                    audienceAgeInputs['35-44'].labelChecked &&
                                    <div className='flex items-center justify-center gap-2'>
                                        <FormInput onChange={audienceAgeChangeHandler}
                                                   value={audienceAgeInputs['35-44'].weight}
                                                   name={'weight-audience-age-35-44'} type={'number'} hideLabel
                                                   placeholder={'%'}/>

                                        <div className='text-xl'>
                                            %
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <FormInput onChange={audienceAgeChangeHandler}
                                           checked={audienceAgeInputs['45-64'].labelChecked}
                                           name={'label-audience-age-45-64'} type={'checkbox'} label={'45-64'}
                                           hideLabel/>
                                {
                                    audienceAgeInputs['45-64'].labelChecked &&
                                    <div className='flex items-center justify-center gap-2'>
                                        <FormInput onChange={audienceAgeChangeHandler}
                                                   value={audienceAgeInputs['45-64'].weight}
                                                   name={'weight-audience-age-45-64'} type={'number'} hideLabel
                                                   placeholder={'%'}/>

                                        <div className='text-xl'>
                                            %
                                        </div>
                                    </div>
                                }
                            </div>
                            <div>
                                <FormInput onChange={audienceAgeChangeHandler}
                                           checked={audienceAgeInputs['65-'].labelChecked}
                                           name={'label-audience-age-65-'} type={'checkbox'} label={'65-'} hideLabel/>
                                {
                                    audienceAgeInputs['65-'].labelChecked &&
                                    <div className='flex items-center justify-center gap-2'>
                                        <FormInput onChange={audienceAgeChangeHandler}
                                                   value={audienceAgeInputs['65-'].weight}
                                                   name={'weight-audience-age-65-'} type={'number'} hideLabel
                                                   placeholder={'%'}/>

                                        <div className='text-xl'>
                                            %
                                        </div>
                                    </div>


                                }
                            </div>
                        </div>
                    </div>

                    <div className="card-actions justify-center mt-10">
                        {searchLoading ? <Loading/> : (
                            <button className="btn btn-md btn-primary" onClick={submitHandler}>
                                {t('labels.search')}
                            </button>
                        )}
                    </div>

                </div>
            </div>
        </>
    );
}
